//import axios from "axios";
//import { Config } from "../../config/Config";

class Authentication {
    static isAuthenticated: boolean;

    constructor() {
        Authentication.isAuthenticated = false;
    }

    static login = (
        username: string,
        password: string,
        from: string,
        feedback?: Function
    ) => {
        //TODO enable server-side authentication
        /*    let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Access-Control-Allow-Origin", window.location.origin);
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("POST", "OPTIONS");

        return axios
          .request({
            method: "POST",
            url: `${Config.authorizationService.baseUrl}/auth`,
            data: { username: username, password: password },
            headers: headers
          })
          .then(res => {
            if (res.status === 200) {
              window.sessionStorage.setItem("access", res.data.token);
              Authentication.isAuthenticated = true;
              window.location.href = from;
            }
          })
          .catch(e => {
            Authentication.isAuthenticated = false;
            if (feedback) {
              feedback();
            }
          });*/

        if (
            username === "user" &&
            password === "12345"
        ) {
            window.localStorage.setItem("access", Math.random().toString());
            Authentication.isAuthenticated = true;
            window.location.href = from;
        } else {
            Authentication.isAuthenticated = false;
            if (feedback) {
                feedback();
            }
        }
    };

    static signOut = () => {
        window.localStorage.removeItem("access");
        Authentication.isAuthenticated = false;
        window.location.href = "/login";
    };

    static getAuthentication = () => {
        return window.localStorage.getItem("access");
    };
}

export default Authentication;
