import React, {Component} from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import firebase from 'firebase/app';
import Util from "../../../util/Util";
import Loading from "../shared/loading/Loading";
import "./portletTable.scss";
import {NavLink} from "react-router-dom";
import HeaderDescription from "../dashboard/HeaderDescription";
import ModalDelete from "../modal/ModalDelete";
const moment = require('moment');

const ActionCell = (row: any)=> {
    return (
        <div className={"cell-action-wrapper"}>
            <NavLink to={`../events/edit/${row.original.id}`}>
                <Button>
                    Edit
                </Button>
            </NavLink>
            <Button
                variant={"danger"}
                onClick={()=> row.original.component.setState({
                    showModalDelete: true,
                    record: {
                        id: row.original.id,
                        name: row.original.name
                    }
                })}>
                Delete
            </Button>
        </div>
    );
};

const StandardCell = (row: any)=>{
    return (
        <div className={`standard-cell`}>
            {row.value}
        </div>

    )
};

const columns = [
    {
        Header: 'Name',
        accessor: 'name', // String-based value accessors!
        Cell: StandardCell
    },
    {
        Header: 'Event date',
        accessor: 'date' // String-based value accessors!
    },
    {
        Header: <div className={"table-th-icon"}><i className={"fas fa-video"}/>&nbsp; Go live</div>,
        accessor: 'goLife',
        width: 100,
        minWidth: 100,
    },
    {
        Header: 'Actions',
        accessor: 'actions', // String-based value accessors!
        Cell: ActionCell,
        width: 150
    }
];


type Props = {
    companyId: string,
    section: string,
    dashboard: string,
};

type State = {
    searchField: string,
    data: Array<any>,
    filteredCompanies: Array<any>,
    selectAll: boolean,
    selection: Array<any>,
    loadingData: boolean,
    showModalDelete: boolean,
    feedback: Array<any>;
    record: {
        name: string,
        id: string,
    };
};


class EventList extends Component<Props, State> {
    // @ts-ignore
    checkboxTable: ReactTable;

    state: State = {
        searchField: "",
        data: [],
        filteredCompanies: [],
        selectAll: false,
        selection: [],
        loadingData: true,
        showModalDelete: false,
        feedback: [],
        record: {
            name: "",
            id: ""
        }
    };

    componentDidMount(): void {
        this.loadData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.companyId !== this.props.companyId){
            this.loadData();
        }
    }

    loadData = () => {
        this.setState({loadingData: true});
        firebase.database().ref(`companies/${this.props.companyId}/events`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState({data: this.mapAudiences(snapshot.val()), loadingData: false});
            }else{
                this.setState({data: [], loadingData: false });
            }
        }, function(error: any) {
            if (error) {
                console.log(error);
            }
        });
    };

    toggleEvent = (id: string, name: string, isLife: boolean) => {
        firebase.database().ref(`companies/${this.props.companyId}/events/${id}`)
            .update({status: isLife ? "offline": "online"})
            .then(() => {
                this.pushFeedback({
                    type: "success",
                    title: `Event ${isLife? "Offline": "Online"}`,
                    text: `Event ${name} is ${isLife? "Offline": "Online"}!`,
                    id: moment.now(),
                })
        });
    };

    mapAudiences = (companies: any): Array<any> => {
        const toggle = (key:string, name: string, isLife: boolean)=>{
            return (
                <div className={`toggle-cell`}>
                    <Form>
                        <Form.Check
                            type="switch"
                            id={key}
                            label=""
                            checked={isLife}
                            onChange={()=>this.toggleEvent(key, name, isLife)}
                        />
                    </Form>
                </div>
            )
        };

        return Object.keys(companies).map((company: any)=> {
            let name = Util.decodeCharacters(companies[company].name);
            let status = Util.decodeCharacters(companies[company].status);
            let date = moment.unix(companies[company].date/1000).format("DD.MM.YYYY");
            let starts = companies[company].starts;
            let ends = companies[company].ends;
            return {
                id: company,
                name: name,
                date: <div>
                        <div>
                            <i className="far fa-calendar-alt"/>&nbsp;
                            <b>{`${date}`}</b>
                        </div>
                        <div><i className="far fa-clock"/> {`${starts} - ${ends}`}</div>
                    </div>,
                component: this,
                goLife:  toggle(company, name, status === "online")
            }
        });
    };

    deleteRecord = (id: string, name: string) => {
        this.setState({showModalDelete: false});
        firebase.database().ref(`companies/${this.props.companyId}/events`).update({[id]: null}).then(()=>{
            this.pushFeedback({
                type: "success",
                title: "Event deleted",
                text: `Event ${name} was deleted!`,
                id: moment.now(),
            })
        });
    };

    pushFeedback = ( feedbackData : any)=>{
        let feedBack = this.state.feedback;
        this.setState({feedback: [feedbackData , ...feedBack]})
    };

    render() {
        const {
            props: {companyId, dashboard, section},
            state: {data, loadingData, showModalDelete, record, feedback},
        }= this;

        return (
            <Container className={"portlet-wrapper"}>
                <HeaderDescription
                    companyId={companyId}
                    dashboard={dashboard}
                    section={section}
                    icon={"fas fa-calendar-alt"}
                    feedback={feedback}
                    name={"Events"} />
                <Row>
                    <Col>
                        <div className="toolbar-dashboard">
                            <NavLink to={`/cms/${companyId}/${section}/add`} className={"btn btn-success"}>
                                <i className="fas fa-plus"/> Add event
                            </NavLink>
                            <NavLink target={"_black"} to={`/company/${companyId}/details#events`} className={"btn btn-light float-right"}>
                                <i className="fas fa-globe-europe"/> See website
                            </NavLink>
                        </div>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        {loadingData?
                            <Loading/>:
                            <ReactTable
                                data={data}
                                columns={columns}
                            />
                        }
                    </Col>
                </Row>
                <ModalDelete
                    showModal={showModalDelete}
                    onHide={()=> this.setState({showModalDelete: false})}
                    onDelete={()=> this.deleteRecord(record.id, record.name )}
                    title={"Delete Event?"}
                    message={`You are about to delete event: ${record.name}`}/>
            </Container>
        );
    }
}

export default EventList;
