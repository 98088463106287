import React, {Component} from 'react';
import {Card, Col, Container, Row, Button, Form, Image, Spinner} from "react-bootstrap";
import "./portlet.scss";
import firebase from 'firebase/app';
import {NavLink} from "react-router-dom";
import Util from "../../../util/Util";

import {Linode} from "../../etc/Config";
import AWS from 'aws-sdk';
AWS.config = new AWS.Config();
AWS.config.accessKeyId = Linode.accessKeyId;
AWS.config.secretAccessKey = Linode.secretAccessKey;
AWS.config.region = Linode.region;
const s3 = new AWS.S3({endpoint: Linode.endpoint});
const hostingURL = Linode.hostingURL;
const moment = require('moment');

type Props = {
    companyId: string,
    mode: "EDIT" | "CREATE",
    section: string,
    dashboard: string
    record:string
};

type State = {
    validated: boolean,
    age: number,
    job: string,
    name: string,
    rating: number,
    review: Array<string>,
    video: string,
    loadingData: boolean,
    photo: string | null,
    file: any | null,
    inputFileValue: any,
    isDataUploading: boolean,
};

class StarterForm extends Component<Props, State> {

    state:State = {
        validated: false,
        age: 15,
        job: "",
        name: "",
        rating: 0,
        review: [],
        photo: "",
        video: "",
        loadingData: false,
        file:  null,
        inputFileValue: "",
        isDataUploading: false
    };


    componentDidMount(): void {
        if(this.props.mode ==="EDIT"){
            this.loadRecord();
        }
    }

    loadRecord = () => {
        const {companyId, record} = this.props;
        this.setState({loadingData: true});
        firebase.database().ref(`companies/${companyId}/reviewers/${record}`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState(
                    {
                        name: Util.decodeCharacters(snapshot.val().name),
                        age: snapshot.val().age,
                        job: Util.decodeCharacters(snapshot.val().job),
                        rating: snapshot.val().rating,
                        review: Util.decodeCharacters(snapshot.val().review.join()).split(/\r?\n/),
                        photo: snapshot.val().photo || null,
                        video: Util.decodeCharacters(snapshot.val().video),
                    }
                )
            }
            this.setState({loadingData: false});
        });
    };


    handleSubmit= (event: any)=>{
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === true ) {
            this.addReviewer();
        }
        this.setState({validated: true});
    };


    addReviewer = () => {
        const {
            age,
            job,
            name,
            rating,
            review,
            video,
            photo,
            file
        } = this.state;

        const {
            mode,
            record,
            companyId,
        } = this.props;

        let data: any = {
            age: age,
            creationDate: moment().valueOf(),
            review: review,
            name: name,
            video: video,
            job: job,
            rating: rating,
            photo: photo
        };
        this.setState({isDataUploading: true});

        if(mode === "CREATE"){
            firebase.database().ref(`companies/${companyId}/reviewers/`).push(data
            ).then((snap)=>{
                this.storeImages(file, companyId, snap.key || "def");
            });
        }

        if(mode === "EDIT"){
            firebase.database().ref(`companies/${companyId}/reviewers/${record}`).update(data
            ).then(()=>{
                this.storeImages(file, companyId, record);
            });
        }
    };

    storeImages = (file: any, companyId: string, record: string) => {
        if(file){
            let params = {
                Body: file,
                Bucket: Linode.Bucket,
                Key: `companies/${companyId}/reviewers/${record}`,
                ACL:'public-read'
            };

            s3.putObject(params, (err: any) => {
                if(!err){
                    let url = `${hostingURL}/companies/${companyId}/reviewers/${record}`;
                    firebase.database().ref(`companies/${this.props.companyId}/reviewers/${record}`).update({photo: url}).then(()=>{
                        window.location.href = Util.getPath(`cms/${companyId}/starters/list`);
                        this.setState({isDataUploading: false});
                    });
                }else{
                    this.setState({isDataUploading: false});
                    alert("error");
                }
            });
        }else{
            window.location.href = Util.getPath(`cms/${companyId}/starters/list`);
        }
    };

    handleOnTextChange = (e: any, key: any) => {
         let value = e.target.value;
        // @ts-ignore
        this.setState({[key]: value});
    };

    handleTextAreaOnChange = (e: any) => {
        let value = e.target.value.toString();
        this.setState({review: value.split(/\r?\n/)});
    };

    onPositionChanged= () => {
        alert()
    };

    setRating = (rating: number)=> {
      this.setState({rating: rating})
    };

    handleLoadFile = (e: any) => {
        if(e && e.target && e.target.files.length > 0){
            let file = e.target.files[0];
            Util.imageToBase64(file).then((photo: any)=>{
                this.setState({file: file, photo: photo, inputFileValue: ""});
            });
        }
    };

    render() {
        const {
            state: {
                validated,
                age,
                job,
                name,
                rating,
                review,
                photo,
                video,
                file,
                inputFileValue,
                isDataUploading
            },
            props:{companyId, section, mode}
        } = this;

        return (
            <Container className={"portlet-wrapper"}>
                <Row noGutters>
                    <Col className={"portlet-header"}>
                        <h4>
                            <i className="fas fa-edit"/>&nbsp;
                            <span>{mode === "CREATE" ? "Add Starter": "Edit Starter" }</span>
                        </h4>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                            <Card className={"card-form-portlet"}>
                                <Card.Header as="h5">Starter Information</Card.Header>
                                <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    value={name}
                                                    onChange={(e)=> this.handleOnTextChange(e, "name")}
                                                    required
                                                    type="text"/>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Age</Form.Label>
                                                <Form.Control
                                                    value={age}
                                                    onChange={(e)=> this.handleOnTextChange(e, "age")}
                                                    required
                                                    type="number"/>
                                            </Form.Group>
                                        </Form.Row>

                                        <Row>
                                            <Col>
                                                <Form.Label>Photo</Form.Label>
                                                <div className="custom custom-file">
                                                    <input
                                                        type="file"
                                                        accept="image/png, image/jpeg, image/jpg"
                                                        className={"custom-file-input"}
                                                        id="fileLogo"
                                                        onChange={this.handleLoadFile}
                                                        value={inputFileValue}
                                                    />
                                                    <label
                                                        className={"custom-file-label"}
                                                        htmlFor="fileLogo">{file? file.name : photo && photo !==""? "Change photo": "Select a photo"}</label>
                                                </div>
                                            </Col>
                                        </Row>
                                        { photo && photo !== "" &&
                                            <Row>
                                                <Col lg={6}>
                                                    <div className={"image-form-wrapper"}>
                                                        <Button
                                                            variant={"danger"}
                                                            className={"btn-delete-image"}
                                                            onClick={()=> this.setState({
                                                                photo: null,
                                                                file: null,
                                                                inputFileValue: ""
                                                            })}>
                                                            <i className="fas fa-times"/>
                                                        </Button>
                                                        <Image src={photo} thumbnail />
                                                    </div>

                                                </Col>
                                            </Row>
                                        }

                                        <Form.Row style={{marginTop: "15px"}}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Video URL</Form.Label>
                                                <Form.Control
                                                    value={video}
                                                    onChange={(e)=> this.handleOnTextChange(e, "video")}
                                                    type="text"/>
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Job Position</Form.Label>
                                                <Form.Control
                                                    value={job}
                                                    onChange={(e)=> this.handleOnTextChange(e, "job")}
                                                    required
                                                    type="text"/>
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Rating</Form.Label>
                                                <div>
                                                    <i className={`fa-star ${rating >= 10? "fas": "far" }`} onClick={()=> this.setRating(10)}/>
                                                    <i className={`fa-star ${rating >= 20? "fas": "far" }`} onClick={()=> this.setRating(20)}/>
                                                    <i className={`fa-star ${rating >= 30? "fas": "far" }`} onClick={()=> this.setRating(30)}/>
                                                    <i className={`fa-star ${rating >= 40? "fas": "far" }`} onClick={()=> this.setRating(40)}/>
                                                    <i className={`fa-star ${rating >= 50? "fas": "far" }`} onClick={()=> this.setRating(50)}/>
                                                </div>
                                            </Form.Group>
                                        </Form.Row>



                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Review</Form.Label>
                                                <Form.Control
                                                    value={review.join("\r\n")}
                                                    onChange={(e)=> this.handleTextAreaOnChange(e)}
                                                    required
                                                    as="textarea"
                                                    rows={5}
                                                    />
                                            </Form.Group>
                                        </Form.Row>


                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <Button variant={!isDataUploading ? "primary": "outline-primary"} type="submit" disabled={isDataUploading}>
                                        {mode === "CREATE"?  (!isDataUploading? "Add": "Adding"):  (!isDataUploading? "Update": "Updating") } starter
                                        {isDataUploading &&
                                        <>  &nbsp;
                                            <Spinner animation="grow" variant="primary" size="sm" />
                                        </>
                                        }
                                    </Button>
                                    <NavLink to={`/cms/${companyId}/${section}/list`}>
                                        <Button variant={"secondary"}>
                                            Cancel
                                        </Button>
                                    </NavLink>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default StarterForm;
