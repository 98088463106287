import React, {Component} from 'react';
import './searchBar.scss';
import {Container, Row, Col} from "react-bootstrap";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import firebase from 'firebase/app';
import Util from "../../../util/Util";

const animatedComponents = makeAnimated();


type Props = {
    onSearch: Function,
    city?: string | null,
    branches?: any | null,
    jobType?: string | null,
}

type State = {
    branches: Array<any>,
    selectedBranches: Array<any>,
    cities: Array<any>,
    selectedCities: any,
    jobType: string
}

class SearchBar extends Component<Props, State> {

    state: State = {
        branches: [],
        selectedBranches: [],
        cities: [],
        selectedCities: null,
        jobType: this.props.jobType ? this.props.jobType: ""
    };

    componentDidMount(): void {
        this.loadBranches();
        this.loadCities();

        const jobType = this.state.jobType;

        if(window.location.href.startsWith(`${Util.getPath("search/")}`)){

            if(this.props.city && this.props.branches){
                this.onSearch(this.props.city,  this.props.branches.toString().split(",").map((b: string)=> {return {value: b, label: b}}), jobType)
            }

            if(this.props.city && !this.props.branches){
                this.onSearch(this.props.city,  [], jobType)
            }
            if(!this.props.city && !this.props.branches){
                this.onSearch("Alle Regionen",  [], jobType)
            }
        }
    }

    loadBranches = ()=> {
        firebase.database().ref(`app/branches`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                let branches = snapshot.val();
                this.setState(
                    {
                        branches:  Object.keys(branches).map((key: string)=> {
                            return {value: branches[key].label, label: branches[key].label}
                        }),
                    }
                );

                if( this.props.branches){
                    let branches = this.props.branches.toString().split(",");
                    this.setState({
                        selectedBranches: branches.map((b: string)=> {return {value: b, label: b}})
                    })
                }
            }
        });
    };

    loadCities = () => {
        const parseCities = (companies: any) => {
           let cities = Object.keys(companies)
            .map((c: string)=>  companies[c].information.city)
            .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
            .map( (c: string)=> {
                return {
                    value: c,
                    label: c
                }
            });
            return [{value: "Alle Regionen", label: "Alle Regionen"}, ...cities];
        };

        firebase.database().ref(`companies`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                const cities = snapshot.val();
                this.setState({
                    cities: parseCities(cities),
                });
                const city = this.props.city;
                if(city){
                    this.setState({selectedCities: {value: city, label: city}});
                }
            }
        });
    };

    handleBranchChange = (selectedBranches: Array<any>) => {
        this.setState({ selectedBranches: selectedBranches });
    };

    handleCityChange = (selectedCities: Array<any>) => {
        this.setState({ selectedCities: selectedCities });
    };

    onSearch = (city: any, selectedBranches: Array<any>, jobType: string)=> {
        this.props.onSearch(city, selectedBranches.map(b => b.value), jobType);
    };

    render() {
        const {
            state: {selectedBranches, branches, selectedCities, cities, jobType},
        } = this;

        return (
                <Container className={"search-bar-widget"}>
                    <Row>
                        <Col>
                            <div className={"job-type-wrapper"}>
                                <label className="control control-radio">
                                    Ausbildungen
                                    <input type="radio"
                                           name="jobType"
                                           value={"ausbildung"}
                                           checked={jobType === "ausbildung"}
                                           onChange={()=> this.setState({jobType: "ausbildung"})}
                                    />
                                    <div className="control_indicator"/>
                                </label>
                                <label className="control control-radio">
                                    Praktikumsplätze
                                    <input type="radio"
                                           name="jobType"
                                           value={"praktikum"}
                                           checked={jobType === "praktikum"}
                                           onChange={()=> this.setState({jobType: "praktikum"})}/>
                                    <div className="control_indicator"/>
                                </label>
                                <label className="control control-radio">
                                    Duales Studium
                                    <input type="radio"
                                           name="jobType"
                                           value={"studium"}
                                           checked={jobType === "studium"}
                                           onChange={()=> this.setState({jobType: "studium"})}/>
                                    <div className="control_indicator"/>
                                </label>
                                {jobType !== "" &&
                                    <div className={"clear-job-filter"} onClick={()=> this.setState({jobType: ""})}>
                                        <i className={"fa fa-times"}/>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="search-bar-container">
                            <Row noGutters className={"search-bar-wrapper"}>
                                <Col sm={4} xl={5}>
                                    <Select
                                        value={selectedCities}
                                        onChange={this.handleCityChange}
                                        options={cities}
                                        components={animatedComponents}
                                        ignoreCase={true}
                                        classNamePrefix={"city-dropdown"}
                                        className={"city-dropdown"}
                                        placeholder={"Region"}
                                    />
                                </Col>
                                <Col sm={4} xl={5}>
                                    <Select
                                        value={selectedBranches}
                                        onChange={this.handleBranchChange}
                                        options={branches}
                                        isMulti
                                        components={animatedComponents}
                                        ignoreCase={true}
                                        classNamePrefix={"branch-dropdown"}
                                        className={"branch-dropdown"}
                                        placeholder={"Branchen"}
                                    />
                                </Col>
                                <Col>
                                    <button onClick={()=> this.onSearch(selectedCities? selectedCities.value: "Alle Regionen", selectedBranches, jobType)} >
                                        <span>Firma finden</span>
                                    </button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                </Container>
        );
    }
}

export default SearchBar;
