import React, {Component} from 'react';
import AliceCarousel from 'react-alice-carousel';
import {Col, Container, Row} from "react-bootstrap";
import './starterCarousel.scss';
import StarterCard from "./StarterCard";

type Props = {
    starters: any;
}

class StarterCarousel extends Component<Props> {
    private Carousel: any;

    onCarouselUpdate = () => {
        console.log(this.Carousel.updater.enqueueForceUpdate)
        this.forceUpdate();
    };

    render() {

        const responsive = {
            0: { items: 1 },
            767: { items: 1 },
            1024: { items: 1 },
        };

        const {
            props: { starters }
        } = this;

        if(Object.keys(starters).length > 2){
            return (
                <Container className={"carousel-wrapper starter-wrapper-carousel"} style={{padding: 0}}>
                    <Row>
                        <Col style={{display: "flex", padding: 0}}>
                            <button className={"carousel-control"} onClick={()=> this.Carousel.slidePrev()}>
                                <i className="fas fa-chevron-left"/>
                            </button>
                            <div style={{width: "calc(100% - 80px)"}}>
                                <AliceCarousel
                                    responsive={responsive}
                                    fadeOutAnimation={true}
                                    infinite={false}
                                    autoHeight={true}
                                    preservePosition={true}
                                    transitionTimingFunction={"ease-in-out"}
                                    buttonsDisabled={true}
                                    ref={(el) => (this.Carousel = el)}
                                    mouseTrackingEnabled>
                                    {Object.keys(starters).map((reviewer: any) =>
                                        <StarterCard key={reviewer} starter={starters[reviewer]} onExpand={this.onCarouselUpdate}/>
                                    )}
                                </AliceCarousel>
                            </div>
                            <button className={"carousel-control"} onClick={()=> this.Carousel.slideNext()}>
                                <i className="fas fa-chevron-right"/>
                            </button>
                        </Col>
                    </Row>
                </Container>
            );
        }


        return (
           <Container style={{padding: 0}}>
               <Row noGutters>
                   {Object.keys(starters).map((reviewer: any) =>
                       <Col lg={12}><StarterCard key={reviewer} starter={starters[reviewer]} onExpand={this.onCarouselUpdate}/></Col>
                   )}
               </Row>
           </Container>
        )

    }
}

export default StarterCarousel;
