import React, {Component, useState} from 'react';
import {Toast} from "react-bootstrap";
import "./feedback.scss";


const Message = (props: any) => {
    const [show, setShow] = useState(props.show);
    const [type] = useState(props.type);
    const [text] = useState(props.text);
    const [title] = useState(props.title);
    const [image] = useState(props.image);

    return ( <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide className={`message-toast-item toast-status-${type}`}>
                <Toast.Header>
                    { type === "success" && <i className="fas fa-check"/> }
                    { type === "error" && <i className="fas fa-bomb"/> }
                    { type === "warning" && <i className="fas fa-exclamation-triangle"/> }
                    <strong className="mr-auto">{title}</strong>
                </Toast.Header>
                <Toast.Body>
                    {text}
                    {image &&
                        <>
                            <br/>
                            <img src={image.props.src} width={100} height={60} alt={""}/>
                        </>
                    }
                </Toast.Body>
            </Toast>);
};

type Props = {
    messages: Array<any>
}
class Feedback extends Component<Props> {
    render() {
        const {messages} = this.props;
        return (
            <div className={"feedback-wrapper"}>
                {messages.map((m: any) => <Message key={m.id} show={true} {...m}/>)}
            </div>
        );
    }
}

export default Feedback;
