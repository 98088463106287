import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import CompanyCard from "./CompanyCard";
import Carousel from "react-multi-carousel";
import ReactResizeDetector from 'react-resize-detector';
import "react-multi-carousel/lib/styles.css";
import './companyCarousel.scss';

type Props = {
    companies: any;
};


type State = {
    clickedCompanies: Array<any>;
    referenceWidth: number
};



class CompanyCarousel extends Component<Props, State> {
    state: State = {
        clickedCompanies: [],
        referenceWidth: 0
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (Object.keys(prevProps.companies || {}).sort().join() !== Object.keys(this.props.companies || {}).sort().join()) {
            this.filterLastClickedCompanies();
        }
    };

    shuffle = (a: any) => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    };

    filterLastClickedCompanies = () => {
        const {companies} = this.props;
        let lastClickedCompanies: Array<any> = JSON.parse(localStorage.getItem('last-clicked-companies') || "[]");

        let filteredCompanies = this.shuffle(Object.keys(companies).filter((c) => !lastClickedCompanies.includes(c))).join(" ");

        if (lastClickedCompanies.length < 6) {
            lastClickedCompanies = [...lastClickedCompanies, ...filteredCompanies.split(" ", 6 - lastClickedCompanies.length)];
        }

        let clickedCompanies = Object.keys(companies).filter((c) => lastClickedCompanies.includes(c)).map(c => {
            return {id: c, ...companies[c]}
        });

        this.setState({clickedCompanies: clickedCompanies});
    };

    handleComponentResizing = (width: any)=>{
        this.setState({referenceWidth: width} );
    };

    render() {

        const {clickedCompanies, referenceWidth} = this.state;

        const ButtonGroup = ({ next, previous}: any) => {

            return (
                <div className="carousel-button-group">

                    <button className={"react-multiple-carousel__arrow react-multiple-carousel__arrow--right"} onClick={() => next()}/>
                    <button className={"react-multiple-carousel__arrow react-multiple-carousel__arrow--left"} onClick={() => previous()}/>

                </div>
            );
        };

        if(clickedCompanies.length === 0){
            return null;
        }




        let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;



        return (
            <ReactResizeDetector handleWidth refreshRate={0} >
                { ({ width }: any)=>
                    <Container fluid className={"carousel-wrapper"} style={{
                        maxHeight: clickedCompanies.length === 0 ? 0 : "1000px",
                        padding: clickedCompanies.length === 0 ? 0 : "60px 0 20px"
                    }}>

                        <ReactResizeDetector refreshRate={0} onResize={(w: any)=> this.handleComponentResizing(w)}>
                            {()=> <Container>
                                <Row>
                                    <Col>
                                        <h3>Für dich empfohlene Firmen</h3>
                                        <h5>Sind die Firmen noch relevant für dich?</h5>
                                    </Col>
                                </Row>
                            </Container>}
                        </ReactResizeDetector>


                        <Row noGutters style={{marginBottom: "35px"}}>
                            <Col style={{
                                paddingLeft: (windowWidth - referenceWidth) / 2
                            }}>
                                <Carousel
                                    additionalTransfrom={0}
                                    autoPlaySpeed={3000}
                                    centerMode={false}
                                    className=""
                                    dotListClass=""
                                    arrows = {false}
                                    draggable
                                    focusOnSelect={false}
                                    infinite
                                    itemClass=""
                                    keyBoardControl
                                    minimumTouchDrag={80}
                                    partialVisible
                                    renderButtonGroupOutside={true}
                                    renderDotsOutside={false}
                                    responsive={
                                        {
                                            superLargeDesktop: {
                                                // the naming can be any, depends on you.
                                                breakpoint: { max: 4000, min: 3000 },
                                                items: 5,
                                                partialVisibilityGutter: 50
                                            },
                                            desktop: {
                                                breakpoint: { max: 3000, min: 1024 },
                                                items: 4,
                                                partialVisibilityGutter: 50
                                            },
                                            tablet: {
                                                breakpoint: { max: 1024, min: 464 },
                                                items: 2,
                                                partialVisibilityGutter: 50
                                            },
                                            mobile: {
                                                breakpoint: { max: 464, min: 0 },
                                                items: 1,
                                                partialVisibilityGutter: 50
                                            }
                                        }
                                    }
                                    showDots={false}
                                    sliderClass=""
                                    slidesToSlide={1}
                                    swipeable
                                    customButtonGroup={<ButtonGroup />}

                                >
                                    {clickedCompanies.map(c => <CompanyCard key={c.id} company={c}/>)}
                                </Carousel>
                            </Col>
                        </Row>
                    </Container>
                }
            </ReactResizeDetector>
        );


    }
}

export default CompanyCarousel;
