import React, {Component} from 'react';
import {Accordion, Card, Col, Row, useAccordionToggle} from "react-bootstrap";
import Util from "../../../util/Util";


function CustomToggle({ children, eventKey }: any) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {});

    return (
        <div
            className={"float-right"}
            onClick={decoratedOnClick}
        >
            {children}
        </div>

    );
}

type Props = {
    job: any,
    index: string
}


class JobAccordion extends Component<Props> {
    render() {
        const {type, title, description} = this.props.job;
        const {index} = this.props;

        return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col style={{maxWidth: "150px"}}>
                                <span>
                                    {type}
                                </span>
                            </Col>
                            <Col>
                                <span>
                                    {Util.decodeCharacters(title)}
                                </span>
                            </Col>
                            {description &&
                                <Col style = {{maxWidth: "50px"}}>
                                    <CustomToggle eventKey={index}>
                                        <i className="fas fa-chevron-down"/>
                                    </CustomToggle>
                                </Col>
                            }
                        </Row>

                    </Card.Header>
                    {description &&
                        <Accordion.Collapse eventKey={index}>
                            <Card.Body>
                                {description.map((text: any, i: number)=> <p key={i}>{Util.decodeCharacters(text)}</p>)}
                            </Card.Body>
                        </Accordion.Collapse>
                    }
                </Card>

        );
    }
}

export default JobAccordion;
