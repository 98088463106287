import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import "./lightbox.scss";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

type Props = {
    gallery: any
}


type State = {
    photoIndex: number,
    isOpen: boolean,
    photos: Array<any>,
    loading: boolean,
    loadedPhotos: number
}

export default class LightBox extends Component<Props, State> {

    state: State = {
        photoIndex: 0,
        isOpen: false,
        photos: [],
        loading: false,
        loadedPhotos: 0
    };

    componentDidMount(): void {
        this.loadImages();
    }

    loadImages = () => {
        let component = this;
        const {
            props: {gallery}
        } = this;

        let photos = Object.keys(gallery).sort().map((g: string)=> gallery[g].url);

        this.setState({
            loading: true
        });

        photos.forEach((photo: string)=> {
            let pic: any = new Image();
            pic.src= photo;
            pic.onload = function() {
                let item = {
                    src: photo,
                    width: pic.width,
                    height: pic.height
                };
                component.setState(prevState => ({
                    photos: [...prevState.photos, item],
                    loadedPhotos: prevState.loadedPhotos+1,
                    loading: !((prevState.loadedPhotos + 1) === photos.length)
                }));
            };
        });
    };

    render() {
        const { photoIndex, isOpen, photos} = this.state;

        return (
            <div className={"image-company-wrapper"} >

                { photos.map((image: any, index: number)=>
                    <img
                        key={index}
                        src={image.src}
                        alt=""
                        width={"150px"}
                        height={"150px"}
                        className={"img-thumbnail"}
                        onClick={() => this.setState({ isOpen: true, photoIndex: index  })}
                    />)
                }

                {isOpen && (
                    <Lightbox
                        mainSrc={photos[photoIndex].src}
                        nextSrc={photos[(photoIndex + 1) % photos.length].src}
                        prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].src}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + photos.length - 1) % photos.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % photos.length,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}
