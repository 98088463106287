import React, {Component} from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {SidebarContent, SidebarFooter, SidebarHeader} from "react-pro-sidebar/dist";
import "./aside.scss";
import {NavLink} from "react-router-dom";

type Props = {
    collapsed: boolean,
    toggled: boolean,
    handleToggleSidebar: Function,
    handleCollapsedChange: Function,
    dashboard: string;
    section: string;
}

class Aside extends Component<Props, {}> {

    handleToggleSidebar = (value: any) => {
        this.props.handleToggleSidebar(value);
    };

    handleCollapseSidebar = ()=> {
        this.props.handleCollapsedChange(!this.props.collapsed);
    };

    render() {
        const {
            props:{ collapsed, toggled, section, dashboard}
        }= this;

        return (
            <ProSidebar
                collapsed={collapsed}
                breakPoint="md"
                toggled={toggled}
                onToggle={this.handleToggleSidebar}
            >
                <SidebarHeader>
                    <div
                        style={{
                            padding: '0 24px',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            fontSize: 14,
                            letterSpacing: '1px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <img src={require("../../../assets/ui/logo.svg")} alt="" width={"30px"}/>{!collapsed && <span>&nbsp;&nbsp;Startzeit CMS</span>}
                    </div>
                </SidebarHeader>

                <SidebarContent>
                    <Menu iconShape="circle">
                        {/*<MenuItem icon={<i className="fas fa-home"/>}>
                            Home
                            <NavLink to={"/cms"} />
                        </MenuItem>*/}
                        <SubMenu
                            title={"Companies"}
                            defaultOpen={true}
                            icon={<i className="fas fa-building"/>}>
                            <MenuItem icon={<i className="fas fa-th-list"/>} active={(section === "company" && dashboard === "list")}>
                                Company list
                                <NavLink to={"/cms/company/list"} />
                            </MenuItem>
                            <MenuItem icon={<i className="fas fa-folder-plus"/>} active={(section === "company" && dashboard === "add")}>
                                Add new company
                                <NavLink to={"/cms/company/add"} />
                            </MenuItem>
                        </SubMenu>
                       {/* <SubMenu
                            title={"Advisers"}
                            icon={<i className="fas fa-id-card-alt"/>}>
                            <MenuItem icon={<i className="fas fa-th-list"/>}>
                                Advisers list
                                <NavLink to={"/cms/advisers/list"} />
                            </MenuItem>
                            <MenuItem icon={<i className="fas fa-user-plus"/>}>
                                Add new adviser
                                <NavLink to={"/cms/advisers/add"} />
                            </MenuItem>
                        </SubMenu>

                        <SubMenu
                            title={"Reviewers"}
                            icon={<i className="fas fa-star"/>}>
                            <MenuItem icon={<i className="fas fa-th-list"/>}>
                                Reviewers list
                                <NavLink to={"/cms/reviewers/list"} />
                            </MenuItem>
                            <MenuItem icon={<i className="fas fa-user-plus"/>}>
                                Add new reviewer
                                <NavLink to={"/cms/reviewers/add"} />
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            title={"Events"}
                            icon={<i className="fas fa-calendar-week"/>}>
                            <MenuItem icon={<i className="fas fa-th-list"/>}>
                                Events list
                                <NavLink to={"/cms/events/list"} />
                            </MenuItem>
                            <MenuItem icon={<i className="fas fa-calendar-plus"/>}>
                                Add new event
                                <NavLink to={"/cms/events/add"} />
                            </MenuItem>
                        </SubMenu>*/}
                    </Menu>
                </SidebarContent>

                <SidebarFooter>
                    <div className="sidebar-btn-wrapper">
                        <span className="sidebar-btn" onClick={this.handleCollapseSidebar}>
                            <i className="fas fa-chevron-left"/>
                        </span>
                    </div>
                </SidebarFooter>
            </ProSidebar>
        );
    }
}

export default Aside;
