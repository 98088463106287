import React, {Component} from 'react';
import {Button, Card, ListGroup} from "react-bootstrap";
import "./companyCard.scss";
import Util from "../../../util/Util";


type Props = {
    company: any
}
class CompanyCard extends Component<Props> {

    handleOnDragStart = (e: any) => e.preventDefault();
    render() {
        const {company} = this.props;
        const imageKeys = Object.keys(company.gallery || {});
        const image = company.information.logo? company.information.logo: imageKeys.length > 0? company.gallery[imageKeys[0]].url: undefined;
        const cover = company.information.cover? `url(${company.information.cover })`: `url(${require("../../../assets/bg/pattern.png")})`;
        const hasCover = company.information.cover && company.information.cover !== "";

        return (
            <div className={"card-wrapper-job"} >
                <Card>
                    {/*<Card.Img className={"card-image"} variant="top" src={cover} />*/}
                    <div className={"cover-card"} style={{backgroundImage: cover, backgroundSize: hasCover ? "cover": "150px" }}>

                        <img src={image} alt=""/>

                    </div>
                    <Card.Body>
                        <ListGroup variant="flush">
                            <ListGroup.Item className={"card-job-name"}>
                                {company.information.name}
                            </ListGroup.Item>
                            <ListGroup.Item className={"card-address"}>
                                <i className="fas fa-map-marker-alt"/> {Util.decodeCharacters(company.information.address)} {Util.decodeCharacters(company.information.zipcode)}, {Util.decodeCharacters(company.information.city)}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Button variant="outline-primary" className={"btn-go"} onClick={()=> window.location.href = `/company/${company.id}/${Util.decodeCharacters(company.information.name)}`}>
                                    <span>Mehr erfahren</span>
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default CompanyCard;
