import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import {Row, Col, ProgressBar} from "react-bootstrap";
import "./score.scss";

type Props = {
    companyId: any;
}

type State = {
    loadingData: boolean;
    company: any;
}

class Score extends Component<Props, State> {

    state: State = {
        loadingData: false,
        company: {}
    };


    componentDidMount(): void {
       this.loadRecord();
    }


    loadRecord = () => {
        this.setState({loadingData: true});
        firebase.database().ref(`companies/${this.props.companyId}`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
               this.setState({
                   company:  snapshot.val()
               })
            }
            this.setState({loadingData: false});
        });
    };

    getBasicInfoScore = (companyInformation: any) => {
      if(companyInformation){
          if(companyInformation.cover && companyInformation.cover !== "" &&  companyInformation.logo && companyInformation.logo !== ""){
              return 2
          }else if((companyInformation.cover === "" && companyInformation.logo === "") || (!companyInformation.cover && !companyInformation.logo)){
              return 0
          }else return 1;
      }
        return 0
    };

    getGalleryScore = (gallery: any, max: number) => {
        if(gallery){
            return Object.keys(gallery).length > max ? max: Object.keys(gallery).length;
        }
        return 0
    };

    getReviewerScore = (reviewers: any, max: number) => {
        if(reviewers){
            return Object.keys(reviewers).length > max ? max: Object.keys(reviewers).length;
        }
        return 0
    };

    getAdvisersScore = (advisers: any, max: number) => {
        if(advisers){
            return Object.keys(advisers).length > max ? max: Object.keys(advisers).length;
        }
        return 0
    };

    getJobScore = (jobs: any, max: number) => {
        if(jobs){
            return Object.keys(jobs).length > max ? max: Object.keys(jobs).length;
        }
        return 0
    };

    getEventScore = (events: any, max: number) => {
        if(events){
            return Object.keys(events).length > max ? max: Object.keys(events).length;
        }
        return 0
    };




    render() {
        const { state: {company}} = this;

        const weights = {
            basicInfo: 0.3,
            gallery:0.2,
            advisers:0.2,
            starters:0.05,
            jobs:0.05,
            events:0.2
        };

        const assumptions = {
            basicInfo: 2,
            gallery: 5,
            advisers: 1,
            starters: 3,
            jobs:3,
            events:1
        };

        const points = {
            basicInfo: this.getBasicInfoScore(company.information),
            gallery: this.getGalleryScore(company.gallery, assumptions.gallery),
            advisers: this.getAdvisersScore(company.advisers, assumptions.advisers),
            starters: this.getReviewerScore(company.reviewers, assumptions.starters),
            jobs: this.getJobScore(company.jobs, assumptions.jobs),
            events:this.getEventScore(company.events, assumptions.events)
        };

        const score = {
            basicInfo: points.basicInfo * 100 / assumptions.basicInfo,
            gallery: points.gallery * 100 / assumptions.gallery,
            advisers: points.advisers * 100 / assumptions.advisers,
            starters: points.starters * 100 / assumptions.starters,
            jobs: points.jobs * 100 / assumptions.jobs,
            events: points.events * 100 / assumptions.events,
        };

        const totalScore: any =
            ((score.basicInfo * weights.basicInfo / 100)+
            (score.gallery * weights.gallery / 100)+
            (score.advisers * weights.advisers / 100)+
            (score.starters * weights.starters / 100)+ (score.jobs * weights.jobs / 100)+
           (score.events * weights.events / 100))*100;


        return (


            <div className={"score-wrapper"}>
                <Row>
                    <Col>
                        <h4>Profile Strength</h4>
                    </Col>
                </Row>
                <Row className={"score-criteria-wrapper"} noGutters>
                    <Col>
                        <Row>
                            <Col>Basic Info</Col>
                        </Row>
                        <Row>
                            <Col>
                                <ProgressBar now={score.basicInfo} />
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        <Row>
                            <Col>Gallery</Col>
                        </Row>
                        <Row>
                            <Col>
                                <ProgressBar now={score.gallery} label={`${company.gallery? Object.keys(company.gallery).length: 0}/${assumptions.gallery}`}  />
                            </Col>
                        </Row>
                    </Col>


                    <Col>
                        <Row>
                            <Col>Advisers</Col>
                        </Row>
                        <Row>
                            <Col>
                                <ProgressBar now={score.advisers} label={`${company.advisers? Object.keys(company.advisers).length: 0}/${assumptions.advisers}`} />
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        <Row>
                            <Col>Starters</Col>
                        </Row>
                        <Row>
                            <Col>
                                <ProgressBar now={score.starters} label={`${company.starters? Object.keys(company.starters).length: 0}/${assumptions.starters}`} />
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        <Row>
                            <Col>Jobs</Col>
                        </Row>
                        <Row>
                            <Col>
                                <ProgressBar now={score.jobs} label={`${company.jobs? Object.keys(company.jobs).length: 0}/${assumptions.jobs}`}/>
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        <Row>
                            <Col>Events</Col>
                        </Row>
                        <Row>
                            <Col>
                                <ProgressBar now={score.events} label={`${company.events? Object.keys(company.events).length: 0}/${assumptions.events}`}/>
                            </Col>
                        </Row>
                    </Col>

                    <Col className={"total-score-wrapper"}>
                        <Row>
                            <Col className={"total-label"}>Profile Strength</Col>
                        </Row>
                        <Row>
                            <Col className={"score-value"}>{totalScore.toFixed(0)}%</Col>
                        </Row>
                    </Col>
                </Row>
            </div>

        );
    }
}

export default Score;
