import React, {Component} from 'react';
import {Form, Col, Card, Button} from "react-bootstrap";
import firebase from 'firebase/app';
import Util from "../../../util/Util";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import moment from 'moment';
const animatedComponents = makeAnimated();


type Props = {
    isRequired: boolean,
    onSuccess: Function
}

type State = {
    color: any,
    label: string,
    icon: any,
    validated: boolean,

}

class BranchForm extends Component<Props, State> {

    state: State = {
        color: {},
        label: "",
        icon: {},
        validated: false
    };

    handleOnCancel = () => {
        let button = document.getElementById("btn-trigger-popover-form-branch");
        if(button){
            button.click();
        }
    };

    handleColorChange = (color: Array<any>) => {
        this.setState({ color : color });
    };

    handleIconChange = (icon: Array<any>) => {
        this.setState({ icon : icon });
    };

    handleLabelChange= (e: any) => {
        this.setState({ label : e.target.value });
    };

    handleOnSubmit = () => {
        const  {label, color, icon} = this.state;
        if(label.trim() === "" || color === {} || icon === {} ){
            this.setState({validated: true});
            return null;
        }else{
            let data = {
                label: Util.decodeCharacters(label),
                icon: icon.value,
                color: color.value
            };
            firebase.database().ref(`app/branches`).push(data).then(()=>{
                this.props.onSuccess(
                    {
                        type: "success",
                        title: "Branch added",
                        text: "Branch added successfully!",
                        id: moment.now(),
                    }
                );
                this.handleOnCancel();
            });
        }
    };

    render() {
        const {
            props: {isRequired},
            state: {color, icon, validated, label}
        } = this;

        const colorOption = (color: string) => {
            return <div style={{color: color, textAlign: "center"}}>
                <i className="fas fa-circle"/>
            </div>
        };

        const iconOption = (icon: string) => {
            return <div style={{ textAlign: "center"}}>
                <i className={icon}/>
            </div>
        };

        const availableColors = [
            { value: '#007bff', label: colorOption('#007bff') },
            { value: '#28a745', label: colorOption('#28a745') },
            { value: '#ffc107', label: colorOption('#ffc107') },
            { value: '#dc3545', label: colorOption('#dc3545') },
            { value: '#17a2b8', label: colorOption('#17a2b8') },
            { value: '#6c757d', label: colorOption('#6c757d') },
            { value: '#343a40', label: colorOption('#343a40') }
        ];

        const availableIcons = [
            { value: 'fas fa-ring', label: iconOption('fas fa-ring') },
            { value: 'fas fa-laptop-code', label: iconOption('fas fa-laptop-code') },
            { value: 'fas fa-bus', label: iconOption('fas fa-bus') },
            { value: 'fas fa-umbrella-beach', label: iconOption('fas fa-umbrella-beach') },
            { value: 'fas fa-briefcase', label: iconOption('fas fa-briefcase') },
        ];

        return (
            <Card style={{ width: '100%' }} className={"form-branch-wrapper"}>
                <Card.Body>
                    <Form noValidate validated={validated}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Branch Label</Form.Label>
                                <Form.Control
                                    type="text"
                                    required={isRequired}
                                    value={label}
                                    onChange={this.handleLabelChange}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="color-branch">
                                <Form.Label>Color</Form.Label>
                                <Select
                                    value={color}
                                    onChange={this.handleColorChange}
                                    options={availableColors}
                                    components={animatedComponents}
                                    ignoreCase={true}
                                    classNamePrefix={`form-dropdown ${!color.value && validated ? "error-dropdown": ""}`}
                                    className={"form-dropdown"}
                                    placeholder={""}
                                    required
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="icon-branch">
                                <Form.Label>Icon</Form.Label>
                                <Select
                                    value={icon}
                                    onChange={this.handleIconChange}
                                    options={availableIcons}
                                    components={animatedComponents}
                                    ignoreCase={true}
                                    classNamePrefix={`form-dropdown  ${!icon.value && validated ? "error-dropdown": ""}`}
                                    className={"form-dropdown"}
                                    placeholder={""}
                                    required
                                />
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Button variant={"primary"} onClick={this.handleOnSubmit}>Create Branch</Button>
                    <Button variant={"secondary"} onClick={this.handleOnCancel}>Cancel</Button>
                </Card.Footer>
            </Card>

        );
    }
}

export default BranchForm;
