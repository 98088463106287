import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import NavProfile from "../profile/NavProfile";
import Feedback from "../feedback/Feedback";
import Score from "../score/Score";

type Props = {
    icon: string,
    companyId: string,
    dashboard: string,
    section: string,
    name: string,
    feedback: Array<any>
}

class HeaderDescription extends Component<Props> {
    render() {
        const {companyId, dashboard, section, name, icon, feedback} = this.props;
        return (
            <>
                <Row noGutters>
                    <Col>
                        <Score companyId={companyId} />
                    </Col>
                </Row>
                <NavProfile companyId={companyId} dashboard={dashboard} section={section}/>
                <Row noGutters>
                    <Col className={"portlet-header"}>
                        <h4>
                            <i className={icon}/>&nbsp;
                            <span>{name}</span>
                        </h4>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                    </Col>
                </Row>

                <Feedback messages={feedback}/>
            </>
        );
    }
}

export default HeaderDescription;
