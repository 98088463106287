import React, {Component} from 'react';
import { Col, Container,  Nav, Navbar, Row} from "react-bootstrap";
import "./dashboard.scss";
import CompanyForm from "../forms/CompanyForm";
import CompanyList from "../lists/CompanyList";
import StarterForm from "../forms/StarterForm";
import AdviserForm from "../forms/AdviserForm";
import AdviserList from "../lists/AdviserList";
import StarterList from "../lists/StarterList";
import EventForm from "../forms/EventForm";
import EventList from "../lists/EventList";
import CompanyProfile from "../profile/CompanyProfile";
import JobList from "../lists/JobList";
import JobForm from "../forms/JobForm";
import Gallery from "../gallery/Gallery";
type Props = {
    collapsed: boolean;
    toggled: boolean;
    handleToggleSidebar: Function;
    handleCollapsedChange: Function;
    dashboard: string;
    section: string;
    companyId: string;
    record: string;
};

type State = {

};
class Dashboard extends Component<Props, State> {

    dashboardDispatcher = (section: string, dashboard: string)=> {

        const {
            props:{companyId, record}
        } = this;

        switch (`${section}/${dashboard}`) {
            case "company/list": return <CompanyList/>;

            case "company/add": return <CompanyForm mode={"CREATE"} companyId={companyId}/>;
            case "starters/add": return <StarterForm mode={"CREATE"} companyId={companyId} section={section} dashboard={dashboard} record={record}/>;
            case "advisers/add": return <AdviserForm mode={"CREATE"} companyId={companyId} section={section} dashboard={dashboard} record={record}/>;
            case "events/add": return <EventForm mode={"CREATE"} companyId={companyId} section={section} dashboard={dashboard} record={record}/>;
            case "jobs/add": return <JobForm mode={"CREATE"} companyId={companyId} section={section} dashboard={dashboard} record={record}/>;

            case "company/edit": return <CompanyForm mode={"EDIT"} companyId={companyId}/>;
            case "starters/edit": return <StarterForm mode={"EDIT"} companyId={companyId} section={section} dashboard={dashboard} record={record}/>;
            case "advisers/edit": return <AdviserForm mode={"EDIT"} companyId={companyId} section={section} dashboard={dashboard} record={record}/>;
            case "events/edit": return <EventForm mode={"EDIT"} companyId={companyId} section={section} dashboard={dashboard} record={record}/>;
            case "jobs/edit": return <JobForm mode={"EDIT"} companyId={companyId} section={section} dashboard={dashboard} record={record}/>;

            case "company/gallery": return <Gallery companyId={companyId} section={section} dashboard={dashboard}/>;
            case "company/profile": return <CompanyProfile companyId={companyId} section={section} dashboard={dashboard}/>;
            case "starters/list": return <StarterList companyId={companyId} section={section} dashboard={dashboard}/>;
            case "advisers/list": return <AdviserList companyId={companyId} section={section} dashboard={dashboard}/>;
            case "events/list": return <EventList companyId={companyId} section={section} dashboard={dashboard}/>;
            case "jobs/list": return <JobList companyId={companyId} section={section} dashboard={dashboard}/>;

            default: return <div>Dashboard not found</div>
        }
    };

    render() {

        const {
            props: {toggled, handleToggleSidebar, section, dashboard}
        }= this;

        return (
            <main className={"dashboard-main-wrapper"}>
                <Container fluid style={{padding: "0", height: "100%"}}>
                    <Row noGutters className={"h-100"}>
                        <Col className={"dashboard-viewer"}>
                            <Row noGutters>
                                <Col className={"navbar-wrapper"}>
                                    <Navbar bg="light" variant="light">
                                        <Navbar.Toggle aria-controls="global-navbar-nav" />
                                        <Navbar.Collapse id="global-navbar-nav">
                                            <Nav className="mr-auto">
                                                {!toggled &&
                                                    <Nav.Link className="d-block d-sm-block d-md-none">
                                                        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
                                                            <i className="fas fa-bars"/>
                                                        </div>
                                                    </Nav.Link>
                                                }
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Navbar>
                                </Col>
                            </Row>
                            <Row noGutters style={{height: "100%"}}>
                                <Col style={{display: "flex", flexDirection:"column" }}>
                                    {this.dashboardDispatcher(section, dashboard)}
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </main>
        );
    }
}

export default Dashboard;
