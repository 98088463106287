import React, {Component} from 'react';
import {Card, Row, Col} from "react-bootstrap";
import Util from "../../../util/Util";
import "./starterCard.scss";

type Props = {
    starter: any,
    onExpand: Function
}

type State = {
    showMore: boolean
}

class StarterCard extends Component<Props, State> {

    state: State = {
        showMore: true
    };

    /*toggleShowMore = () =>{
        this.setState(prevState => ({showMore: !prevState.showMore}));
        this.props.onExpand();
    };*/

    render() {
        const {
            props: {starter},
            state: {showMore}
        } = this;

        let stars = Array.from({length: starter.rating ? Math.round(parseInt(starter.rating.toString())/10) : 0}, (_ : any, i: number) => i + 1);

        return (
            <Card className={"starter-card-wrapper"}>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            {starter.photo && starter.photo !== "" &&
                                <Col style={{width: "60px", maxWidth: "60px", padding: "0 0 0 10px"}}>
                                    <img src={starter.photo} alt=""/>
                                </Col>
                            }
                            <Col>
                                {Util.decodeCharacters(starter.name)}
                                <span className={" age"}>
                                    {starter.age} Jahre alt
                                </span>
                                {starter.rating &&
                                    <div className={"start-wrapper"}>
                                        {stars.map((s: any, i: number) => <i key={i} className="fas fa-star"/>)}
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Card.Title>
                    <hr/>
                    <Card.Title>{Util.decodeCharacters(starter.job)}</Card.Title>
                    <div>
                        <div className="showMore" style={{
                            lineHeight: "18px",
                            maxHeight: showMore? "1000000px" : "54px", /* line-height * 3 */
                            overflow: "hidden"

                        }}>
                            {showMore && starter.review && starter.review.map((paragraph: string, key: number)=>
                                <div key={key} className={"description"}>{Util.decodeCharacters(paragraph)}</div>)
                            }
                           {!showMore && starter.review && starter.review[0] && <span>{Util.decodeCharacters(starter.review[0])}</span>}
                        </div>
                        {/*<a onClick={this.toggleShowMore}>
                            {!showMore ? "Mehr lesen": "Weniger lesen"}
                        </a>*/}

                    </div>
                </Card.Body>
            </Card>
        );
    }
}

export default StarterCard;
