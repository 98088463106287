import React, {Component} from 'react';
import {Container, Row, Col, Badge} from "react-bootstrap";
import './jobCheckHero.scss';

class JobCheckHero extends Component {
    render() {
        return (

            <Container fluid style={{backgroundImage: `url(${require('../../../assets/images/jobCheck/job-check.svg')})`}} className={"job-check-hero-wrapper"}>
               <Row>
                   <Col style={{display: "flex", height: "50vh"}}>
                       <Container style={{margin: "auto"}}>
                           <Row>
                               <Col md={7}>
                                   <Row>
                                       <Col>
                                           <h3>
                                               DU WEIßT NOCH NICHT WAS DU
                                               MACHEN WILLST?
                                           </h3>
                                           <p>
                                                "Hey mein Kind, hast du dir eigentlich schon überlegt was du beruflich machen möchtest?" Musstest du dir diese Frage auch so oft anhören wie wir. Ja? Dann ist ja super, weil uns hat das nämlich tierisch genervt und deswegen haben wir uns überlegt wie wir dir helfen können aus deinem Talent das Beste zu machen? Ich, zum Beispiel wusste nur , dass ich richtig gut bin mit Technik und das mich Webseiten unheimlich fasziniert haben.  - aber ich hatte damals keine Ahnung wie man seine Leidenschaft zum Job machen konnte, daher habe ich irgendwann mal angefangen aufzuschreiben was ich gut kann und jemandn hat mir dann dabei geholfen die passenden Ausbildungen aufzulisten. Naja kurze Rede, langer Sinn - am Ende habe ich als Progammierer bei einem Industrieunternehmen angefangen und digitalisiere zum Beispiel "Das Bauen von Metallzylindern" und dann habe ich diesen Check entwickelt um euch zu helfen den passenden Job zu finden.
                                           </p>
                                           <h1>
                                               <Badge variant="success">Demnächst verfügbar</Badge>
                                           </h1>
                                       </Col>
                                   </Row>
                               </Col>
                           </Row>
                       </Container>
                   </Col>
               </Row>
            </Container>


        );
    }
}

export default JobCheckHero;
