import React, {Component} from 'react';
import {Card, Col, Container, Row, Button, Form, Image, Spinner} from "react-bootstrap";
import Select from 'react-select';
import firebase from 'firebase/app';
import "react-dates/initialize";
import 'antd/dist/antd.css';
import "../dashboard/dashboard.scss";
import "react-dates/lib/css/_datepicker.css";
import "./portlet.scss";
import { TimePicker } from 'antd';
import { DatePicker } from 'antd';
import Util from "../../../util/Util";
import {NavLink} from "react-router-dom";
import {Linode} from "../../etc/Config";
import AWS from 'aws-sdk';
AWS.config = new AWS.Config();
AWS.config.accessKeyId = Linode.accessKeyId;
AWS.config.secretAccessKey = Linode.secretAccessKey;
AWS.config.region = Linode.region;
const s3 = new AWS.S3({endpoint: Linode.endpoint});
const hostingURL = Linode.hostingURL;

const { RangePicker } = TimePicker;
const moment = require('moment');

type Props = {
    companyId: string,
    mode: "EDIT" | "CREATE",
    section: string,
    dashboard: string,
    record:string
};

type State = {
    validated: boolean,
    deeplink: string,
    roomLink: string,
    description: Array<string>,
    date: number,
    starts: string | null,
    ends: string| null,
    name: string,
    gallery: Array<string>,
    status: any,
    loadingData: boolean;
    image: string | null;
    file: any | null;
    inputFileValue: any;
    isDataUploading: boolean;
};

class EventForm extends Component<Props, State> {

    state:State = {
        validated: false,
        deeplink: "",
        description: [],
        date: moment().startOf("day").valueOf(),
        starts: "00:00",
        ends: "23:59",
        name: "",
        roomLink: "",
        gallery: [],
        status: {value: "offline", label: "Offline"},
        image: "",
        loadingData: false,
        file: null,
        inputFileValue: "",
        isDataUploading: false,
    };

    componentDidMount(): void {
        if(this.props.mode === "EDIT"){
            this.loadRecord();
        }
    }

    loadRecord = () => {
        const {companyId, record} = this.props;
        this.setState({loadingData: true});

        firebase.database().ref(`companies/${companyId}/events/${record}`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState(
                    {
                        deeplink: Util.decodeCharacters(snapshot.val().deeplink),
                        description: snapshot.val().description,
                        starts: "Invalid date" === snapshot.val().starts ? "00:00" : moment(snapshot.val().starts, 'HH:mm').format('HH:mm'),
                        date: snapshot.val().date,
                        ends: "Invalid date" === snapshot.val().ends ?  "23:59": moment(snapshot.val().ends, 'HH:mm').format('HH:mm') ,
                        name: Util.decodeCharacters(snapshot.val().name),
                        image:   snapshot.val().gallery && snapshot.val().gallery[0] ? snapshot.val().gallery[0]: "",
                        status: {value: snapshot.val().status, label: snapshot.val().status},
                        roomLink: Util.decodeCharacters(snapshot.val().roomLink)
                    }, ()=> this.setState({loadingData: false})
                )
            }else{
                this.setState({loadingData: false});
            }
        });
    };

    handleSubmit= (event: any)=>{
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === true ) {
            this.addEvent();
        }
        this.setState({validated: true});
    };

    addEvent = () => {
        const {
            deeplink,
            description,
            starts,
            ends,
            name,
            status,
            date,
            roomLink,
            file
        } = this.state;

        const {
            mode,
            record,
            companyId
        } = this.props;

        const data = {
            deeplink:deeplink,
            description:description,
            starts: starts,
            date: date,
            ends: ends,
            name:name,
            status: status.value,
            roomLink: roomLink
        };

        this.setState({isDataUploading: true});

        if(mode === "CREATE"){
            firebase.database().ref(`companies/${companyId}/events/`).push(data
            ).then((snap)=>{
                this.storeImages(file, companyId, snap.key || "def");
            });
        }

        if(mode === "EDIT"){
            firebase.database().ref(`companies/${companyId}/events/${record}`).update(data
            ).then(()=>{
                this.storeImages(file, companyId, record);
            });
        }
    };

    storeImages = (file: any, companyId: string, record: string) => {
        if(file){
            let params = {
                Body: file,
                Bucket: Linode.Bucket,
                Key: `companies/${companyId}/events/${record}`,
                ACL:'public-read'
            };

            s3.putObject(params, (err: any) => {
                if(!err){
                    let url = `${hostingURL}/companies/${companyId}/events/${record}`;
                    firebase.database().ref(`companies/${this.props.companyId}/events/${record}/gallery`).update({0: url}).then(()=>{
                        window.location.href = Util.getPath(`cms/${companyId}/events/list`);
                        this.setState({isDataUploading: false});
                    });
                }else{
                    this.setState({isDataUploading: false});
                    alert("error");
                }
            });
        }else{
            window.location.href = Util.getPath(`cms/${companyId}/events/list`);
        }
    };

    handleOnTextChange = (e: any, key: any) => {
         let value = e.target.value;
        // @ts-ignore
        this.setState({[key]: value});
    };

    handleTextAreaOnChange = (e: any) => {
        let value = e.target.value.toString();
        this.setState({description: value.split(/\r?\n/)});
    };

    handleStatusChange  = (selectedOptions: any) => {
        this.setState({ status : selectedOptions  });
    };

    onDateChange= (date: any) => {
        this.setState({ date: date.startOf("day").valueOf()});
    };

    onTimeChange= (time: any,  timesFormat: Array<string>) => {
        this.setState({ starts: timesFormat[0], ends: timesFormat[1] });
    };

    handleLoadFile = (e: any) => {
        if(e && e.target && e.target.files.length > 0){
            let file = e.target.files[0];
            Util.imageToBase64(file).then((image: any)=>{
                this.setState({file: file, image: image, inputFileValue: ""});
            });
        }
    };

    render() {
        const {
            state: {
                validated,
                deeplink,
                description,
                name,
                status,
                date,
                image,
                roomLink,
                ends,
                loadingData,
                starts,
                inputFileValue,
                file,
                isDataUploading
            },
            props:{companyId, section, mode}
        } = this;
        const statusOptions = [
            {
                value: "offline",
                label: "Offline"
            },
            {
                value: "online",
                label: "Online"
            }
        ];

        if(loadingData && mode === "EDIT"){
            return null;
        }

        return (
            <Container className={"portlet-wrapper"}>
                <Row noGutters>
                    <Col className={"portlet-header"}>
                        <h4>
                            <i className="fas fa-calendar-alt"/>&nbsp;
                            <span>{mode === "CREATE" ? "Add Event": "Edit Event" }</span>
                        </h4>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                            <Card className={"card-form-portlet"}>
                                <Card.Header as="h5">General Information</Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Event name</Form.Label>
                                            <Form.Control
                                                value={name}
                                                onChange={(e)=> this.handleOnTextChange(e, "name")}
                                                required
                                                type="text"/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Status</Form.Label>
                                            <Select
                                                value={status}
                                                onChange={(e: any)=> this.handleStatusChange(e)}
                                                options={statusOptions}
                                                classNamePrefix={`form-dropdown`}
                                                className={"form-dropdown"}
                                                placeholder={"Select time slot"}
                                                required
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Event date</Form.Label>
                                            <div className={"datetime-picker-wrapper"}>
                                                <DatePicker
                                                    onChange={this.onDateChange}
                                                    bordered={ false}
                                                    format={"DD.MM.YYYY"}
                                                    allowClear={false}
                                                    value={moment.unix(date/1000)}
                                                    disabledDate={(current: any)=>{
                                                        return current && current < moment().startOf("day");
                                                    }}
                                                    suffixIcon={<i className="far fa-calendar-alt" />}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Event time</Form.Label>
                                            <div className={"time-picker-wrapper"}>
                                                <RangePicker
                                                    format={'HH:mm'}
                                                    picker={moment('00:00', 'HH:mm')}
                                                    suffixIcon={<i className="fas fa-clock"/>}
                                                    minuteStep={10}
                                                    placeholder={["from", "to"]}
                                                    className={"time-picker"}
                                                    onChange={this.onTimeChange}
                                                    defaultValue={[moment(starts, 'HH:mm'), moment(ends, 'HH:mm')]}
                                                 />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Video conference link</Form.Label>
                                            <Form.Control
                                                value={roomLink}
                                                onChange={(e)=> this.handleOnTextChange(e, "roomLink")}
                                                required
                                                type="text"/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Row>
                                        <Col>
                                            <Form.Label>Cover Image</Form.Label>
                                            <div className="custom custom-file">
                                                <input
                                                    type="file"
                                                    accept="image/png, image/jpeg, image/jpg"
                                                    className={"custom-file-input"}
                                                    id="fileLogo"
                                                    onChange={this.handleLoadFile}
                                                    required={!image || image === ""}
                                                    value={inputFileValue}
                                                />
                                                <label
                                                    className={"custom-file-label"}
                                                    htmlFor="fileLogo">{file? file.name : image && image !==""? "Change cover image": "Select cover image"}</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    { image && image !== "" &&
                                        <Row>
                                            <Col lg={6}>
                                                <div className={"image-form-wrapper"}>
                                                    <Button
                                                        variant={"danger"}
                                                        className={"btn-delete-image"}
                                                        onClick={()=> this.setState({
                                                            image: null,
                                                            file: null,
                                                            inputFileValue: ""
                                                        })}>
                                                        <i className="fas fa-times"/>
                                                    </Button>
                                                    <Image src={image} thumbnail />
                                                </div>

                                            </Col>
                                        </Row>
                                    }
                                    <Form.Row style={{marginTop: "15px"}}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Link to get more information</Form.Label>
                                            <Form.Control
                                                value={deeplink}
                                                onChange={(e)=> this.handleOnTextChange(e, "deeplink")}
                                                required
                                                type="text"/>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                value={description.join("\r\n")}
                                                onChange={(e)=> this.handleTextAreaOnChange(e)}
                                                required
                                                as="textarea"
                                                rows={5}
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <Button variant={!isDataUploading ? "primary": "outline-primary"} type="submit" disabled={isDataUploading}>
                                        {mode === "CREATE"?  (!isDataUploading? "Add": "Adding"):  (!isDataUploading? "Update": "Updating") } event
                                        {isDataUploading &&
                                        <>  &nbsp;
                                            <Spinner animation="grow" variant="primary" size="sm" />
                                        </>
                                        }
                                    </Button>
                                    <NavLink to={`/cms/${companyId}/${section}/list`}>
                                        <Button variant={"secondary"}>
                                            Cancel
                                        </Button>
                                    </NavLink>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default EventForm;
