import React, {Component} from 'react';
import {Card, Form, Button, Modal, Image, Spinner} from "react-bootstrap";
import "./imageCard.scss";
import Util from "../../../util/Util";
import firebase from 'firebase/app';
import moment from 'moment';

import AWS from 'aws-sdk';

AWS.config = new AWS.Config();
AWS.config.accessKeyId = "D7NIQ591W4POYVPR89G4";
AWS.config.secretAccessKey = "fm97XGhW3aBAsYO3mCXsskPw7NsajO8YbNPVHxzD";
AWS.config.region = "eu-central-1";

const s3 = new AWS.S3({endpoint: "eu-central-1.linodeobjects.com"});

type Props = {
    companyId: string;
    imageId: any;
    image: any;
    pushFeedback: Function;
    showForm: boolean;
}

type State = {
    caption: string,
    headline: string,
    hasBeenModified: boolean,
    showModalDelete: boolean,
    isVisible: boolean,
    imageStatus: "failed" | "loading" | "loaded",
}

class ImageCard extends Component<Props, State> {

    state: State = {
        headline: Util.decodeCharacters(this.props.image.headline)  || "",
        caption: Util.decodeCharacters(this.props.image.caption)  || "",
        hasBeenModified: false,
        showModalDelete: false,
        isVisible: true,
        imageStatus: "loading",
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.image.caption !== this.props.image.caption){
            this.setState({
                caption: this.props.image.caption
            })
        }

        if(prevProps.image.headline !== this.props.image.headline){
            this.setState({
                headline: this.props.image.headline
            })
        }
    }

    handleImageChange = (key: "caption" | "headline", index: number, value: string) => {
        // @ts-ignore
        this.setState({[key]: value});
        this.setState({hasBeenModified: value !== this.props.image[key]});
    };

    onCancel = () => {
        this.setState({
            caption: this.props.image.caption,
            headline: this.props.image.headline,
            hasBeenModified: false,
        })
    };

    handleImageLoaded = () => {
        this.setState({ imageStatus: "loaded" });
    };

    handleImageErrored = () => {
        this.setState({ imageStatus: "failed" });
    };


    onUpdate = () => {
        let {
            props: {companyId, imageId, pushFeedback, image},
            state: { caption, headline }
        } = this;
        let data = {
            caption: Util.encodeCharacters(caption),
            headline: Util.encodeCharacters(headline)
        };
       firebase.database().ref(`companies/${companyId}/gallery/${imageId}`).update(data).then(()=>{
           this.setState({
               hasBeenModified: false,
           });
           pushFeedback({
               type: "success",
               title: "Image updated",
               text: "Image information has been updated!",
               id: moment.now(),
               image: <img src={image.url} alt="" width={80}/>
           })
        }).catch(()=>{
               pushFeedback({
                   type: "error",
                   title: "Oops we encounter an error",
                   text: "Please try again later",
                   id: moment.now(),
               })
           }
       );
    };

    deleteImage = () => {


        let params = {
            Bucket: "startzeit-digital-bucket",//`companies/${companyId}/gallery`,
            Key: `companies/${this.props.companyId}/gallery/${this.props.imageId}`,
        };


        s3.deleteObject(params, (err) => {
            if(err){

            }else{
                firebase.database()
                    .ref(`companies/${this.props.companyId}/gallery/`)
                    .update({[this.props.imageId]: null}).then(()=>{
                    this.setState({showModalDelete: false, isVisible: false});
                    this.props.pushFeedback({
                        type: "success",
                        title: "Image deleted",
                        text: "Image was deleted successfully!",
                        id: moment.now(),
                        image: <img src={this.props.image.url} alt="" width={80}/>
                    })
                }).catch( ()=> {
                    this.setState({showModalDelete: false});
                    this.props.pushFeedback({
                        type: "error",
                        title: "Oops we encounter an error",
                        text: "Please try again later",
                        id: moment.now(),
                    })
                });

            }
        });



        /*firebase.storage().ref(`companies/${this.props.companyId}/gallery/${this.props.imageId}`).delete().then(() => {
            firebase.database()
                .ref(`companies/${this.props.companyId}/gallery/`)
                .update({[this.props.imageId]: null}).then(()=>{
                this.setState({showModalDelete: false, isVisible: false});
                this.props.pushFeedback({
                    type: "success",
                    title: "Image deleted",
                    text: "Image was deleted successfully!",
                    id: moment.now(),
                    image: <img src={this.props.image.url} alt="" width={80}/>
                })
            }).catch( ()=> {
                this.setState({showModalDelete: false});
                this.props.pushFeedback({
                    type: "error",
                    title: "Oops we encounter an error",
                    text: "Please try again later",
                    id: moment.now(),
                })
            });
        });*/
    };

    setDisplayMode = (mode: "cover" | "contain") =>{
        firebase.database()
            .ref(`companies/${this.props.companyId}/gallery/${this.props.imageId}`)
            .update({displayMode: mode}).then(()=>{
        }).catch( ()=> {});
    };

    render() {
        const {
            props: { image, imageId, showForm },
            state: { caption, headline, hasBeenModified, showModalDelete, isVisible, imageStatus}
        } = this;

        const mode = image.displayMode || "contain";

        if(!isVisible){
            return null;
        }


        return (
            <>
                <Card className={"card-image-gallery-card"}>
                    <div className="card-img-top">
                        { imageStatus === "loading" &&
                            <Spinner animation="grow" variant="secondary" />
                        }

                        <img src={image.url}
                             alt=""
                             width={"100%"}
                             height={"100%"}
                             onLoad={this.handleImageLoaded}
                             onError={this.handleImageErrored}
                             style={{opacity: imageStatus === "loaded"? 1: 0}}
                             className={`img-${mode}`}
                        />

                        { imageStatus === "loaded" &&
                            <div className={"btn-wrappers"}>
                                <Button variant={"danger"} className={"btn-delete-image"} onClick={()=> this.setState({showModalDelete: true})}>
                                    <i className="fas fa-times"/>
                                </Button>
                                <Button variant={mode === "cover"? "primary": "light"} className={"btn-cover"} onClick={()=> this.setDisplayMode("cover")}>
                                    <i className="fas fa-expand"/>
                                </Button>
                                <Button variant={mode === "contain"? "primary": "light"}  className={"btn-contain"} onClick={()=> this.setDisplayMode("contain")}>
                                    <i className="fas fa-compress"/>
                                </Button>
                            </div>
                        }
                    </div>

                    {showForm &&
                        <>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Label>Headline</Form.Label>
                                    <Form.Control type="text" required value={headline} onChange={(e) => this.handleImageChange("headline", imageId, e.target.value )}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Caption</Form.Label>
                                    <Form.Control type="text" required value={caption} onChange={(e) => this.handleImageChange("caption", imageId, e.target.value)}/>
                                </Form.Group>
                            </Card.Body>
                            <Card.Footer>
                                <Button disabled={!hasBeenModified} variant={hasBeenModified ? "success": "secondary"} onClick={this.onUpdate}>
                                    Update
                                </Button>
                                {" "}
                                {hasBeenModified &&
                                <Button disabled={!hasBeenModified} variant={"danger"} onClick={this.onCancel}>
                                    Cancel
                                </Button>
                                }
                            </Card.Footer>
                        </>
                    }
                </Card>
                <Modal show={showModalDelete} onHide={()=> this.setState({showModalDelete: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Image?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            You are about to delete the following image:
                        </p>
                        <div style={{maxWidth: "100px"}} >
                            <Image src={image.url} thumbnail />
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.deleteImage} >Delete</Button>
                        <Button variant="secondary" onClick={()=> this.setState({showModalDelete: false})}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </>

        );
    }
}

export default ImageCard;
