import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import "./dndZone.scss";


type Props = {
    onDrop: Function;
}

class DnDZone extends Component<Props, {}> {
    render() {
        const {onDrop} = this.props;
        return (
            <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} accept={["image/png", "image/jpg", "image/jpeg"]}>
                {({getRootProps, getInputProps}) => (
                    <div className={"drop-zone-wrapper"}>
                        <div {...getRootProps()} >
                            <input {...getInputProps()} />
                            <i className="fas fa-cloud-upload-alt"/>
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                    </div>
                )}
            </Dropzone>
        );
    }
}

export default DnDZone;
