import React, {Component} from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import firebase from 'firebase/app';
import Util from "../../../util/Util";
import Loading from "../shared/loading/Loading";
import "./portletTable.scss";
import {NavLink} from "react-router-dom";
import ModalDelete from "../modal/ModalDelete";
import moment from 'moment';
import Feedback from "../feedback/Feedback";

const ActionCell = (row: any)=> {
  return (
      <div className={"cell-action-wrapper"}>
          <NavLink to={`/cms/${row.original.id}/company/edit`}>
              <Button>
                  Edit
              </Button>
          </NavLink>
          <Button
              variant={"danger"}
              onClick={()=> row.original.component.setState({
                  showModalDelete: true,
                  record: {
                      id: row.original.id,
                      name: row.original.company
                  }
              })}>
              Delete
          </Button>
      </div>
  );
};

const linkCell = (id:string, value: any, section: string, dashboard: string, className?: string)=>{
    return (
        <div className={`link-cell ${className}`}>
            <NavLink to={`/cms/${id}/${section}/${dashboard}`}>
                <span>{className==="warning" && <i className="fas fa-exclamation-circle"/>} {value}</span>&nbsp;
                <i className="far fa-arrow-alt-circle-right"/>
            </NavLink>
        </div>

    )
};

const columns = [
    {
        Header: <div className={"table-th"}>Company</div>,
        accessor: 'company'
    },
    {
        Header: <div className={"table-th-icon"}><i className={"fas fa-images"}/>&nbsp; Gallery</div>,
        accessor: 'gallery',
        width: 100,
        minWidth: 100
    },
    {
        Header: <div className={"table-th-icon"}><i className={"fas fa-id-card-alt"}/>&nbsp; Advisers</div>,
        accessor: 'advisers',
        width: 100,
        minWidth: 100
    },
    {
        Header: <div className={"table-th-icon"}><i className={"fas fa-user-tie"}/>&nbsp; Starters</div>,
        accessor: 'starters',
        width: 100,
        minWidth: 100
    },
    {
        Header: <div className={"table-th-icon"}><i className={"fas fa-briefcase"}/>&nbsp; Jobs</div>,
        accessor: 'jobs',
        width: 100,
        minWidth: 100
    },
    {
        Header: <div className={"table-th-icon"}><i className={"fas fa-calendar-alt"}/>&nbsp; Events</div>,
        accessor: 'events',
        width: 100,
        minWidth: 100,
    },
    {
        Header: <div className={"table-th-icon"}><i className={"fas fa-globe-europe"}/>&nbsp; Go live</div>,
        accessor: 'goLife',
        width: 100,
        minWidth: 100,
    },
    {
        Header: 'Actions',
        Cell: ActionCell,
        width: 150,
        minWidth: 150
    }
];

type State = {
    searchField: string,
    companies: Array<any>,
    filteredCompanies: Array<any>,
    loading: boolean,
    selectAll: boolean,
    selection: Array<any>,
    loadingData: boolean,
    showModalDelete: boolean,
    feedback: Array<any>;
    record: {
        name: string,
        id: string,
    };
};

type Props = {

};

class CompanyList extends Component<Props, State> {
    // @ts-ignore
    checkboxTable: ReactTable;

    state: State = {
        searchField: "",
        companies: [],
        filteredCompanies: [],
        loading: false,
        selectAll: false,
        selection: [],
        loadingData: true,
        showModalDelete: false,
        feedback: [],
        record: {
            name: "",
            id: ""
        }
    };

    componentDidMount(): void {
        this.setState({loadingData: true});
        firebase.database().ref(`companies/`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState({companies: this.mapCompanies(snapshot.val()), loadingData: false});
            }else{
                this.setState({loadingData: false});
            }

        }, function(error: any) {
            if (error) {
                console.log(error);
            }
        });
    }

    mapCompanies = (companies: any): Array<any> => {

        const toggle = (key:string, name: string, isLife: boolean)=>{
            return (
                <div className={`toggle-cell`}>
                    <Form>
                        <Form.Check
                            type="switch"
                            id={key}
                            label=""
                            checked={isLife}
                            onChange={()=>this.toggleCompany(key, name, isLife)}
                        />
                    </Form>
                </div>
            )
        };

        return Object.keys(companies).map((key: any)=> {
            let company = companies[key];
            let gallery = company.gallery ? Object.keys(company.gallery).length: 0;
            let advisers= company.advisers ? Object.keys(company.advisers).length: 0;
            let starters= company.starters ? Object.keys(company.starters).length: 0;
            let jobs= company.jobs ? Object.keys(company.jobs).length: 0;
            let events= company.events ? Object.keys(company.events).length: 0;
            let name = Util.decodeCharacters(company.information.name);
            let isLife = company.information.isLife;
            return {
                id: key,
                company:  linkCell(key, name,     "company",  "profile", "left"),
                gallery:  linkCell(key, gallery,  "company",  "gallery", `${gallery ===0 ? "warning": ""}`),
                advisers: linkCell(key, advisers, "advisers", "list", `${advisers ===0 ? "warning": ""}`),
                starters: linkCell(key, starters, "startes",  "list"),
                jobs:     linkCell(key, jobs,     "jobs",     "list", `${jobs ===0 ? "warning": ""}`),
                events:   linkCell(key, events,   "events",   "list"),
                component: this,
                goLife:  toggle(key, name, isLife)
            }
        });
    };

    toggleCompany = (id: string, name: string, isLife: boolean) => {
        firebase.database().ref(`companies/${id}/information/`).update({isLife: !isLife}).then(()=>{
            this.pushFeedback({
                type: "success",
                title: `Company ${isLife? "Offline": "Online"}`,
                text: `Company ${name} is ${isLife? "Offline": "Online"}!`,
                id: moment.now(),
            })
        });
    };

    deleteRecord = (id: string, name: string) => {
        this.setState({showModalDelete: false});
        firebase.database().ref(`companies/`).update({[id]: null}).then(()=>{
            this.pushFeedback({
                type: "success",
                title: "Adviser deleted",
                text: `Adviser ${name} was deleted!`,
                id: moment.now(),
            })
        });
    };

    pushFeedback = ( feedbackData : any)=>{
        let feedBack = this.state.feedback;
        this.setState({feedback: [feedbackData , ...feedBack]})
    };

    render() {
        const {
            state: {companies, loadingData, feedback, showModalDelete, record }
        }= this;

        return (
            <Container className={"portlet-wrapper"}>
                <Feedback messages={feedback}/>
                <Row noGutters>
                    <Col className={"portlet-header"}>
                        <h4>
                            <i className="fas fa-building"/>&nbsp;
                            <span>Company List</span>
                        </h4>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="toolbar-dashboard">
                            <NavLink to={`/cms/company/add`} className={"btn btn-success"}>
                                <i className="fas fa-plus"/> Add company
                            </NavLink>
                        </div>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        {loadingData?
                            <Loading/>:
                            <ReactTable
                                data={companies}
                                columns={columns}
                            />
                        }
                    </Col>
                </Row>
                <ModalDelete
                    showModal={showModalDelete}
                    onHide={()=> this.setState({showModalDelete: false})}
                    onDelete={()=> this.deleteRecord(record.id, record.name )}
                    title={"Delete Company?"}
                    message={`You are about to delete company: ${record.name}`}/>
            </Container>
        );
    }
}

export default CompanyList;
