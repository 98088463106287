import React, {Component} from 'react';
import "./footer.scss";
import {Container, Row, Col} from "react-bootstrap";

class Footer extends Component {
    render() {
        return (
           <footer className="site-footer">
               <Container>
                   <Row>
                       <Col sm={12} md={6}>
                           <h6>WARUM STARTZEIT?</h6>
                           <p className="text-justify">Startzeit Digital ist deine Plattform um den richtigen Schritt für deine Zukunft zu machen. Von jungen Menschen gemacht um junge Menschen zu unterstützen - Deine Zukunft, ist unsere Motivation.
                           </p>
                       </Col>

                       <Col sm={12} md={{span: 3, offset: 3 }}>
                           <h6>LINKS</h6>
                           <ul className="footer-links">
                               <li><a href="/firmen">FIRMEN</a></li>
                               <li><a href="/wish-list">WUNSCHLISTE</a></li>
                               <li><a href="/wish-list">BERUFSCHECK</a></li>
                           </ul>
                       </Col>
                   </Row>
                   <hr/>
               </Container>
               <Container>
                   <Row>
                       <Col md={6} sm={12} xs={12}>
                           <p className="copyright-text">Copyright &copy; 2020 All Rights
                               Reserved by
                               <a href="#"> Startzeit</a>.
                           </p>
                       </Col>

                       <Col md={3} sm={6} xs={12}>
                           <p className="copyright-text">
                               <a href="#"> Impressum</a> / <a href="#"> Datenschutz</a>
                           </p>
                       </Col>

                       <Col md={3} sm={6} xs={12}>
                           <ul className="social-icons">
                               <li>
                                   <a className="facebook" href="#">
                                       <i className="fab fa-facebook-f"/>
                                   </a>
                               </li>
                               <li>
                                   <a className="facebook" href="#">
                                       <i className="fab fa-instagram"/>
                                   </a>
                               </li>
                               <li>
                                   <a className="facebook" href="#">
                                       <i className="fab fa-twitter"/>
                                   </a>
                               </li>
                           </ul>
                       </Col>
                   </Row>
               </Container>
           </footer>


        );
    }
}

export default Footer;
