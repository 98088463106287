import React, {Component} from 'react';
import './homeHero.scss';
import {Container} from "react-bootstrap";
import SearchBar from "../searchBar/SearchBar";
import TextTransition, { presets } from "react-text-transition";



const TextAnimated = (props: any) => {

    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(() =>
                setIndex(index => index + 1),
            10000 // every 10 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <TextTransition
            text={ props.texts[index % props.texts.length] }
            springConfig={presets.gentle}
            style={props.style}
        />
    );
};



type State = {
    backgroundIndex: number,
}

  const BACKGROUNDS = [
         require("../../../assets/images/hero/banner3.jpg"),
         require("../../../assets/images/hero/banner2.jpg"),
         require("../../../assets/images/hero/banner1.jpg")
     ];


class HomeHero extends Component<{}, State> {

    private intervalBG: any;
    state: State ={
        backgroundIndex:  0
    };

    componentDidMount(): void {
        this.intervalBG = setInterval(() =>
                this.setState({backgroundIndex: this.state.backgroundIndex === 2 ? 0 : this.state.backgroundIndex + 1}),
            10000 // every 10 seconds
        );
    }

    componentWillUnmount(): void {
        clearInterval(this.intervalBG);
    }


    onSearch = (cities: any, branches: any, jobType: any)=> {
        window.location.href = `/search/${cities}?b=${branches.join(",")}${jobType !== ""? `&j=${jobType}`: "" }`;
    };

    render() {
        const {backgroundIndex} = this.state;
        return (
            <Container fluid className="home-hero-banner-wrapper"
                style={{

                    backgroundImage: `URL(${BACKGROUNDS[backgroundIndex]})`
                }}
            >

                <div style={{width:"0", height:"0", opacity: "0", overflow:"hidden"}}>
                    <img src={BACKGROUNDS[0]} alt=""/>
                    <img src={BACKGROUNDS[1]} alt=""/>
                    <img src={BACKGROUNDS[2]} alt=""/>
                </div>
                <h1 className={"hero-header"}>
                    <div className={"lineOne"}>
                        <TextAnimated
                            texts={[
                                "Finde die passende Firma für deine",
                                "Finde die passende Firma für deinen",
                                "Finde die passende Firma für dein",
                            ]}
                            style={{
                                fontFamily: "roboto-bold",
                                textShadow: "1px 5px 5px black",
                            }}
                        />
                    </div>
                    <TextAnimated
                        style={{
                            fontFamily: "roboto-bold",
                            fontSize: "60px",
                            textShadow: "1px 5px 5px black",
                            display: "inline"
                        }}
                        texts={[
                            "Ausbildung",
                            "Praktikumsplatz",
                            "Duales Studium"
                        ]}
                    />
                </h1>
                <SearchBar onSearch={this.onSearch}/>
            </Container>
        );
    }
}

export default HomeHero;
