import React, {Component} from 'react';
import "./productDetail.scss";
import {Container, Row, Col, Card, Jumbotron, Image, Button, Accordion} from "react-bootstrap";
import NavigationBar from "../../shared/navigationBar/NavigationBar";
import Loading from "../../shared/loading/Loading";

import firebase from 'firebase/app';
import Util from "../../../util/Util";
import Markdown from "../../shared/markdown/Markdown";
import Footer from "../../shared/footer/Footer";
import StarterCarousel from "../../shared/starter-carousel/StarterCarousel";
import LightBox from "../../shared/gallery/LightBox";
import JobAccordion from "../../shared/job-accordion/JobAccordion";
import moment from "moment";

type Props = {
    id: string,
}

type State = {
    loadingData: boolean,
    company: any,
    liked: boolean
}

class ProductDetail extends Component<Props, State> {
    state: State = {
        company: null,
        loadingData: true,
        liked: false
    };

    componentDidMount(): void {
        firebase.database().ref(`companies/${this.props.id}`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState({company: snapshot.val(), loadingData: false});
            }
        });

        let id = this.props.id;
        let wishList = JSON.parse(localStorage.getItem('wish-list') || "[]");

        if(Array.isArray(wishList)){
            this.setState({liked: wishList.includes(id)});
        }
    }

    handleOnLike = (id: string)=>{
        let liked = !this.state.liked;
        let wishList = JSON.parse(localStorage.getItem('wish-list') || "[]");

        if(!Array.isArray(wishList)){
            wishList = [];
        }

        if(liked){
            wishList.push(id)
        }else{
            wishList = wishList.filter((w: string) => w !== id);
        }
        this.setState({liked: liked});
        localStorage.setItem('wish-list', JSON.stringify(wishList));
    };

    render() {
        const {
            state: {company, loadingData, liked},
            props: {id}
        } = this;

        if(!company){
            return null;
        }

        let jobs = Object.keys(company.jobs || {});
        let companyDescription = JSON.parse(Util.decodeCharacters(company.information.description));
        let isCompanyOnline = company.events && Object.keys(company.events).filter((e)=> company.events[e].status === "online").length > 0;

        if(company.information && !company.information.isLife){
            return (
                <Container fluid className={"product-detail-wrapper"}>
                    <NavigationBar/>
                    <Row>
                        <Col>
                            <h6>
                                This content is currently unavailable! :(
                            </h6>
                        </Col>
                    </Row>
                </Container>
            )
        }

        return (
            <Container fluid className={"product-detail-wrapper"}>
                <NavigationBar/>
                {loadingData && <Loading/> }

                {!loadingData && <>
                    <Row noGutters style={{paddingBottom: "50px", background: "#ededed"}}>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="profile-wrapper">
                                        <div className={`cover-image ${company.information.cover && company.information.cover !== "" ? "cover": "default"}`} style={{backgroundImage: company.information.cover ?  `url(${company.information.cover})`: `url(${require("../../../assets/bg/pattern.png")})`}}>
                                            <div className="profile-image">
                                                {isCompanyOnline &&
                                                    <div className="online blob">
                                                        Online
                                                    </div>
                                                }
                                                <Image
                                                    src={company.information.logo}
                                                    alt=""
                                                    width={"150px"}
                                                    height={"150px"}
                                                    thumbnail >
                                                </Image>
                                            </div>
                                        </div>
                                        <div className="product-basic-info">
                                            <div className="title">
                                                {company.information.name}
                                            </div>
                                            <div className="address">
                                                <i className="fas fa-map-marker-alt"/>&nbsp;
                                                {Util.decodeCharacters(company.information.address)}&nbsp;
                                                {Util.decodeCharacters(company.information.zipcode)},&nbsp;
                                                {Util.decodeCharacters(company.information.city)}&nbsp;
                                            </div>
                                            <div
                                                className={`like ${liked? "liked": ""}`}
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "10px"
                                                }}>
                                                <Button
                                                    variant="light"
                                                    className={"btn-card"}
                                                    onClick={()=> this.handleOnLike(id)}
                                                >
                                                    <i className={`${liked? "fas": "far"} fa-heart`}/>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Row>

                    <Row noGutters>
                        <Container className={"product-blog"}>
                            <Row>
                                <Col className={"product-blog-body"} xs={{ span: 12, order: 2 }} lg={{ span: company.advisers? 8: 12, order: 1 }}>
                                    {company.events && <Row>
                                        <Col>
                                            <section>
                                                <h4>
                                                    Live Event
                                                </h4>
                                                { Object.keys(company.events).map((event:string, index: number)=>
                                                    {
                                                        return company.events[event].status === "online"? <Jumbotron key={index} bsPrefix={"event-jumbotron"}>
                                                            <div className={"jumbo-image"}>
                                                                <img src={ company.events[event].gallery[0]} alt=""/>
                                                            </div>
                                                            <div className={"jumbo-body"}>
                                                                <Container>
                                                                    <Row>
                                                                        <Col>
                                                                            <h4>Online Now!</h4>
                                                                            <hr/>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <h4>{company.events[event].name}</h4>
                                                                            { company.events[event].description.map((paragraph: string, key: number)=>
                                                                                <p key={key} className={"description"}>{Util.decodeCharacters(paragraph)}</p>)
                                                                            }
                                                                            <hr/>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col sm={8}>Diese Veranstaltung endet um:</Col>
                                                                        <Col>
                                                                            {company.events[event].ends} Uhr
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <hr/>
                                                                            <a
                                                                                className={"btn btn-success"}
                                                                                href={company.events[event].roomLink}
                                                                                target={"_blank"}
                                                                                rel="noopener noreferrer">
                                                                                <i className="fas fa-video"/>&nbsp;&nbsp;Join Event
                                                                            </a>
                                                                            {company.events[event].deeplink && company.events[event].deeplink !== "" &&
                                                                                <a
                                                                                    className={"btn btn-primary"}
                                                                                    href={company.events[event].deeplink}
                                                                                    target={"_blank"}
                                                                                    rel="noopener noreferrer">
                                                                                    Mehr erfahren
                                                                                </a>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </div>

                                                        </Jumbotron>: null
                                                    }
                                                )
                                                }
                                            </section>
                                        </Col>
                                    </Row>}

                                    {company.events && Object.keys(company.events).length &&
                                        <Row>
                                            <Col>
                                                <section>
                                                    <h4>
                                                        Kommende Veranstaltungen
                                                    </h4>
                                                    <div className={"event-main-wrapper"} style={{
                                                        marginLeft: 0,
                                                        border: 0,
                                                        padding: 0,
                                                    }}>

                                                        <div className={"event-list-wrapper"}>
                                                            {company.events && Object.keys(company.events).map((event: any, index: number)=> {

                                                                if(company.events[event].status === "online"){
                                                                    return null;
                                                                }

                                                                return(
                                                                    <div key={index} className={"event-tile-wrapper"}>
                                                                        <img src={company.events[event].gallery[0]} alt=""/>
                                                                        <div className={"event-body"}>
                                                                            <div className="title">
                                                                                {company.events[event].name}
                                                                            </div>
                                                                            <div className="time">
                                                                                <i className="far fa-calendar-alt"/> {moment(company.events[event].date).format("DD.MM.YYYY")}
                                                                                <br/>
                                                                                <i className="far fa-clock"/> {(company.events[event].starts)} - {(company.events[event].ends)}
                                                                            </div>

                                                                            <div className={"ctas"}>
                                                                                <a
                                                                                    className={""}
                                                                                    href={company.events[event].deeplink}
                                                                                    target={"_blank"}
                                                                                    rel="noopener noreferrer">
                                                                                    Mehr erfahren <i className="far fa-arrow-alt-circle-right"/>
                                                                                </a>
                                                                                {company.events[event].status === "online" && <div className="online blob">
                                                                                        Online
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </section>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <section>
                                                <h4>Fotoalbum</h4>
                                                { company.gallery &&
                                                  <LightBox gallery={company.gallery} />
                                               }
                                            </section>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <section>
                                                <h4>Firmeneinleitung</h4>
                                                <div className={"description"}>

                                                        <Markdown key={Math.random()} value={companyDescription}/>

                                                   {/* { company.information.description.map((paragraph: string, key: number)=>
                                                        <p key={key} className={"description"}>{Util.decodeCharacters(paragraph)}</p>)
                                                    }*/}
                                                </div>
                                            </section>
                                        </Col>
                                    </Row>
                                    {company.jobs &&
                                            <Row>
                                            <Col>
                                                <section>
                                                    <h4>Karrieremöglichkeiten</h4>
                                                    <Accordion defaultActiveKey="0">
                                                        {company.jobs && jobs.map((j: any, index: number) =>
                                                            <JobAccordion key={j} job={company.jobs[j]} index={index.toString()}/>)}
                                                    </Accordion>
                                                </section>
                                            </Col>
                                        </Row>
                                    }
                                    { company.reviewers &&
                                        <Row>
                                            <Col>
                                                <section>
                                                    <h4>Berichte von Startern</h4>
                                                    <StarterCarousel starters={company.reviewers}/>
                                                </section>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {company.advisers && <Col className={"product-blog-sidebar"} xs={{ span: 12, order: 1 }} lg={{ span: 4, order: 2 }}>
                                    <Row style={{position: "sticky", top: "10px"}}>
                                        <Col style={{display: "flex", flexDirection: "column"}}>

                                            {  Object.keys(company.advisers).map((adviser: any, index: number)=>
                                                        <Card key={index} bsPrefix={"adviser-card"}>
                                                        <Card.Body>
                                                            <Card.Title>Dein persönlicher Berater</Card.Title>
                                                            {company.advisers[adviser].photo ?
                                                                <div className="avatar-wrapper">
                                                                    <img src={company.advisers[adviser].photo} alt=""/>
                                                                </div>:
                                                                <div className="avatar-wrapper">
                                                                    <span><i className={"fas fa-user"}/></span>
                                                                </div>
                                                            }
                                                            <Card.Text style={{margin: "0"}}>
                                                                {Util.decodeCharacters(company.advisers[adviser].name)}
                                                            </Card.Text>
                                                            <Card.Text>
                                                                {
                                                                    company.advisers[adviser].phone.map((phone: any, i: number)=>
                                                                        <span key={i} >{phone}</span>
                                                                    )
                                                                }
                                                            </Card.Text>
                                                            <Card.Text>
                                                                {Util.decodeCharacters(company.advisers[adviser].email)}
                                                            </Card.Text>

                                                            <div className={"contact"}>
                                                                {Util.decodeCharacters(company.advisers[adviser].ctaLabel)}

                                                                {company.advisers[adviser].cta === "booking"&&
                                                                    <a target={"_black"} href={Util.decodeCharacters(company.advisers[adviser].ctaExternalURL)} className={"btn-detail float-right btn btn-primary"}>
                                                                        Termin buchen
                                                                    </a>
                                                                }
                                                                {company.advisers[adviser].cta === "call"&&
                                                                    <a target={"_black"} href={`callto:${company.advisers[adviser].phone.join("")}`} className={"btn-detail float-right btn btn-primary"}>
                                                                        Anrufen
                                                                    </a>
                                                                }
                                                                {company.advisers[adviser].cta === "email"&&
                                                                    <a target={"_black"} href={`mailto:${company.advisers[adviser].email}`} className={"btn-detail float-right btn btn-primary"}>
                                                                        Mail schicken
                                                                    </a>
                                                                }
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Col>}

                            </Row>
                        </Container>
                    </Row>
                </>}
                <Container fluid>
                    <Row>
                        <Col style={{padding: 0}}>
                            <Footer/>
                        </Col>
                    </Row>
                </Container>


            </Container>
        );
    }
}

export default ProductDetail;
