import React, {Component} from 'react';
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/analytics';
import 'firebase/storage';
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";

import {Config} from "../config/Config";
import Routing from "../Routing";
firebase.initializeApp(Config.getFirebaseKeys());
firebase.storage();


if(process.env.REACT_APP_STAGE !== "dev"){
    firebase.analytics();
}

class App extends Component<{}, {}> {
    render(){
        return (
            <Router>
                <Routing />
            </Router>
        );
    }
}

export default App;
