import React, {Component} from 'react';
import {Badge, Button, Card, ListGroup} from "react-bootstrap";
import "./productCard.scss";
import Util from "../../../util/Util";
import ReactTooltip from 'react-tooltip';
import moment from "moment";

type State = {
    liked: boolean;
}

type Props = {
    company: any,
    id: string,
    branches: any,
    updateWLCounter: Function,
    onProductHover: Function,
};

class ProductCard extends Component<Props, State> {

    state: State = {
        liked: false,
    };

    componentDidMount(): void {
        let id = this.props.id;
        let wishList = JSON.parse(localStorage.getItem('wish-list') || "[]");

        if(Array.isArray(wishList)){
            this.setState({liked: wishList.includes(id)});
        }
    }

    handleOnLike = (id: string)=>{
        let liked = !this.state.liked;
        let wishList = JSON.parse(localStorage.getItem('wish-list') || "[]");

        if(!Array.isArray(wishList)){
            wishList = [];
        }

        if(liked){
            wishList.push(id)
        }else{
            wishList = wishList.filter((w: string) => w !== id);
        }
        this.setState({liked: liked});
        localStorage.setItem('wish-list', JSON.stringify(wishList));
        this.props.updateWLCounter(wishList.length);
    };

    addToLastClickedCompanies = (id: string) => {
        let lastClickedCompanies = JSON.parse(localStorage.getItem('last-clicked-companies') || "[]");
        if(!Array.isArray(lastClickedCompanies)){
            lastClickedCompanies = [];
        }
        if(!lastClickedCompanies.includes(id)){
            lastClickedCompanies.push(id)
        }
        localStorage.setItem('last-clicked-companies', JSON.stringify(lastClickedCompanies));
    };

    getBasicInfoScore = (companyInformation: any) => {
        if(companyInformation){
            if(companyInformation.cover && companyInformation.cover !== "" &&  companyInformation.logo && companyInformation.logo !== ""){
                return 2
            }else if((companyInformation.cover === "" && companyInformation.logo === "") || (!companyInformation.cover && !companyInformation.logo)){
                return 0
            }else return 1;
        }
        return 0
    };

    getGalleryScore = (gallery: any, max: number) => {
        if(gallery){
            return Object.keys(gallery).length > max ? max: Object.keys(gallery).length;
        }
        return 0
    };

    getReviewerScore = (reviewers: any, max: number) => {
        if(reviewers){
            return Object.keys(reviewers).length > max ? max: Object.keys(reviewers).length;
        }
        return 0
    };

    getAdvisersScore = (advisers: any, max: number) => {
        if(advisers){
            return Object.keys(advisers).length > max ? max: Object.keys(advisers).length;
        }
        return 0
    };

    getJobScore = (jobs: any, max: number) => {
        if(jobs){
            return Object.keys(jobs).length > max ? max: Object.keys(jobs).length;
        }
        return 0
    };

    getEventScore = (events: any, max: number) => {
        if(events){
            return Object.keys(events).length > max ? max: Object.keys(events).length;
        }
        return 0
    };

    render() {
        const {company, id, onProductHover} = this.props;
        const {liked} = this.state;
        const imageKeys = Object.keys(company.gallery || {});
        const image = company.information.logo? company.information.logo: imageKeys.length > 0? company.gallery[imageKeys[0]].url: undefined;
        const appBranches = this.props.branches;
        const branches = Object.keys(appBranches  || {})
            .map((b)=> company.information.branches.includes(appBranches[b].label)? appBranches[b]:null)
            .filter((b) => b !== null);

        const ausbildungen = Object.keys(company.jobs || {}).filter((key)=> company.jobs[key].type === "Ausbildung" ).length;
        const praktikum = Object.keys(company.jobs || {}).filter((key)=> company.jobs[key].type === "Praktikum" ).length;
        const duales = Object.keys(company.jobs || {}).filter((key)=> company.jobs[key].type === "Duales Studium" ).length;

        const events = company.events;

        const weights = {
            basicInfo: 0.3,
            gallery:0.2,
            advisers:0.2,
            starters:0.05,
            jobs:0.05,
            events:0.2
        };

        const assumptions = {
            basicInfo: 2,
            gallery: 5,
            advisers: 1,
            starters: 3,
            jobs:3,
            events:1
        };

        const points = {
            basicInfo: this.getBasicInfoScore(company.information),
            gallery: this.getGalleryScore(company.gallery, assumptions.gallery),
            advisers: this.getAdvisersScore(company.advisers, assumptions.advisers),
            starters: this.getReviewerScore(company.reviewers, assumptions.starters),
            jobs: this.getJobScore(company.jobs, assumptions.jobs),
            events:this.getEventScore(company.events, assumptions.events)
        };

        const score = {
            basicInfo: points.basicInfo * 100 / assumptions.basicInfo,
            gallery: points.gallery * 100 / assumptions.gallery,
            advisers: points.advisers * 100 / assumptions.advisers,
            starters: points.starters * 100 / assumptions.starters,
            jobs: points.jobs * 100 / assumptions.jobs,
            events: points.events * 100 / assumptions.events,
        };

        const totalScore: any =
            ((score.basicInfo * weights.basicInfo / 100)+
                (score.gallery * weights.gallery / 100)+
                (score.advisers * weights.advisers / 100)+
                (score.starters * weights.starters / 100)+ (score.jobs * weights.jobs / 100)+
                (score.events * weights.events / 100))*100;

        return (
            <div style={{
                "display": "block",
                marginBottom: company.events && Object.keys(events).length ? "0": "10px",
                borderBottom: company.events && Object.keys(events).length ? "0" : "1px solid #dfdfdf"
            }}>
                <Card className={"product-search-card-wrapper"}
                      onMouseEnter={() => onProductHover(id)}
                      onMouseLeave={() => onProductHover(null)}
                    >
                    { image && <Card.Img
                        bsPrefix={"card-img-thumbnail"}
                        variant="top"
                        src={image}
                        style={{
                            borderBottom: company.events && Object.keys(events).length ? "1px solid #dfdfdf": "0"
                        }}
                    /> }
                    <Card.Body>
                        <div className={"info-wrapper"}>
                            <div className="company-info">
                                <Card.Title className={"card-product-name"}>
                                    <span>
                                        {Util.decodeCharacters(company.information.name)}
                                    </span>
                                </Card.Title>
                                <Card.Text className={"card-product-description"}>
                                    <i className="fas fa-map-marker-alt"/> {Util.decodeCharacters(company.information.address)} {Util.decodeCharacters(company.information.zipcode)}, {Util.decodeCharacters(company.information.city)}
                                </Card.Text>
                            </div>
                            {/*{ company.isOnline &&
                                <div className="online blob">
                                    Online
                                </div>
                            }*/}
                            <div className={'gauge-widget'}
                                 data-for={`score-${id}`}
                                 data-tip="Dieser Wert zeigt das Firmen <br> Engagement und die Profilstärke <br> der jeweiligen Firma an">
                                <ReactTooltip id={`score-${id}`} multiline/>
                                <svg viewBox="0 0 36 36" className="circular-chart">
                                    <path
                                        className="circle-border"
                                        strokeDasharray="100, 100"
                                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                    <path
                                        className="circle-progress"
                                        strokeDasharray={`${totalScore}, 100`}
                                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                </svg>
                                <div className="percentage">
                                    <span>{totalScore.toFixed(0)}%</span>
                                </div>
                            </div>

                           <div className={`like ${liked? "liked": ""}`}>
                               <Button
                                   variant="primary"
                                   className={"btn-card"}
                                   onClick={()=> this.handleOnLike(id)}
                               >
                                   <i className={`${liked? "fas": "far"} fa-heart`}/>
                               </Button>
                           </div>
                        </div>
                         <div style={{
                            display: "flex",
                            position: "relative",
                            marginBottom: "5px"
                        }}>
                            <div className="branch-wrapper">
                                {branches.map( (b: any, key: number )=>
                                    <Badge key={key} variant="light" style={{backgroundColor: b.color, color: "white"}}>
                                        <i className={b.icon}/>&nbsp;
                                        <span>{b.label}</span>
                                    </Badge>)
                                }
                            </div>
                        </div>

                        <div className="job-offers-wrapper">
                            <ListGroup variant="flush">
                                {ausbildungen > 0  &&
                                    <ListGroup.Item>
                                        {ausbildungen} Ausbildungen
                                    </ListGroup.Item>
                                }
                                {praktikum > 0  &&
                                    <ListGroup.Item>
                                        {praktikum} Praktikum
                                    </ListGroup.Item>
                                }
                                {duales > 0  &&
                                    <ListGroup.Item>
                                        {duales} Duales Studium
                                    </ListGroup.Item>
                                }
                            </ListGroup>
                        </div>
                         <div className={"card-actions-wrapper"}>
                            <Button
                                variant="primary"
                                className={"btn-detail float-right"}
                                onClick={()=> this.addToLastClickedCompanies(id)}
                                href={`/company/${id}/${Util.decodeCharacters(company.information.name).replace(/ /g, "-")}`}>
                                Mehr erfahren <i className="far fa-arrow-alt-circle-right"/>
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
                {events && Object.keys(events).length &&
                    <div>
                        <div className={"event-main-wrapper"}>
                            <div>
                                Events
                            </div>
                            <div className={"event-list-wrapper"}>
                                {events && Object.keys(events).map((event: any, index: number)=> (
                                    <div key={index} className={"event-tile-wrapper"}>
                                        <img src={events[event].gallery[0]} alt=""/>
                                        <div className={"event-body"}>
                                            <div className="title">
                                                {events[event].name}
                                            </div>
                                            <div className="time">
                                                <i className="far fa-calendar-alt"/> {moment(events[event].date).format("DD.MM.YYYY")}
                                                <br/>
                                                <i className="far fa-clock"/> {(events[event].starts)} - {(events[event].ends)}
                                            </div>
                                            <div className={"ctas"}>
                                                <a
                                                    className={""}
                                                    href={company.events[event].deeplink}
                                                    target={"_blank"}
                                                    rel="noopener noreferrer">
                                                    Mehr erfahren <i className="far fa-arrow-alt-circle-right"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default ProductCard;
