import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';

import {Col, Container, Row} from "react-bootstrap";
import NavigationBar from "../../shared/navigationBar/NavigationBar";
import Map from "../../shared/map/Map";
import ProductList from "../../shared/productList/ProductList";
import './searchView.scss';

type Props = {
    city: string | null,
    branches: any | null,
    jobType: any | null
};

type State = {
    loadingData: boolean,
    companies: any,
    filteredCompanies: Array<any>,
    WLCounter: number;
    jobType: string;
    city: string | null;
    branches: any | null;
    productHovered: any | null;
};

class SearchView extends Component<Props, State> {

    state: State = {
        loadingData: true,
        companies: {},
        filteredCompanies: [],
        WLCounter: 0,
        city: null,
        branches: null,
        jobType: this.props.jobType ? this.props.jobType : "",
        productHovered: null
    };

    componentDidMount(): void {
        firebase.database().ref(`companies/`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState({companies: snapshot.val(), loadingData: false});

                if(this.state.city && this.state.branches){
                    this.onSearch(this.state.city, this.state.branches, this.state.jobType);
                }
            }
        });
    }

    onSearch = (city: any, branches: any, jobType: string) => {

        let companies  = this.state.companies;
        let filteredCompanies = Object.keys(companies).filter((c: any)=> {
               return (((city === "Alle Regionen" && companies[c].information.branches.includes(...branches)) || //filter by tag
                       (city === "Alle Regionen" && Array.isArray(branches) && branches.length === 0) ||
                       (companies[c].information.city === city && companies[c].information.branches.includes(...branches)) || //both city and tag are considered
                       (companies[c].information.city === city &&  Array.isArray(branches) && branches.length === 0))
                   && companies[c].information.isLife);
            }).map((f: any) =>  {
                const isCompanyOnline = companies[f].events && Object.keys(companies[f].events).filter((e)=> companies[f].events[e].status === "online").length > 0;
                return {id: f,
                    isOnline: isCompanyOnline,
                    ...companies[f]}
            } );

        const visibleCompaniesBasedOnJobFilter = filteredCompanies.map((company: any)=>
            {
                const ausbildung = Object.keys(company.jobs || {}).filter((key)=> company.jobs[key].type === "Ausbildung" ).length;
                const praktikum = Object.keys(company.jobs || {}).filter((key)=> company.jobs[key].type === "Praktikum" ).length;
                const studium = Object.keys(company.jobs || {}).filter((key)=> company.jobs[key].type === "Duales Studium" ).length;
                switch (jobType) {
                    case "ausbildung":  return ausbildung > 0 ? company : null;
                    case "praktikum": return praktikum > 0 ? company : null;
                    case "studium": return studium > 0 ? company : null;
                    case "": return company;
                }
                return company;
            }
        ).filter((company: any) => company !== null);
        this.setState({
            // @ts-ignore
            filteredCompanies: visibleCompaniesBasedOnJobFilter,
            jobType:jobType,
            city: city,
            branches: branches,
        });
    };

    updateWLCounter = (WLCounter: number) => {
        this.setState({WLCounter: WLCounter})
    };

    productOnHover = (product: any) => {
        this.setState({productHovered: product});
    };

    render() {
        const {loadingData, filteredCompanies, WLCounter, productHovered} = this.state;
        const {city, branches, jobType} = this.props;

        return (
            <Container fluid className={"search-view-wrapper"}>
                <NavigationBar counter={WLCounter}/>
                <Row noGutters className={"list-view-port"}>
                    {loadingData && <div>Loading</div>}
                    { !loadingData &&
                        <>
                            <Col>
                                <ProductList
                                    companies={filteredCompanies}
                                    onSearch={this.onSearch}
                                    city={city}
                                    branches={branches}
                                    jobType={jobType? jobType: ""}
                                    updateWLCounter={this.updateWLCounter}
                                    onProductHover={this.productOnHover}
                                />
                            </Col>
                            <Col md={6}>
                                { filteredCompanies.length > 0 &&
                                    <Map companies={filteredCompanies} productHovered={productHovered}/>
                                }
                            </Col>
                        </>
                    }
                </Row>
            </Container>
        );
    }
}

export default SearchView;
