import React, {Component} from 'react';
import {Card, CardGroup, Row, Col} from "react-bootstrap";
import "./qAdeckCard.scss";

class QAdeckCard extends Component {
    render() {
        return (
           <Row className={"qa-wrapper"}>
               <Col>

                   <Row>
                       <Col>
                           <h3>
                               Dein Ort um lokale Firmen mit nur einem Klick kennenzulernen.
                           </h3>
                       </Col>
                   </Row>
                   <Row>
                       <Col>
                           <h5>
                               Die Startzeit Digital, erschafft dir einen Einblick in alle Firmen in deiner Nähe und was Sie dir bieten können um erfolgreich durchzustarten.

                           </h5>
                       </Col>
                   </Row>
                   <Row>
                       <Col>
                           <CardGroup>
                               <Card>
                                   <Card.Body>
                                       <Card.Title>
                                           <i className="fas fa-file-alt"/> &nbsp;
                                           <div>
                                               Online Kennenlernen & Bewerben
                                           </div>
                                       </Card.Title>
                                       <Card.Text>
                                           Du kannst jede der Firmen online kennenlernen, nur einen Mausklick entfernt. Vergleiche unterschiedliche Firmen und setze deine Favoriten auf deine Wunschliste um diese deinen Freunden und deiner Familie später zu zeigen.
                                       </Card.Text>
                                   </Card.Body>

                               </Card>
                               <Card>
                                   <Card.Body>
                                       <Card.Title>
                                           <i className="fas fa-award"/> &nbsp;
                                           <div>Verifizierte Firmen</div>
                                       </Card.Title>
                                       <Card.Text>
                                           Bei uns findest du nur verifizierte Firmen, mit hilfe des Scores bekommst du eine Einsicht welche Firmen engagierter sind und welche weniger.

                                       </Card.Text>
                                   </Card.Body>

                               </Card>
                               <Card>
                                   <Card.Body>
                                       <Card.Title>
                                           <i className="fas fa-user-tie"/> &nbsp;
                                           <div>Persönliche Unterstützung</div>
                                       </Card.Title>
                                       <Card.Text>
                                           Wir sind immer für Dich da und bieten Dir individuelle Unterstützung auf dem Weg zum Starter.
                                       </Card.Text>
                                   </Card.Body>

                               </Card>
                           </CardGroup>
                       </Col>
                   </Row>

               </Col>
           </Row>

        );
    }
}

export default QAdeckCard;
