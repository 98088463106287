import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import NavigationBar from "../../shared/navigationBar/NavigationBar";
import firebase from 'firebase/app';
import 'firebase/database';
import ProductCard from "../../shared/productList/ProductCard";
import "./firmen.scss";

type Props = {

};

type State = {
    WLCounter: number;
    loadingData: boolean;
    companies: any;
};

class Firmen extends Component<Props, State> {

    state: State = {
        loadingData: true,
        WLCounter: 0,
        companies: {}
    };

    componentDidMount(): void {
        firebase.database().ref(`companies/`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState({
                    companies: Object.keys(snapshot.val()).map(c => {
                        return {
                            id: c,
                            ...snapshot.val()[c]
                        }
                    }),
                    loadingData: false});
            }
        });
    }

    updateWLCounter = (WLCounter: number) => {
        this.setState({WLCounter: WLCounter})
    };

    render() {
        const {loadingData, WLCounter, companies} = this.state;

        return (
            <Container fluid className={"wish-list-wrapper"}>
                <NavigationBar counter={WLCounter}/>
                <Container>
                    <Row noGutters style={{marginTop: "50px"}}>
                        <Col>
                            <h4>FIRMEN</h4>
                        </Col>
                    </Row>
                    <Row noGutters>
                        <Col>
                            {!loadingData && companies.map((company: any)=>
                                <ProductCard
                                    key={company.id}
                                    company={company}
                                    id={company.id}
                                    updateWLCounter={this.updateWLCounter}
                                    branches={[]}
                                    onProductHover={()=>{}}/>
                            )}
                            {loadingData &&
                            <span>loading..</span>
                            }
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

export default Firmen;
