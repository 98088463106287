import React, {Component} from 'react';
import {Button, Image, Modal} from "react-bootstrap";

type Props = {
    showModal: boolean,
    onHide: Function
    onDelete: Function,
    image?: any,
    message: string,
    title: string
}

class ModalDelete extends Component<Props> {
    render() {
        const {showModal, onHide, onDelete, message, image, title} = this.props;
        return (
            <Modal show={showModal} onHide={()=> onHide()}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message && message !== "" &&
                        <p>
                            {message}
                        </p>
                    }
                    {image &&
                        <div style={{maxWidth: "100px"}} >
                            <Image src={image} thumbnail />
                        </div>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={()=> onDelete()} >Delete</Button>
                    <Button variant="secondary" onClick={()=> onHide()}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalDelete;
