import React, {Component} from 'react';
import {Col, Row, Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import "./navProfile.scss";

type Props = {
    companyId: string,
    section: string,
    dashboard: string
};

class NavProfile extends Component<Props, {}> {
    render() {
        const {
            props: { section, dashboard, companyId}
        } = this;
        return (
            <>
                <Row noGutters className={"nav-section-wrapper"}>
                    <Col>
                        <Nav variant="tabs" defaultActiveKey={`${section}/${dashboard}`}>
                            <Nav.Item>
                                <NavLink to={`/cms/${companyId}/company/profile`} className={"nav-link"}>
                                    <i className="fas fa-building"/>&nbsp;
                                    Company
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink to={`/cms/${companyId}/company/gallery`} className={"nav-link"}>
                                    <i className="fas fa-images"/>&nbsp;
                                    Gallery
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink to={`/cms/${companyId}/advisers/list`} className={"nav-link"}>
                                    <i className="fas fa-id-card-alt"/>&nbsp;
                                    Advisers
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink to={`/cms/${companyId}/starters/list`} className={"nav-link"}>
                                    <i className="fas fa-user-tie"/>&nbsp;
                                    Starters
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink to={`/cms/${companyId}/jobs/list`} className={"nav-link"}>
                                    <i className="fas fa-briefcase"/>&nbsp;
                                    Job Positions
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink to={`/cms/${companyId}/events/list`} className={"nav-link"}>
                                    <i className="fas fa-calendar-alt"/>&nbsp;
                                    Events
                                </NavLink>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
            </>

        );
    }
}

export default NavProfile;
