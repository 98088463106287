import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./login.scss";
import Authentication from "../../../Authentication";

import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

type Props = {
  from: string;
  search: string;
};

type State = {
  userName: string;
  password: string;
  isInvalid: boolean;
};

export default class Login extends Component<Props, State> {
  state: State = {
    userName: "",
    password: "",
    isInvalid: false
  };

  feedback = () => {
    this.setState({ isInvalid: true });
  };

  handleUpdateUserName = (event: any) => {
    const { value } = event.target;
    if (value !== this.state.userName) {
      this.setState({ userName: value, isInvalid: false });
    }
  };

  handleUpdatePassword = (event: any) => {
    const { value } = event.target;
    if (value !== this.state.password) {
      this.setState({ password: value, isInvalid: false });
    }
  };

  handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      Authentication.login(
        this.state.userName,
        this.state.password,
        this.props.from + this.props.search,
        this.feedback
      );
    }
  };

  render() {
    const {
      state: { userName, password, isInvalid },
      props: { from, search }
    } = this;
    return (
      <Container
        fluid
        className="main-login-wrapper"
        style={{
          background: "#f3f3f3"
        }}
      >
        <Row className="h-100">

          <Col
              style={{
                background: `url("${require("../../../assets/images/hero/banner3.jpg")}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: "#1c75b7",
                backgroundBlendMode: "multiply"
              }}
          >
            <Container className="h-100">
              <Row className="h-100">
                <Col className="flex">
                  <div className="login-portlet">
                    <Row>
                      <Col>
                        <div className="logo">
                            <img
                                width={"50px"}
                                src={require("../../../assets/ui/logo.svg")} alt=""/>

                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="form">
                        <div>
                          <InputGroup
                            className={`userName ${isInvalid ? "error" : ""}`}
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <i className="fas fa-user" />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="User name"
                              aria-label="Large"
                              aria-describedby="inputGroup-sizing-sm"
                              value={userName}
                              name="username"
                              type="text"
                              onChange={this.handleUpdateUserName}
                              isInvalid={isInvalid}
                            />
                          </InputGroup>
                          <InputGroup
                            className={`password ${isInvalid ? "error" : ""}`}
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <i className="fas fa-key" />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="Password"
                              aria-label="Large"
                              aria-describedby="inputGroup-sizing-sm"
                              value={password}
                              name="pass"
                              type="password"
                              onChange={this.handleUpdatePassword}
                              isInvalid={isInvalid}
                              onKeyPress={this.handleKeyPress}
                            />
                          </InputGroup>

                          {isInvalid && (
                            <span className={"error-message"}>
                              E-mail or password are incorrect
                            </span>
                          )}

                          <button
                            onClick={() =>
                              Authentication.login(
                                userName,
                                password,
                                from + search,
                                this.feedback
                              )
                            }
                            className="btn btn-primary float-right"
                          >
                            <i className="fas fa-sign-in-alt" /> Login
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}
