import React, { useCallback, useMemo, useState } from 'react';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor, Transforms, createEditor, Node } from 'slate';
import { withHistory } from 'slate-history';
import {Button} from "react-bootstrap";
import "./textEditor.scss";
const LIST_TYPES = ['numbered-list', 'bulleted-list'];

type Props = {
    onChange: Function,
    value: any
}

const TextEditor = (props:Props) => {
    const [value, setValue] = useState<Node[]>(props.value);
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);

    return (
        <Slate
            editor={editor}
            value={value}
            onChange={value => {
                setValue(value);
                props.onChange(value);
            }}
        >
            <div className={"editor-wrapper"}>
                <div className={"editor-toolbar-wrapper"}>
                    <MarkButton format="bold" icon="fas fa-bold" />
                    <MarkButton format="italic" icon="fas fa-italic" />
                    <MarkButton format="underline" icon="fas fa-underline" />
                    <BlockButton format="heading-five" icon="fas fa-heading" />
                    <BlockButton format="numbered-list" icon="fas fa-list-ol"/>
                    <BlockButton format="bulleted-list" icon="fas fa-list-ul" />
                </div>
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder=""
                    autoFocus
                />
            </div>
        </Slate>
    )
};

const toggleBlock = (editor: any, format: any) => {

    console.log(isBlockActive(editor, format));
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type as string),
        split: true,
    });

    Transforms.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor: any, format: any) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
};

const isBlockActive = (editor: any, format: any) => {
    // @ts-ignore
    const [match] = Editor.nodes(editor, {
        match: n => n.type === format,
    });

    return !!match;
};

const isMarkActive = (editor: any, format: any) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false
};

const Element = ({ attributes, children, element }: any) => {
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'heading-five':
            return <h5 {...attributes}>{children}</h5>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

const Leaf = ({ attributes, children, leaf }: any) => {
    if (leaf.bold) {
        children = <b style={{fontFamily: 'Roboto'}}>{children}</b>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }: any) => {
    const editor = useSlate();
    return (
        <Button
            variant={isBlockActive(editor, format) ? "secondary": "light"}
            onClick={() => {
                toggleBlock(editor, format)
            }}
        >
            <i className={icon} />
        </Button>
    )
};

const MarkButton = ({ format, icon }: any) => {
    const editor = useSlate();
    return (
        <Button
            variant={ "light"}
            onClick={() => {
                toggleMark(editor, format);
            }}
        >
            <i className={icon} />
        </Button>
    )
};

export default TextEditor;
