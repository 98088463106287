import React, {Component} from 'react';
import {Card, Col, Container, Row, Button, Form, ListGroup, Image, Spinner} from "react-bootstrap";
import "./portlet.scss";
import firebase from 'firebase/app';
import Select from 'react-select';
import Util from "../../../util/Util";
import {NavLink} from "react-router-dom";

import {Linode} from "../../etc/Config";
import AWS from 'aws-sdk';
AWS.config = new AWS.Config();
AWS.config.accessKeyId = Linode.accessKeyId;
AWS.config.secretAccessKey = Linode.secretAccessKey;
AWS.config.region = Linode.region;
const s3 = new AWS.S3({endpoint: Linode.endpoint});
const hostingURL = Linode.hostingURL;

type Props = {
    companyId: string,
    mode: "EDIT" | "CREATE",
    section: string,
    dashboard: string,
    record:string
};

type State = {
    validated: boolean,
    name: string,
    phone: string,
    email: string,
    photo: string | null,
    dayAvailability: Array<any>,
    from: number,
    to: number,
    loadingData: boolean,
    file: any | null,
    inputFileValue: any,
    isDataUploading: boolean,
    cta: string,
    ctaExternalURL: string,
    ctaLabel: string
};

class AdviserForm extends Component<Props, State> {

    state:State = {
        validated: false,
        name: "",
        phone: "",
        email: "",
        photo: null,
        dayAvailability: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"],
        from: 8,
        to: 16,
        loadingData: false,
        file: null,
        inputFileValue: "",
        isDataUploading: false,
        cta: "email",
        ctaExternalURL: "",
        ctaLabel: ""
    };

    componentDidMount(): void {
        if(this.props.mode ==="EDIT"){
            this.loadRecord();
        }
    }

    loadRecord = () => {
        const {companyId, record} = this.props;
        this.setState({loadingData: true});

        firebase.database().ref(`companies/${companyId}/advisers/${record}`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState(
                    {
                        name: Util.decodeCharacters(snapshot.val().name),
                        phone: snapshot.val().phone.join(","),
                        email: Util.decodeCharacters(snapshot.val().email),
                        photo: snapshot.val().photo,
                        dayAvailability: snapshot.val().dayAvailability || [],
                        from: snapshot.val().from,
                        to: snapshot.val().to,
                        cta: snapshot.val().cta || "email",
                        ctaExternalURL: Util.decodeCharacters(snapshot.val().ctaExternalURL) || "",
                        ctaLabel: Util.decodeCharacters(snapshot.val().ctaLabel) || ""
                    }
                )
            }
            this.setState({loadingData: false});
        });
    };


    handleSubmit= (event: any)=>{
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === true ) {
            this.addReviewer();
        }
        this.setState({validated: true});
    };

    addReviewer = () => {
        const {
            name,
            phone,
            email,
            photo,
            dayAvailability,
            from,
            to,
            file,
            cta,
            ctaExternalURL,
            ctaLabel
        } = this.state;

        const {
            mode,
            record,
            companyId
        } = this.props;

        const data: any = {
            name: Util.encodeCharacters(name),
            phone: phone.split(","),
            email: Util.encodeCharacters(email),
            dayAvailability: dayAvailability,
            from: from,
            to: to,
            cta: cta,
            ctaExternalURL: Util.encodeCharacters(ctaExternalURL),
            ctaLabel: Util.encodeCharacters(ctaLabel)
        };

        if(photo && photo !== ""){
            data.photo = photo;
        }
        this.setState({isDataUploading: true});
        if(mode === "CREATE"){
            firebase.database().ref(`companies/${companyId}/advisers/`).push(data).then((snap)=>{
                this.storeImages(file, companyId, snap.key || "def");
            });
        }

        if(mode === "EDIT"){
            firebase.database().ref(`companies/${companyId}/advisers/${record}`).update(data
            ).then(()=>{
                this.storeImages(file, companyId, record);
            });
        }
    };

    storeImages = (file: any, companyId: string, record: string) => {
        if(file){
            let params = {
                Body: file,
                Bucket: Linode.Bucket,
                Key: `companies/${companyId}/advisers/${record}`,
                ACL:'public-read'
            };

            s3.putObject(params, (err) => {
                if(!err){
                    let url = `${hostingURL}/companies/${companyId}/advisers/${record}`;
                    firebase.database().ref(`companies/${this.props.companyId}/advisers/${record}`).update({photo: url}).then(()=>{
                        window.location.href = Util.getPath(`cms/${companyId}/advisers/list`);
                        this.setState({isDataUploading: false});
                    });
                }else{
                    this.setState({isDataUploading: false});
                    alert("error");
                }
            });
        }else{
            window.location.href = Util.getPath(`cms/${companyId}/advisers/list`);
        }
    };

    handleOnTextChange = (e: any, key: any) => {
         let value = e.target.value;
        // @ts-ignore
        this.setState({[key]: value});
    };

    addDayAvailability = (day: string)=>{
        const dayAvailability = this.state.dayAvailability;

        if(dayAvailability.includes(day)){
            this.setState({dayAvailability: dayAvailability.filter((d)=> d !== day)});
        }else{
            dayAvailability.push(day);
            this.setState({dayAvailability: dayAvailability});
        }
    };

    handleSlotChange = (selectedOptions: any, key:string) => {
        // @ts-ignore
        this.setState({ [key] : parseInt(selectedOptions.value)  });
    };

    handleLoadFile = (e: any) => {
        if(e && e.target && e.target.files.length > 0){
            let file = e.target.files[0];
            Util.imageToBase64(file).then((photo: any)=>{
                this.setState({file: file, photo: photo, inputFileValue: ""});
            });
        }
    };

    setCTA = (cta: string)=>{
        this.setState({cta: cta});
    };

    render() {
        const {
            state: {
                validated,
                name,
                phone,
                email,
                photo,
                dayAvailability,
                from,
                to,
                file,
                inputFileValue,
                isDataUploading,
                cta,
                ctaExternalURL,
                ctaLabel
            },
            props:{companyId, section, mode}
        } = this;

        const availableSlots = [
            { value: 0, label: '0:00' },
            { value: 1, label: '1:00' },
            { value: 2, label: '2:00' },
            { value: 3, label: '3:00' },
            { value: 4, label: '4:00' },
            { value: 5, label: '5:00' },
            { value: 6, label: '6:00' },
            { value: 7, label: '7:00' },
            { value: 8, label: '8:00' },
            { value: 9, label: '9:00' },
            { value: 10, label: '10:00' },
            { value: 11, label: '11:00' },
            { value: 12, label: '12:00' },
            { value: 13, label: '13:00' },
            { value: 14, label: '14:00' },
            { value: 15, label: '15:00' },
            { value: 16, label: '16:00' },
            { value: 17, label: '17:00' },
            { value: 18, label: '18:00' },
            { value: 19, label: '19:00' },
            { value: 20, label: '20:00' },
            { value: 21, label: '21:00' },
            { value: 22, label: '22:00' },
            { value: 23, label: '23:00' },
            { value: 24, label: '24:00' },
        ];


        return (
            <Container className={"portlet-wrapper"}>
                <Row noGutters>
                    <Col className={"portlet-header"}>
                        <h4>
                            <i className="fas fa-edit"/>&nbsp;
                            <span>{mode === "CREATE" ? "Add Adviser": "Edit Adviser" }</span>
                        </h4>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                            <Card className={"card-form-portlet"}>
                                <Card.Header as="h5">Adviser Information</Card.Header>
                                <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    value={name}
                                                    onChange={(e)=> this.handleOnTextChange(e, "name")}
                                                    required
                                                    type="text"/>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>E-mail</Form.Label>
                                                <Form.Control
                                                    value={email}
                                                    onChange={(e)=> this.handleOnTextChange(e, "email")}
                                                    required
                                                    type="email"/>
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control
                                                    value={phone}
                                                    onChange={(e)=> this.handleOnTextChange(e, "phone")}
                                                    required
                                                    type="text"/>
                                            </Form.Group>
                                        </Form.Row>

                                        <Row>
                                            <Col md={12} lg={6}>
                                                <Form.Label>Photo</Form.Label>
                                                <div className="custom custom-file">
                                                    <input
                                                        type="file"
                                                        accept="image/png, image/jpeg, image/jpg"
                                                        className={"custom-file-input"}
                                                        id="fileLogo"
                                                        onChange={this.handleLoadFile}
                                                        value={inputFileValue}
                                                    />
                                                    <label
                                                        className={"custom-file-label"}
                                                        htmlFor="fileLogo">{file? file.name : photo && photo !==""? "Change photo": "Select a photo"}</label>
                                                </div>
                                            </Col>
                                        </Row>
                                        { photo && photo !== "" &&
                                            <Row>
                                                <Col lg={6}>
                                                    <div className={"image-form-wrapper"}>
                                                        <Button
                                                            variant={"danger"}
                                                            className={"btn-delete-image"}
                                                            onClick={()=> this.setState({
                                                                photo: null,
                                                                file: null,
                                                                inputFileValue: ""
                                                            })}>
                                                            <i className="fas fa-times"/>
                                                        </Button>
                                                        <Image src={photo} thumbnail />
                                                    </div>

                                                </Col>
                                            </Row>
                                        }

                                        <Form.Row style={{marginTop: "15px"}}>
                                            <Form.Group as={Col}>
                                                <Form.Label>Day Availability</Form.Label>
                                                <ListGroup horizontal>
                                                    <ListGroup.Item
                                                        onClick={()=> this.addDayAvailability("MONDAY")}
                                                        active={dayAvailability.includes("MONDAY")}
                                                    >
                                                        Monday</ListGroup.Item>
                                                    <ListGroup.Item
                                                        onClick={()=> this.addDayAvailability("TUESDAY")}
                                                        active={dayAvailability.includes("TUESDAY")}
                                                    >
                                                        Tuesday</ListGroup.Item>
                                                    <ListGroup.Item
                                                        onClick={()=> this.addDayAvailability("WEDNESDAY")}
                                                        active={dayAvailability.includes("WEDNESDAY")}
                                                    >
                                                        Wednesday</ListGroup.Item>
                                                    <ListGroup.Item
                                                        onClick={()=> this.addDayAvailability("THURSDAY")}
                                                        active={dayAvailability.includes("THURSDAY")}
                                                    >
                                                        Thursday</ListGroup.Item>
                                                    <ListGroup.Item
                                                        onClick={()=> this.addDayAvailability("FRIDAY")}
                                                        active={dayAvailability.includes("FRIDAY")}
                                                    >
                                                        Friday</ListGroup.Item>
                                                    <ListGroup.Item
                                                        onClick={()=> this.addDayAvailability("SATURDAY")}
                                                        active={dayAvailability.includes("SATURDAY")}
                                                    >
                                                        Saturday</ListGroup.Item>
                                                    <ListGroup.Item
                                                        onClick={()=> this.addDayAvailability("SUNDAY")}
                                                        active={dayAvailability.includes("SUNDAY")}
                                                    >
                                                        Sunday</ListGroup.Item>
                                                </ListGroup>
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>From</Form.Label>
                                                <Select
                                                    value={{ value: from, label: `${from}:00` }}
                                                    onChange={(e: any)=> this.handleSlotChange(e, "from")}
                                                    options={availableSlots.filter((s:any)=> s.value !== 24)}
                                                    classNamePrefix={`form-dropdown`}
                                                    className={"form-dropdown"}
                                                    placeholder={"Select time slot"}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>To</Form.Label>
                                                <Select
                                                    value={{ value: to, label: `${to}:00` }}
                                                    onChange={(e: any)=> this.handleSlotChange(e, "to")}
                                                    options={availableSlots.filter((s:any)=> s.value > from)}
                                                    classNamePrefix={`form-dropdown`}
                                                    className={"form-dropdown"}
                                                    placeholder={"Select time slot"}
                                                    required
                                                />
                                            </Form.Group>
                                        </Form.Row>


                                        <Row>
                                            <Col>
                                                <hr/>
                                                <p>
                                                    Setup Call to action
                                                </p>
                                            </Col>
                                        </Row>

                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Action</Form.Label>
                                                <ListGroup horizontal>
                                                    <ListGroup.Item
                                                        onClick={()=> this.setCTA("email")}
                                                        active={cta === ("email")}
                                                    >
                                                        Send email</ListGroup.Item>
                                                    <ListGroup.Item
                                                        onClick={()=> this.setCTA("booking")}
                                                        active={cta === ("booking")}
                                                    >
                                                        Book Appointment</ListGroup.Item>
                                                    <ListGroup.Item
                                                        onClick={()=> this.setCTA("call")}
                                                        active={cta === ("call")}
                                                    >
                                                        Call
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Label to be displayed</Form.Label>
                                                <Form.Control
                                                    value={ctaLabel}
                                                    onChange={(e)=> this.handleOnTextChange(e, "ctaLabel")}
                                                    required
                                                    type="text"/>
                                            </Form.Group>
                                        </Form.Row>

                                    {cta === "booking" &&
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>External URL</Form.Label>
                                                <Form.Control
                                                    value={ctaExternalURL}
                                                    onChange={(e)=> this.handleOnTextChange(e, "ctaExternalURL")}
                                                    required
                                                    type="text"/>
                                            </Form.Group>
                                        </Form.Row>
                                    }
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <Button variant={!isDataUploading ? "primary": "outline-primary"} type="submit" disabled={isDataUploading}>
                                        {mode === "CREATE"?  (!isDataUploading? "Add": "Adding"):  (!isDataUploading? "Update": "Updating") } adviser
                                        {isDataUploading &&
                                        <>  &nbsp;
                                            <Spinner animation="grow" variant="primary" size="sm" />
                                        </>
                                        }

                                    </Button>
                                    <NavLink to={`/cms/${companyId}/${section}/list`}>
                                        <Button variant={"secondary"}>
                                            Cancel
                                        </Button>
                                    </NavLink>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default AdviserForm;
