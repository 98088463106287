import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import HeaderDescription from "../dashboard/HeaderDescription";
import {NavLink} from "react-router-dom";

type Props = {
    companyId: string,
    section: string,
    dashboard: string
};

type State = {
    company: any;
};

class CompanyProfile extends Component<Props, State> {

    render() {
        const {
            props: {companyId, section, dashboard}
        } = this;
        return (
            <Container className={"portlet-wrapper"}>
                <HeaderDescription
                    companyId={companyId}
                    dashboard={dashboard}
                    section={section}
                    icon={"fas fa-building"}
                    feedback={[]}
                    name={"Company"} />
                <Row>
                    <Col>
                        <div className="toolbar-dashboard">
                            <NavLink to={`/cms/${companyId}/${section}/edit`} className={"btn btn-success"}>
                                <i className="fas fa-edit"/>&nbsp;Edit information
                            </NavLink>
                            <NavLink target={"_black"} to={`/company/${companyId}/details`} className={"btn btn-light float-right"}>
                                <i className="fas fa-globe-europe"/> See website
                            </NavLink>
                        </div>
                    </Col>
                </Row>
                <Row noGutters>

                </Row>
            </Container>
        );
    }
}

export default CompanyProfile;
