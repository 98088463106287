import React, { Component } from 'react';
import {
    Card,
    Col,
    Container,
    Row,
    Button,
    Form,
    OverlayTrigger,
    Popover, Spinner
} from "react-bootstrap";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import "./portlet.scss";

import firebase from 'firebase/app';
import 'firebase/storage';

import Util from "../../../util/Util";
import {NavLink} from "react-router-dom";
import BranchForm from "./BranchForm";
import Feedback from "../feedback/Feedback";
import TextEditor from "../textEditor/TextEditor";
import {Linode} from "../../etc/Config";
import AWS from 'aws-sdk';
import AddressAutocomplete from "../shared/addressAutocomplete/AddressAutocomplete";
import ImageCropper from "../shared/imageCropper/ImageCropper";
AWS.config = new AWS.Config();
AWS.config.accessKeyId = Linode.accessKeyId;
AWS.config.secretAccessKey = Linode.secretAccessKey;
AWS.config.region = Linode.region;
const s3 = new AWS.S3({endpoint: Linode.endpoint});
const hostingURL = Linode.hostingURL;
const animatedComponents = makeAnimated();

type Props = {
    mode: "EDIT" | "CREATE",
    companyId: any,
}

type State = {
    validated: boolean,
    branches: Array<any>,
    availableBranches: Array<any>,
    address: string,
    city: string,
    description: Array<any>,
    name: string,
    zipcode: string,
    lat: number,
    lng: number,
    isMarkerShown: boolean,
    loadingData: boolean,
    logoFile: any | null,
    logo: string | null,
    coverFile: any | null,
    cover: string | null,
    inputCoverValue: any,
    inputLogoValue: any,
    feedback: Array<any>,
    isBranchPopoverOpen: boolean,
    isLife: boolean,
    isDataUploading: boolean,
}
class CompanyForm extends Component<Props, State> {

    state:State = {
        validated: false,
        availableBranches: [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' },
        ],
        branches: [],
        address: "",
        city: "",
        description: this.props.mode === "EDIT" ? []: [
            {
                type: 'paragraph',
                children: [{ text: '' }],
            }
        ],
        name: "",
        zipcode: "",
        lat: 0,
        lng: 0,
        isMarkerShown: false,
        loadingData: true,
        logoFile: null,
        logo: "",
        coverFile: null,
        cover: "",
        inputLogoValue: "",
        inputCoverValue: "",
        feedback: [],
        isBranchPopoverOpen: false,
        isLife: false,
        isDataUploading: false
    };

    componentDidMount(): void {
        this.loadBranches();
        if(this.props.mode ==="EDIT"){
            this.loadRecord();
        }else{
            this.setState({loadingData: false});
        }

    }

    handleBranchChange = (selectedBranches: Array<any>) => {
        this.setState({ branches : selectedBranches || [] });
    };

    handleSubmit= (event: any)=>{
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;

        if (form.checkValidity() === true && this.state.branches.length > 0 ) {
            this.addCompany();
        }
        this.setState({validated: true});
    };

    loadRecord = () => {
        firebase.database().ref(`companies/${this.props.companyId}`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                let defaultDescription = [
                    {
                        type: 'paragraph',
                        children: [{ text: '' }],
                    }
                ];
                this.setState(
                    {
                        branches: snapshot.val().information.branches.map((b: string)=> {return {value: b, label: b}}) || [],
                        address: Util.decodeCharacters(snapshot.val().information.address),
                        city: Util.decodeCharacters(snapshot.val().information.city),
                        description: snapshot.val().information.description ? JSON.parse(Util.decodeCharacters(snapshot.val().information.description.toString()) ): defaultDescription,
                        name: Util.decodeCharacters(snapshot.val().information.name),
                        zipcode: snapshot.val().information.zipcode,
                        lat: snapshot.val().information.geolocation.lat,
                        lng: snapshot.val().information.geolocation.lng,
                        loadingData: false,
                        logo: snapshot.val().information.logo,
                        cover: snapshot.val().information.cover,
                        isLife: snapshot.val().information.isLife,
                    }
                )
            }
            this.setState({loadingData: false});
        });
    };

    loadBranches = ()=> {
        firebase.database().ref(`app/branches`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                let branches= snapshot.val();
                this.setState(
                    {
                        availableBranches: Object.keys(branches).map((key: string)=> {
                            return {value: branches[key].label, label: branches[key].label}
                        }),
                    }
                )
            }
        });
    };

    addCompany = () => {
        const {
            address,
            city,
            description,
            name,
            zipcode,
            lat,
            lng,
            branches,
            isLife,
            cover,
            logo
        } = this.state;

        const {companyId, mode} = this.props;
        this.setState({isDataUploading: true});

        const data = {
            information: {
                address: address,
                city: city,
                description: Util.encodeCharacters(JSON.stringify(description)),
                geolocation: {lat: lat, lng: lng},
                name: name,
                zipcode: zipcode,
                branches: branches.map((b)=> b.value ),
                isLife: isLife,
                logo: logo,
                cover: cover
            }
        };

        if(mode === "CREATE"){
            firebase.database().ref(`companies`).push(data).then((snap)=>{
                this.storeImages(snap.key || "def");
            });
        }

        if(mode === "EDIT"){
            firebase.database().ref(`companies/${companyId}`).update(data
            ).then(()=>{
                this.storeImages(companyId);
            });
        }
    };


    getPromiseFile = (file: any, fileName: string, companyId: string ) => {
        let params = {
            Body: file,
            Bucket: Linode.Bucket,
            Key: `companies/${companyId}/${fileName}`,
            ACL:'public-read'
        };

        return new Promise((resolve, reject) => {
            this.setState({isDataUploading: true});
            s3.putObject(params, (err) => {
                if(!err){
                    let url = `${hostingURL}/companies/${companyId}/${fileName}`;
                    firebase.database().ref(`companies/${companyId}/information/`).update({[fileName]: url}).then(()=>{
                        resolve();
                    });
                }else{
                    reject();
                }
            });
        });
    };

    storeImages = (companyId: string) => {
        let promises = [];

        if(this.state.logoFile){
            promises.push(this.getPromiseFile(this.state.logoFile, "logo", companyId))
        }

        if(this.state.coverFile){
            promises.push(this.getPromiseFile(this.state.coverFile, "cover", companyId));
        }

        if(promises.length > 0){
            Promise.all(promises).then(() => {
                this.setState({isDataUploading: false});
                window.location.href = Util.getPath(`cms/${companyId}/company/profile`);
            });
        }else{
            window.location.href = Util.getPath(`cms/${companyId}/company/profile`);
        }
    };


    handleOnTextChange = (e: any, key: any) => {
         let value = e.target.value;
        // @ts-ignore
        this.setState({[key]: value});
    };

    handleTextAreaOnChange = (value: any) => {
        this.setState({description: value});
    };

    handleLoadFile = (imaageBase64: any, file: any) => {
        this.setState({logoFile: file, logo: imaageBase64, inputLogoValue: ""});
    };

    handleLoadCoverFile = (imaageBase64: any, file: any) => {
        this.setState({coverFile: file, cover: imaageBase64, inputCoverValue: ""});
    };

    pushFeedback = ( feedbackData : any)=>{
        let feedBack = this.state.feedback;
        this.setState({feedback: [feedbackData , ...feedBack]})
    };

    onAddressFound = (data: any) =>{
      this.setState({
          zipcode: data.zipcode.short_name,
          city: data.city.short_name,
          lat: data.lat,
          lng: data.lng,
      });
    };

    render() {
        const {
            state: {
                validated,
                branches,
                availableBranches,
                name,
                description,
                address,
                city,
                zipcode,
                lat,
                lng,
                isBranchPopoverOpen,
                loadingData,
                logo,
                cover,
                feedback,
                isDataUploading
            },
            props: {mode, companyId}
        } = this;

        if(loadingData){
            return null;
        }

        return (
            <Container className={"portlet-wrapper"}>
                <Row noGutters>
                    <Col className={"portlet-header"}>
                        <h4>
                            <i className="fas fa-edit"/>&nbsp;
                            <span>{mode === "CREATE" ? "Add Company": "Edit Company" }</span>
                        </h4>
                        <p>
                            Hier finden Sie alle relevanten Information zu den existierenden Firmen. ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                    </Col>
                </Row>
                <Feedback messages={feedback}/>
                <Row noGutters>
                    <Col className={"card-form-portlet"}>
                        <Form noValidate validated={validated} onSubmit={this.handleSubmit} autoComplete={"off"}>
                            <Card>
                                <Card.Header as="h5">Company Information</Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Name *</Form.Label>
                                            <Form.Control
                                                value={name}
                                                onChange={(e)=> this.handleOnTextChange(e, "name")}
                                                required
                                                type="text"/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row style={{zIndex: 10}}>
                                        <Col>
                                            <Form.Label>Branch *</Form.Label>
                                            <Select
                                                value={branches}
                                                onChange={this.handleBranchChange}
                                                options={availableBranches}
                                                isMulti
                                                components={animatedComponents}
                                                ignoreCase={true}
                                                classNamePrefix={`form-dropdown ${validated && branches.length === 0 ? "error": validated && branches.length > 0 ? "success": ""}`}
                                                className={"form-dropdown"}
                                                placeholder={"Select Branch"}
                                                required
                                            />
                                        </Col>
                                        <Col style={{maxWidth: "200px"}}>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement={"left"}
                                                overlay={
                                                    <Popover
                                                        bsPrefix={"popover popover-form-branch"}
                                                        id={`popover-positioned-left`}>
                                                        <Popover.Title as="h3"><i className="fas fa-code-branch"/>  {`New branch`}</Popover.Title>
                                                        <Popover.Content>
                                                            <BranchForm isRequired={isBranchPopoverOpen} onSuccess={this.pushFeedback}/>
                                                        </Popover.Content>
                                                    </Popover>
                                                }
                                            >
                                                <Button
                                                    onClick={()=> this.setState(prevState => ({isBranchPopoverOpen: !prevState.isBranchPopoverOpen}))}
                                                    id={"btn-trigger-popover-form-branch"}
                                                    className={"btn btn-primary"}
                                                    style={{marginTop: "30px", width: "100%"}}>
                                                    <i className={"fa fa-plus"}/> Define new branch
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                    </Form.Row>

                                    <Form.Row style={{marginTop: "15px"}}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Description</Form.Label>
                                            {
                                                description.length > 0 && <TextEditor
                                                    onChange={(value: string)=> this.handleTextAreaOnChange(value)}
                                                    value={description}
                                                />
                                            }
                                        </Form.Group>
                                    </Form.Row>

                                    <Row style={{position: "relative", zIndex: 0}}>
                                        <Col lg={6}>
                                            <ImageCropper
                                                image={logo}
                                                aspect={1}
                                                label={"Logo"}
                                                updateImage={this.handleLoadFile}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <ImageCropper
                                                image={cover}
                                                aspect={4/1}
                                                label={"Cover"}
                                                updateImage={this.handleLoadCoverFile}
                                            />
                                        </Col>
                                    </Row>

                                    <hr/>

                                    <AddressAutocomplete
                                     address={address}
                                     handleOnAddressChange={(address: string)=> this.setState({address: address})}
                                     handleOnAddressSelect={this.onAddressFound}/>

                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                value={city}
                                                onChange={(e)=> this.handleOnTextChange(e, "city")}
                                                required />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridZip">
                                            <Form.Label>Zip code</Form.Label>
                                            <Form.Control
                                                value={zipcode}
                                                onChange={(e)=> this.handleOnTextChange(e, "zipcode")}
                                                required />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label>Latitude</Form.Label>
                                            <Form.Control
                                                value={lat}
                                                readOnly
                                                type={"number"}
                                                onChange={(e)=> this.handleOnTextChange(e, "lat")}
                                                 />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridZip">
                                            <Form.Label>Longitude</Form.Label>
                                            <Form.Control
                                                value={lng}
                                                readOnly
                                                type={"number"}
                                                onChange={(e)=> this.handleOnTextChange(e, "lng")}
                                                 />
                                        </Form.Group>
                                    </Form.Row>

                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <Button variant={!isDataUploading ? "primary": "outline-primary"} type="submit" disabled={isDataUploading}>
                                        {mode === "CREATE"?  (!isDataUploading? "Add": "Adding"):  (!isDataUploading? "Update": "Updating") } company
                                        {isDataUploading &&
                                            <>  &nbsp;
                                                <Spinner animation="grow" variant="primary" size="sm" />
                                            </>
                                        }
                                    </Button>
                                    {" "}
                                    <NavLink to={`/cms/${companyId}/company/profile`}>
                                        <Button variant={"secondary"}>
                                            Cancel
                                        </Button>
                                    </NavLink>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default CompanyForm;
