import React, {Component} from 'react';
import Container from "react-bootstrap/esm/Container";
import {Col,  Row} from "react-bootstrap";
import Aside from "../../components/aside/Aside";
import "./main.scss";
import Dashboard from "../../../cms/components/dashboard/Dashboard";

type Props = {
    dashboard: string;
    section: string;
    companyId: string;
    record: string;
}

type State = {
    collapsed: boolean;
    toggled: boolean;
}

class Main extends Component<Props,State> {

    state: State = {
        collapsed: false,
        toggled: false,
    };

    handleToggleSidebar = (value: any) => {
        this.setState({toggled: value});
    };

    handleCollapseSidebar = ()=> {
        this.setState(prevState => ({collapsed: !prevState.collapsed}));
    };

    render() {
        const {
            state:{collapsed, toggled},
            props:{dashboard, section, companyId, record}
        }= this;

        return (
            <Container fluid className={"admin-wrapper"}>
                <Row noGutters className={"h-100"}>
                    <Col className={"h-100 panel-main-portlet"}>
                        <Aside
                            collapsed={collapsed}
                            toggled={toggled}
                            handleCollapsedChange={this.handleCollapseSidebar}
                            handleToggleSidebar={this.handleToggleSidebar}
                            dashboard={dashboard}
                            section={section}
                        />
                        <Dashboard collapsed={collapsed}
                                   toggled={toggled}
                                   handleCollapsedChange={this.handleCollapseSidebar}
                                   handleToggleSidebar={this.handleToggleSidebar}
                                   dashboard={dashboard}
                                   section={section}
                                   companyId={companyId}
                                   record={record}
                        />
                    </Col>

                </Row>
            </Container>
        );
    }
}

export default Main;
