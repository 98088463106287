import React, {Component} from 'react';
import Cropper from 'react-easy-crop';
import {Form} from "react-bootstrap";
import Util from "../../../../util/Util";
import getCroppedImg from "./cropImage";

type Props = {
    image: string | null;
    aspect: number;
    updateImage: Function,
    label: string
};
type State = {
    image: string | null;
    inputImageValue: string;
    crop: {x: number, y: number};
    zoom: number
}

class ImageCropper extends Component<Props, State> {
    state: State= {
        image: this.props.image,
        inputImageValue: "",
        crop: { x: 0, y: 0 },
        zoom: 1,
    };

    onCropChange = (crop: {x: number, y: number}) => {
        this.setState({ crop })
    };

    onCropComplete = async (croppedArea: any, croppedAreaPixels: any) => {
        try {
            const croppedImage = await getCroppedImg(
                this.state.image,
                croppedAreaPixels,
                0
            );
            const self = this;
            let reader = new FileReader();
            reader.onload = function() {
                let dataUrl = reader.result;
                let base64 = dataUrl;
                self.props.updateImage(base64, self.state.inputImageValue);
            };
            reader.readAsDataURL(croppedImage);
        } catch (e) {
            console.error(e)
        }
    };

    onZoomChange = (zoom: number) => {
        this.setState({ zoom })
    };

    handleLoadFile = (e: any) => {
        if(e && e.target && e.target.files.length > 0){
            let file = e.target.files[0];
            Util.imageToBase64(file).then((image: any)=>{
                this.setState({image: image, inputImageValue: "" });
            });
        }
    };

    render() {

        const {
            state:{
                inputImageValue,
                image,
                crop,
                zoom
            },
            props:{
                aspect,
                label
            }
        } = this;
        return (
            <div style={{display: "block"}}>

                <Form.Label>{label} *</Form.Label>
                <div className="custom custom-file">
                    <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        className={"custom-file-input"}
                        id="fileLogo"
                        onChange={this.handleLoadFile}

                        value={inputImageValue}
                    />
                    <label
                        className={"custom-file-label"}
                        htmlFor="fileLogo">{"Select an image"}</label>
                </div>

                <div style={{height: "300px", position: "relative", marginTop: "10px"}}>
                    {image &&
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                    />
                    }
                </div>

            </div>
    )
    }
}

export default ImageCropper;
