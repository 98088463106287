import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import './home.scss';
import HomeHero from "../../shared/homeHero/HomeHero";
import CompanyCarousel from "../../shared/company-carousel/CompanyCarousel";
import JobCheckHero from "../../shared/job-check-hero/JobCheckHero";
import NavigationBar from "../../shared/navigationBar/NavigationBar";
import firebase from 'firebase/app';
import 'firebase/database';
import Footer from "../../shared/footer/Footer";
import QAdeckCard from "../../shared/QAdeckCard/QAdeckCard";

type Props = {};

type State = {
    companies: any;
    loadingData: boolean;
};

class Home extends Component<Props, State> {

    state: State = {
        companies: {},
        loadingData: true
    };

    componentDidMount(): void {
        firebase.database().ref(`companies/`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState({companies: snapshot.val(), loadingData: false});
            }
        });
    }

    render() {

        const {companies} = this.state;
        return (
            <>
                <Container fluid className={"home-wrapper"}>
                    <NavigationBar/>
                    <HomeHero/>
                    <CompanyCarousel companies={companies} />
                </Container>
                <Container>
                    <QAdeckCard/>
                </Container>
                <JobCheckHero/>
                <Footer/>
            </>
        );
    }
}

export default Home;
