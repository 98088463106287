import React, {Component} from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";
import CookieConsent from "react-cookie-consent";
import './navigationBar.scss';

type Props = {
    counter?: number
}

type State = {
    wishListLength: number,
}

class NavigationBar extends Component<Props, State> {

    state: State = {
        wishListLength: 0
    };

    componentDidMount(): void {
        this.updateCounter();
    }

    componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): void {
        let wishList = JSON.parse(localStorage.getItem('wish-list') || "[]");
        if(Array.isArray(wishList) && wishList.length !== this.state.wishListLength){
            this.updateCounter();
        }
    }

    updateCounter = () => {
        let wishList = JSON.parse(localStorage.getItem('wish-list') || "[]");
        if(Array.isArray(wishList)){
            this.setState({wishListLength: wishList.length});
        }
    };

    render() {
        const { state: {wishListLength}} = this;
        return (
            <>
                <CookieConsent
                    location="top"
                    buttonText="Akzeptieren"
                    cookieName="consent-cookie"
                    style={{ background: "#343a40", position: "relative", borderBottom: "1px solid #494e53"}}
                    buttonStyle={{ color: "white", fontSize: "13px", background: "#b5c534", borderRadius: "5px"}}
                    expires={150}
                >
                    Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.
                </CookieConsent>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className={"navigation-bar-wrapper"} >
                    <Container fluid>
                        <Navbar.Brand href="/">
                            <img src={require("../../../assets/ui/logo.svg")} alt="" width={"40px"}/>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                            <Nav className={"navBar-links-wrapper"}>
                                <Nav.Link href="/firmen">FIRMEN</Nav.Link>
                                <Nav.Link href="/wish-list">
                                    <i className={"fas fa-heart"} style ={{color: wishListLength > 0 ? "#ff004e": ""}}/>&nbsp;
                                    WUNSCHLISTE {wishListLength > 0 && <span>({wishListLength})</span>}
                                </Nav.Link>
                                <Nav.Link href="#deets">BERUFSCHECK</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}

export default NavigationBar;
