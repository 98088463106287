import React, {Component} from 'react';
import {Alert, Button, Col, Container, Row} from "react-bootstrap";
import firebase from 'firebase/app';
import ImageCard from "./ImageCard";
import DnDZone from "../shared/dropzone/DnDZone";
import HeaderDescription from "../dashboard/HeaderDescription";
import Loading from "../shared/loading/Loading";
import moment from 'moment';
import Util from "../../../util/Util";
import {NavLink} from "react-router-dom";

import {Linode} from "../../etc/Config";
import AWS from 'aws-sdk';
AWS.config = new AWS.Config();
AWS.config.accessKeyId = Linode.accessKeyId;
AWS.config.secretAccessKey = Linode.secretAccessKey;
AWS.config.region = Linode.region;
const s3 = new AWS.S3({endpoint: Linode.endpoint});
const hostingURL = Linode.hostingURL;

const Compress = require('compress.js');
const compress = new Compress();

type Props = {
    companyId: string,
    section: string,
    dashboard: string
};

type State = {
    gallery: any,
    loadingData: boolean,
    gridSize: "large" | "small",
    showModalDelete: boolean,
    feedback: Array<any>,
    showForm: boolean
}

class Gallery extends Component<Props, State> {

    state: State = {
        gallery: {},
        loadingData: false,
        gridSize: "large",
        showModalDelete: false,
        feedback: [],
        showForm: false,
    };

    componentDidMount(): void {
        this.setState({loadingData: true});
        firebase.database().ref(`companies/${this.props.companyId}/gallery`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState({gallery: snapshot.val()});
            }else{
                this.setState({gallery: []});
            }
            this.setState({loadingData: false});
        });
    }

    onDrop = (files: Array<any>) => {
        files.forEach((f)=>{
            Util.imageToBase64(f).then((image: any)=>{
                let data =  {headline: "", caption: "", file: f.path, url: "" };
                firebase.database().ref(`companies/${this.props.companyId}/gallery/`).push(data).then((snap)=> {
                    this.storeImage(this.props.companyId, snap.key, f , image );
                });
            })
        });
    };

    storeImage = (companyId: string, name: any, file: any, image: any) => {
        if(file){
            compress.compress([file], {
                    size: 0.5, // the max size in MB, defaults to 2MB
                    quality: 1, // the quality of the image, max is 1,
                    maxWidth: 2000, // the max width of the output image, defaults to 1920px
                    maxHeight: 2000, // the max height of the output image, defaults to 1920px
                    resize: true // defaults to true, set false if you do not want to resize the image width and height
            }).then((compressedFile: any) => {
                const img1 = compressedFile[0];
                const base64str = img1.data;
                const imgExt = img1.ext;
                const smallFile = Compress.convertBase64ToFile(base64str, imgExt);

                let params = {
                    Body: smallFile,
                    Bucket: "startzeit-digital-bucket",
                    Key: `companies/${companyId}/gallery/${name}`,
                    ACL:'public-read'
                };

                s3.putObject(params, (err) => {
                    if(err){
                        alert("error")
                    }else{
                        let url = `${hostingURL}/companies/${companyId}/gallery/${name}`;
                        firebase.database().ref(`companies/${this.props.companyId}/gallery/${name}`).update({url: url}).then(()=>{
                            this.pushFeedback({
                                type: "success",
                                title: "Image added",
                                text: "Image was added successfully!",
                                id: moment.now(),
                                image: <img src={image} alt="" width={80}/>
                            })
                        });
                    }
                });
            });
        }else{
            window.location.href = Util.getPath(`cms/${companyId}/company/profile`);
        }
    };

    deleteImage = (imageId: string) => {
        firebase.storage().ref(`companies/${this.props.companyId}/gallery/${imageId}`).delete().then(() => {
            firebase.database()
                .ref(`companies/${this.props.companyId}/gallery/`)
                .update({[imageId]: null}).then(()=>{

            }).catch( ()=> {});
    })
    };

    pushFeedback = ( feedbackData : any)=>{
        let feedBack = this.state.feedback;
        this.setState({feedback: [feedbackData , ...feedBack]})
    };

    render() {
        const {
            props: {companyId, dashboard, section},
            state: {gallery, gridSize, loadingData, feedback, showForm}
        } = this;
        return (
            <Container className={"portlet-wrapper"}>
                <HeaderDescription
                    companyId={companyId}
                    dashboard={dashboard}
                    section={section}
                    icon={"fas fa-images"}
                    feedback={feedback}
                    name={"Gallery"}/>

                { loadingData && <Loading/> }

                { !loadingData && Object.keys(gallery).length === 0 &&
                    <Row>
                        <Col>
                            <Alert variant={"primary"}>
                                <i className="fas fa-info-circle" style={{fontSize: "20px"}}/>&nbsp;
                                Your gallery is currently empty, please add some nice images to attract the attention of the applicants
                            </Alert>

                        </Col>
                    </Row>
                }
                {!loadingData &&  Object.keys(gallery).length > 0 &&
                    <Row noGutters>
                        <Col>
                            <div className="toolbar-dashboard">
                                <Button variant="light" onClick={()=> this.setState({gridSize: "large"})}>
                                    <i className="fas fa-th-large"/>
                                </Button>
                                <Button variant="light" onClick={()=> this.setState({gridSize: "small"})}>
                                    <i className="fas fa-th"/>
                                </Button>
                                <span className="divider"/>
                                <Button variant="light" onClick={()=> this.setState(prevState =>({showForm: !prevState.showForm}))}>
                                    <i className="fas fa-edit"/>
                                </Button>
                                <NavLink target={"_black"} to={`/company/${companyId}/details#gallery`} className={"btn btn-light float-right"}>
                                    <i className="fas fa-globe-europe"/> See website
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                }
                {!loadingData &&
                    <Row noGutters>
                        <Col>
                            <DnDZone onDrop={this.onDrop}/>
                        </Col>
                    </Row>
                }

                { !loadingData && Object.keys(gallery).length > 0 &&
                    <Row noGutters>
                        <Col style={{padding: "0 5px"}}>
                            <Row>
                                {Object.keys(gallery).reverse().map((key) =>
                                    <Col
                                        key={key}
                                        lg={gridSize === "large" ? 6: 4}
                                        xl={gridSize === "large" ? 4: 3}
                                        style={{ padding: "10px" }}>
                                        <ImageCard
                                            companyId={companyId}
                                            imageId={key}
                                            image={gallery[key]}
                                            pushFeedback={this.pushFeedback}
                                            showForm={showForm}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                }
            </Container>
        );
    }
}

export default Gallery;
