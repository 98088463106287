import ProductDetail from "./app/screens/productDetail/ProductDetail";
import Main from "./cms/screens/main/Main";
import queryString from 'query-string';
import React, {Component} from 'react';
import { Route, Switch, Redirect, RouteProps, useParams } from "react-router-dom";
import Home from "./app/screens/home/Home";
import SearchView from "./app/screens/searchView/SearchView";
import WishList from "./app/screens/wishList/WishList";
import Firmen from "./app/screens/firmen/Firmen";
import Authentication from "./Authentication";
import Login from "./cms/screens/login/Login";

const PrivateRoute = ({ component, ...rest }: RouteProps) => (
    <Route
        render={props =>
            Authentication.getAuthentication() ? (
                <Route {...rest} component={component} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);


class Routing extends Component<{}, {}> {

    home = () => {
        if(window.location.href.startsWith("https://startzeitdigital.web.app/")){
            window.location.href = "https://startzeit-digital.de/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=webapp";
        }

        return  <Home/>;
    };

    productList = (props: any) => {
        let params = queryString.parse(props.location.search);
        let city = props.match.params.city;
        return  <SearchView city={city}
                            branches={params && params.b ? params.b : null}
                            jobType={params && params.j ? params.j : null}/>;
    };

    wishList = () => {
        return  <WishList />;
    };

    firmen = () => {
        return  <Firmen />;
    };

    productDetail = () => {
       // let query = new URLSearchParams(location.search).get("b");
        let { id } = useParams();
        return  <ProductDetail id={id}/>;
    };

    notFound = () => {
        return  <div>Not found 400</div>;
    };

    cms = () => {
        let { companyId, section, dashboard, record } = useParams();
        if(!companyId && !section  && !dashboard ){
            window.location.href = "/cms/company/list";
        }
        return  <Main section={section? section: "home" }
                      dashboard={dashboard? dashboard: "overview"}
                      companyId={companyId}
                      record={record}/>
    };

    cmsAddCompany = () => {
        return  <Main section={"company" } dashboard={"create"} companyId={""} record={""}/>
    };

    cmsEditCompany = () => {
        let { companyId } = useParams();
        return  <Main section={"company" } dashboard={"edit"} companyId={companyId} record={""}/>
    };

    login = (route: any) => {
        return route.location.state ? (
            <Login
                from={route.location.state.from.pathname}
                search={route.location.state.from.search}
            />
        ) : (
            <Login from={"/"} search={""} />
        );
    };

    redirect = (c?: string) => {
        let { company } = useParams();
        if(c && typeof c === "string"){
            company = c;
        }

        switch (company) {
          case "eosz": window.location.href = "https://startzeit-digital.de/firmen/europaschule-oberstufenzentrum-oder-spree/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=OSZ";break;
          case "kompetenznetzwerk": window.location.href = "https://startzeit-digital.de/firmen/kompetenznetzwerk-fachkraeftesicherung-ostbrandenburg-e-v/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=Kompetenznetzwerk";break;
          case "trevira": window.location.href = "https://startzeit-digital.de/firmen/trevira-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=trevira";break;
          case "b30": window.location.href = "https://startzeit-digital.de/firmen/baeckerei-dreissig-kg/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=baeckerei30";break;
          case "karrierecenter": window.location.href = "https://youtu.be/c19-qBPPvcE";break;
          case "ehst": window.location.href = "https://startzeit-digital.de/firmen/schule-fuer-gesundheit-und-pflegeberufe-eisenhuettenstadt-e-v/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=gesundheitsschule-eh";break;
          case "viadrina": window.location.href = "https://startzeit-digital.de/firmen/europa-universitaet-viadrina/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=viadrina";break;
          case "ewg": window.location.href = "https://startzeit-digital.de/firmen/eisenhuettenstaedter-wohnungsbaugenossenschaft-eg/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=ewg-eh";break;
          case "elas": window.location.href = "https://startzeit-digital.de/firmen/elas-elektroanlagenbau-eisenhuettenstadt-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=elas";break;
          case "ekhffo": window.location.href = "https://startzeit-digital.de/firmen/evangelisches-krankenhaus-lutherstift-frankfurt-oder/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=ev-kh"; break;
          case "hotelprinzalbrecht": window.location.href = "https://startzeit-digital.de/firmen/landhaushotel-prinz-albrecht/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=prinzalbrecht";break;
          case "spie": window.location.href = "https://spie.de/karriere/ausbildung-duales-studium";break;
          case "actemium": window.location.href = "https://startzeit-digital.de/firmen/actemium-bea-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=actemium";break;
          case "veo": window.location.href = "https://startzeit-digital.de/firmen/veo-vulkan-energiewirtschaft-oderbruecke-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=veo";break;
          case "osz": window.location.href = "https://startzeit-digital.de/firmen/europaschule-oberstufenzentrum-oder-spree/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=OSZ";break;
          case "sos": window.location.href = "https://startzeit-digital.de/firmen/sparkasse-oder-spree/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=sparkasse-os";break;
          case "unitechnik": window.location.href = "https://startzeit-digital.de/firmen/unitechnik-automatisierungs-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=unitechnik";break;
          case "linde": window.location.href = "https://startzeit-digital.de/firmen/l-dietze-sohn-foerdertechnik-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=dietze-sohn";break;
          case "imd": window.location.href = "https://startzeit-digital.de/firmen/imd-labor-oderland/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=imd-labor";break;
          case "bredow": window.location.href = "https://startzeit-digital.de/firmen/bredow-elektroinstallationen-gmbh-co-kg/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=bredow";break;
          case "arcelormittal": window.location.href = "https://arcelormittal.startzeit-digital.de/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=landingpage";break;
          case "klaehr": window.location.href = "https://startzeit-digital.de/firmen/elektromaschinen-service-klaehr/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=klaehr";break;
          case "asb": window.location.href = "https://startzeit-digital.de/firmen/arbeiter-samariter-bund-regionalverband-ostbrandenburg-e-v/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=asb";break;
          case "viertel": window.location.href = "https://startzeit-digital.de/firmen/viertel-elektroinstallation/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=viertel";break;
          case "franzmb": window.location.href = "https://startzeit-digital.de/firmen/autohaus-franz-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=franz-autohaus";break;
          case "debeka": window.location.href = "https://startzeit-digital.de/firmen/debeka-versicherung/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=debeka";break;
          case "cemex": window.location.href = "https://startzeit-digital.de/firmen/cemex-deutschland-ag/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=cemex";break;
          case "dabb": window.location.href = "https://www.digital-agentur.de/schwerpunkte/digitale-bildung/projekte/innovet";break;
          case "fff": window.location.href = "https://startzeit-digital.de/firmen/finanzverwaltung-brandenburg/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=finanfverwaltung-bb";break;
          case "oderglass": window.location.href = "https://startzeit-digital.de/firmen/oderglas-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=oderglas";break;
          case "kausa": window.location.href = "https://startzeit-digital.de/firmen/kausa-servicestelle-brandenburg/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=kausa";break;
          case "golem": window.location.href = "https://startzeit-digital.de/firmen/golem-kunst-und-baukeramik-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=golem";break;
          case "krohm": window.location.href = "https://www.elektroinnung-oder-spree.de/mitglieder/15859-krohm-elektro-gmbh.php?utm_source=katalog_2106&utm_medium=qrcode&utm_content=";break;
          case "stein": window.location.href = "https://startzeit-digital.de/firmen/modellbau-stein-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=stein";break;
          case "wjobb": window.location.href = "https://startzeit-digital.de/firmen/wirtschaftsjunioren-ostbrandenburg/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=wj-obb";break;
          case "oskh": window.location.href = "https://startzeit-digital.de/firmen/oder-spree-krankenhaus/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=os-kh";break;
          case "sle": window.location.href = "https://startzeit-digital.de/firmen/sle-stahl-logistik-eisenhuettenstadt-gmbh-co-kg/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=sle-stahl";break;
          case "pedag": window.location.href = "https://startzeit-digital.de/firmen/pedag-international/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=pedag";break;
          case "stama": window.location.href = "https://startzeit-digital.de/firmen/stama-stahl-und-maschinenbau-bad-saarow-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=stama";break;
          case "stawenow": window.location.href = "https://startzeit-digital.de/firmen/stawenow-gmbh-co-kg/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=stawenow";break;
          case "drv": window.location.href = "https://startzeit-digital.de/firmen/deutsche-rentenversicherung-fuer-berlin-und-brandenburg/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=drv";break;
          case "fels": window.location.href = "https://startzeit-digital.de/firmen/fels-vertriebs-und-service-gmbh-co-kg/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=fels";break;
          case "agrarnz": window.location.href = "https://startzeit-digital.de/firmen/agrargenossenschaft-neuzelle-eg/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=agrargenossenschaft";break;
          case "starkde": window.location.href = "https://startzeit-digital.de/firmen/stark-deutschland-gmbh/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=stark";break;
          case "igmetal": window.location.href = "www.igmetall-ostbrandenburg.de";break;
          case "qcw": window.location.href = "https://startzeit-digital.de/firmen/qualifizierungscentrum-der-wirtschaft-gmbh-qcw/?utm_source=katalog_2106&utm_medium=qrcode&utm_content=qcw";break;
        }
        return <div>LOADING...</div>;
    };

    render() {
        return (
            <Switch>
                <Route exact path="/" component={this.home} strict={true} />
                <Route exact path="/login" component={this.login} strict={true} />
                <Route exact path="/wish-list" strict={true} component={this.wishList}/>
                <Route exact path="/firmen" strict={true} component={this.firmen}/>
                <Route exact path="/search/:city" strict={true} render={(props) => this.productList(props)}/>
                <Route exact path="/company/:id/:name" component={this.productDetail} strict={true} />

                <Route exact path="/arcelormittal" component={()=> this.redirect("arcelormittal")} strict={true} />
                <Route exact path="/asb" component={()=> this.redirect("asb")} strict={true} />
                <Route exact path="/301/:company" component={this.redirect} strict={true} />

                <PrivateRoute exact path="/cms" component={this.cms} strict={true} />
                <PrivateRoute exact path="/cms/companies/create" component={this.cmsAddCompany} strict={true} />
                <PrivateRoute exact path="/cms/companies/:companyId" component={this.cmsEditCompany} strict={true}/>
                <PrivateRoute exact path="/cms/:section/:dashboard" component={this.cms} strict={true} />
                <PrivateRoute exact path="/cms/:companyId/:section/:dashboard" component={this.cms} strict={true} />
                <PrivateRoute exact path="/cms/:companyId/:section/:dashboard/:record" component={this.cms} strict={true} />




            {/*    <Route exact path="/cms/:section/:dashboard" component={this.cms} strict={true} />
                <Route exact path="/cms/:section/:dashboard/:id" component={this.cms} strict={true} />*/}
                <Route path="/*" component={this.notFound} strict={true}/> />
            </Switch>
        );
    }
}

export default Routing;
