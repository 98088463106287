
import React, {Component} from 'react'
import {GoogleMap, Marker, InfoWindow, DirectionsService, DirectionsRenderer, OverlayView} from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import "./map.scss";
import Util from "../../../util/Util";
import {Button, Col, Row} from "react-bootstrap";

type Props = {
    companies: Array<any>,
    productHovered: any | null;
};

type State = {
    map: any;
    activeWindow: any
    response: any,
    travelMode: 'DRIVING' | 'BICYCLING' | 'TRANSIT' | 'WALKING' | undefined,
    destination: string,
    origin: string,
    isScriptLoaded: boolean,
    directionsVisible: boolean
};

class Map extends Component<Props, State> {

    state: State ={
        map: null,
        activeWindow: null,
        response: null,
        travelMode: 'DRIVING',
        origin: '',
        destination: '',
        isScriptLoaded: false,
        directionsVisible: false
    };

    componentDidMount(): void {
        const googleapis = document.createElement('script');
        googleapis.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBeXKaK0lJ7mlFyeC_nL0qbIUWPHNB0gAc&libraries=places'
        googleapis.onload = () => {
            this.setState({isScriptLoaded: true});
        };
        document.head.append(googleapis);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if(JSON.stringify(this.getIds(prevProps.companies)) !== JSON.stringify(this.getIds(this.props.companies))){
            this.mapFitBound(this.state.map);
        }

        if(this.props.productHovered && this.props.productHovered !== prevProps.productHovered){
            this.setState({activeWindow: this.props.companies.findIndex((company)=> company.id === this.props.productHovered ).toString()});
        }
    }


    getIds = (list: Array<any>) => {
        return list.map((l: any) => l.id)
    };

    mapFitBound = (map: any)=>{
        if(map){
            const bounds = new window.google.maps.LatLngBounds();
            const companies = this.props.companies;

            for (let company in companies){
                // @ts-ignore
                bounds.extend(new window.google.maps.LatLng(
                    Number(companies[company].information.geolocation.lat),
                    Number(companies[company].information.geolocation.lng)
                ));
            }
            map.fitBounds(bounds);
           this.setState({map:map});
        }
    };

    directionsCallback = (response: any) => {
        if (response !== null) {
            if (response.status === 'OK') {
                this.setState(
                    () => ({
                        response
                    })
                )
            } else {
                console.log('response: ', response)
            }
        }
    };

     onLoad = (map: any) =>{
        // @ts-ignore
        const bounds = new window.google.maps.LatLngBounds();
        const companies = this.props.companies;

        for (let company in companies){
            // @ts-ignore
            bounds.extend(new window.google.maps.LatLng(
                Number(companies[company].information.geolocation.lat),
                Number(companies[company].information.geolocation.lng)
            ));
        }
        map.fitBounds(bounds);
         this.setState({map:map});

    };

     onUnmount =() =>{
         this.setState({map:null});
    };

     setTravelMode = (travelMode: 'DRIVING' | 'BICYCLING' | 'TRANSIT' | 'WALKING') =>{
         this.setState({travelMode:travelMode, response: null  });
     };

    handleSelect = (address: any) => {
        this.setState({origin: address});
         geocodeByAddress(address).then((results: any) => {
            if(results && results[0] && results[0].address_components){
                const locations = results[0].address_components;
            }
         });
    };


    render(){
        const {
            state: {activeWindow, origin, destination, travelMode, isScriptLoaded, response, directionsVisible }
        }= this;
        let distance: any = {};

        if(!isScriptLoaded){
            return null;
        }

        if(response && response.routes && response.routes[0] && response.routes[0].legs && response.routes[0].legs[0]){
                distance = response.routes[0].legs[0];
        }

        return (

            <Row noGutters style={{height: "100%"}}>
                <Col className='map'>
                    <Row noGutters>
                        <Col>
                            <div className='map-settings'>
                                {directionsVisible &&
                                <div className={"search-box-wrapper"}>
                                    <div className={"search-box"}>
                                        <div className="autocomplete-box">
                                            <PlacesAutocomplete
                                                value={origin}
                                                onChange={(d: any) => this.setState({origin: d})}
                                                onSelect={this.handleSelect}
                                            >
                                                {({getInputProps, suggestions, getSuggestionItemProps, loading}: any) => (
                                                    <span style={{
                                                        position: "relative"
                                                    }}>

                                                <input
                                                    {...getInputProps({
                                                        placeholder: 'Search Places ...',
                                                        className: 'form-control',
                                                        autoComplete: "off"
                                                    })}
                                                />

                                                        {suggestions.length > 0 &&
                                                        <div
                                                            style={{
                                                                border: "1px solid #ced4da",
                                                                borderTop: "0",
                                                                marginTop: "5px",
                                                                borderRadius: "5px",
                                                                overflow: "hidden",
                                                                position: "absolute",
                                                                minWidth: "390px"
                                                            }}
                                                        >
                                                            {suggestions
                                                                .map((suggestion: any, key: any) => {
                                                                    const className = suggestion.active
                                                                        ? '--active'
                                                                        : '';
                                                                    // inline style for demonstration purpose
                                                                    let style = suggestion.active
                                                                        ? {backgroundColor: '#fafafa'}
                                                                        : {backgroundColor: '#ffffff'};
                                                                    return (
                                                                        <div
                                                                            key={key}
                                                                            style={{
                                                                                height: "36px",
                                                                                borderTop: "1px solid #ced4da",
                                                                                padding: "5px 15px",
                                                                                cursor: 'pointer',
                                                                                ...style,
                                                                            }}
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                            })}
                                                                        >
                                                                            <span>{suggestion.description}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                        }
                                            </span>
                                                )}
                                            </PlacesAutocomplete>
                                        </div>
                                        <input id='DESTINATION' className='form-control' type='text' value={destination}/>
                                    </div>


                                    <ul className={"vehicle-box"}>
                                        <li onClick={() => this.setTravelMode("DRIVING")}
                                            className={travelMode === "DRIVING" ? "active" : ""}>
                                            <i className="fas fa-car"/>
                                        </li>
                                        <li onClick={() => this.setTravelMode("BICYCLING")}
                                            className={travelMode === "BICYCLING" ? "active" : ""}>
                                            <i className="fas fa-subway"/>
                                        </li>
                                        <li onClick={() => this.setTravelMode("TRANSIT")}
                                            className={travelMode === "TRANSIT" ? "active" : ""}>
                                            <i className="fas fa-bicycle"/>
                                        </li>
                                        <li onClick={() => this.setTravelMode("WALKING")}
                                            className={travelMode === "WALKING" ? "active" : ""}>
                                            <i className="fas fa-walking"/>
                                        </li>
                                    </ul>
                                </div>
                                }
                                <div className={"directions-btn"}
                                     style={{
                                         left: !directionsVisible ?  "186px": "470px"
                                     }}
                                     onClick={()=> this.setState(prevState => ({directionsVisible: !prevState.directionsVisible, response: null, destination: ""}))}
                                >
                                    <i className={ directionsVisible? "fas fa-times": "fas fa-directions"}/>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='map-container h-100' noGutters>
                        <Col>

                                <GoogleMap
                                    // required
                                    id='direction-example'
                                    mapContainerStyle={{ width: "100%", height: `calc(100%)`,  borderRadius: "0px", background: "#1a75b7" }}
                                    zoom={10}
                                    onLoad={this.onLoad}
                                    onUnmount={this.onUnmount}

                                >
                                    { origin !== "" && destination !=="" && !response && (
                                            <DirectionsService
                                                // required
                                                options={{
                                                    destination: this.state.destination,
                                                    origin: this.state.origin,
                                                    // @ts-ignore
                                                    travelMode: travelMode
                                                }}
                                                // required
                                                callback={this.directionsCallback}
                                                // optional
                                                onLoad={directionsService => {
                                                    console.log('DirectionsService onLoad directionsService: ', directionsService)
                                                }}
                                                // optional
                                                onUnmount={directionsService => {
                                                    console.log('DirectionsService onUnmount directionsService: ', directionsService)
                                                }}
                                            />
                                        )
                                    }

                                    {Object.keys(this.props.companies).map((company: any, i: number )=>
                                            <div key={i}>
                                               <Marker
                                                   icon={require('../../../assets/ui/pin-map.svg')}
                                                   onClick={()=> {this.setState({
                                                       activeWindow: company,
                                                       destination: `${Util.decodeCharacters(this.props.companies[company].information.address)}, ${Util.decodeCharacters(this.props.companies[company].information.zipcode)} ${Util.decodeCharacters(this.props.companies[company].information.city)}`
                                                   })}}
                                                   position={{
                                                       lat: Number(this.props.companies[company].information.geolocation.lat),
                                                       lng: Number(this.props.companies[company].information.geolocation.lng)
                                                   }}
                                               />
                                            { activeWindow === company &&
                                                <InfoWindow
                                                    key={`info-${i}`}
                                                    onCloseClick={()=> {this.setState({activeWindow: null})}}
                                                    position={{
                                                        lat: Number(this.props.companies[company].information.geolocation.lat),
                                                        lng: Number(this.props.companies[company].information.geolocation.lng)
                                                    }}
                                                >

                                                    <div className={"window-info-wrapper"}>
                                                        <img width={100} src={this.props.companies[company].information.logo} alt=""/>
                                                        <div className="window-body">
                                                            <div className={"card-product-name"}>
                                                                <i className="fas fa-building"/>&nbsp;
                                                                {Util.decodeCharacters(this.props.companies[company].information.name)}

                                                            </div>
                                                            <div className={"card-product-description"}>
                                                                <i className="fas fa-map-marker-alt"/>&nbsp;
                                                                {Util.decodeCharacters(this.props.companies[company].information.address)},&nbsp;
                                                                {Util.decodeCharacters(this.props.companies[company].information.zipcode)}&nbsp;
                                                                {Util.decodeCharacters(this.props.companies[company].information.city)}
                                                            </div>

                                                                <div style={{
                                                                    display: "flex",
                                                                    marginTop: "10px"
                                                                }}>
                                                                    <Button
                                                                        variant="primary"
                                                                        className={"btn-detail"}
                                                                        href={`/company/${this.props.companies[company].id}/${Util.decodeCharacters(this.props.companies[company].information.name).replace(/ /g, "-")}`}>
                                                                        Mehr erfahren
                                                                    </Button>
                                                                    {this.props.companies[company].isOnline &&
                                                                        <div className="online blob">
                                                                            Online
                                                                        </div>
                                                                    }
                                                                </div>

                                                        </div>
                                                    </div>
                                                </InfoWindow>
                                            }
                                           </div>
                                    )
                                    }

                                    {response !== null && distance && (
                                        <>
                                            <DirectionsRenderer
                                                // required
                                                // @ts-ignore
                                                options={{directions: response}}
                                                // optional
                                                onLoad={directionsRenderer => {
                                                    console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                                                }}

                                                // optional
                                                onUnmount={directionsRenderer => {
                                                    console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                                                }}
                                            />
                                            <OverlayView
                                                position={{
                                                    lat: distance.start_location.lat(),
                                                    lng: distance.start_location.lng()
                                                }}
                                                bounds={distance.bounds}
                                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                            >
                                                <div style={{
                                                    background: 'white',
                                                    border: '1px solid #ccc',
                                                    padding: 15
                                                }}>
                                                    {distance.distance.text} /
                                                    {distance.duration.text}
                                                </div>
                                            </OverlayView>
                                        </>
                                    )
                                    }
                                </GoogleMap>
                        </Col>
                    </Row>
                </Col>
            </Row>


    )}
}

export default React.memo(Map);




{/*<LoadScript
            googleMapsApiKey="AIzaSyBeXKaK0lJ7mlFyeC_nL0qbIUWPHNB0gAc"
        >
            <GoogleMap
                mapContainerStyle={{ height: `100%`,  borderRadius: "0px", background: "#1a75b7" }}
                zoom={10}
                onLoad={this.onLoad}
                onUnmount={this.onUnmount}
            >

            </GoogleMap>
        </LoadScript>*/}
