export default class Util {

    static getBaseUrl = () => {
        const url = new URL(window.location.href);
        const hostname = url.hostname;
        const port = url.port;
        const protocol = url.protocol;
        return `${protocol}//${hostname}${port ? `:${port}` : ""}`;
    };

    static getPath = (path: string) => {
        return `${Util.getBaseUrl()}/${path}`;
    };


    static encodeCharacters = (text: string | null, avoidTrimming?: boolean) => {
        if(text === null || text === undefined){
            return "";
        }
        let encode = text
            .replace(/[.]/g, "_P_")
            .replace(/[$]/g, "_D_")
            .replace(/[#]/g, "_H_")
            .replace(/[[]/g, "_O_")
            .replace(/[\]]/g, "_C_")
            .replace(/[?]/g, "_Q_")
            .replace(/[%]/g, "_100_")
            .replace(/[/]/g, "_S_");

        if(avoidTrimming){
            return encode
        }

        return encode.trim();

    };

    static decodeCharacters = (text: string | null,  avoidTrimming?: boolean) => {
        if(text === null || text === undefined){
            return "";
        }
        let decode = text
            .replace( /_P_/g, ".",)
            .replace(/_D_/g, "$")
            .replace(/_H_/g, "#")
            .replace(/_O_/g, "[")
            .replace(/_C_/g, "]")
            .replace(/_Q_/g, "?")
            .replace(/_100_/g, "%")
            .replace(/_S_/g, "/");

        if(avoidTrimming){
            return decode
        }

        return decode.trim();
    };

    static findNestedValue = (obj: any, ...args: any) => {
        return args.reduce((obj: any, level: any) => obj && obj[level], obj);
    };

    static imageToBase64 = (file: File) => {
        return new Promise((resolve: any, reject: any) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };
}

