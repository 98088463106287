import React, {Component} from 'react';
import {Card, Col, Container, Row, Button, Form} from "react-bootstrap";
import firebase from 'firebase/app';
import "react-dates/initialize";
import 'antd/dist/antd.css';
import "../dashboard/dashboard.scss";
import "react-dates/lib/css/_datepicker.css";
import "./portlet.scss";
import {NavLink} from "react-router-dom";
import Util from "../../../util/Util";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

type Props = {
    companyId: string,
    mode: "EDIT" | "CREATE",
    section: string,
    dashboard: string,
    record:string
};

type State = {
    validated: boolean,
    type: any,
    title:string,
    description: Array<string>,
    loadingData: boolean

};

class JobForm extends Component<Props, State> {

    state:State = {
        validated: false,
        type: {},
        title:"",
        description: [],
        loadingData: false,
    };

    componentDidMount(): void {
        if(this.props.mode ==="EDIT"){
            this.loadRecord();
        }
    }

    loadRecord = () => {
        const {companyId, record} = this.props;
        this.setState({loadingData: true});

        firebase.database().ref(`companies/${companyId}/jobs/${record}`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState(
                    {
                        description: snapshot.val().description.map((d: any) => Util.decodeCharacters(d)),
                        title: Util.decodeCharacters(snapshot.val().title),
                        type: {value: snapshot.val().type, label: snapshot.val().type},
                    }
                )
            }
            this.setState({loadingData: false});
        });
    };

    handleSubmit= (event: any)=>{
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === true && this.state.type.value ) {
            this.addJob();
        }
        this.setState({validated: true});
    };

    handleTypeChange = (type: any) => {
        this.setState({ type : type });
    };

    addJob = () => {
        const {
            description,
            type,
            title,
        } = this.state;

        const {
            mode,
            companyId,
            record,
        } = this.props;

        const data = {
            description: description.map(d => Util.encodeCharacters(d)),
            title: Util.encodeCharacters(title),
            type: Util.encodeCharacters(type.value)
        };

        if(mode === "CREATE"){
            firebase.database().ref(`companies/${companyId}/jobs/`).push(data).then(()=>{
                window.location.href = Util.getPath(`cms/${companyId}/jobs/list`);
            });
        }

        if(mode === "EDIT"){
            firebase.database().ref(`companies/${companyId}/jobs/${record}`).update(data
            ).then(()=>{
                window.location.href = Util.getPath(`cms/${companyId}/jobs/list`);
            });
        }

    };

    handleOnTextChange = (e: any, key: any) => {
         let value = e.target.value;
        // @ts-ignore
        this.setState({[key]: value});
    };

    handleTextAreaOnChange = (e: any) => {
        let value = e.target.value.toString();
        this.setState({description: value.split(/\r?\n/)});
    };

    render() {
        const {
            state: {
                validated,
                description,
                title,
                type
            },
            props:{companyId, section, mode}
        } = this;

        const availableJobsTypes = [
            { value: 'Ausbildung', label: "Ausbildung" },
            { value: 'Praktikum', label: "Praktikum" },
            { value: 'Duales Studium', label: "Duales Studium" },
            /*{ value: 'Werkstudentenjob', label: "Werkstudentenjob" },*/
        ];

        return (
            <Container className={"portlet-wrapper"}>
                <Row noGutters>
                    <Col className={"portlet-header"}>
                        <h4>
                            <i className="fas fa-edit"/>&nbsp;
                            <span>{mode === "CREATE" ? "Add Job": "Edit Job" }</span>
                        </h4>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                            <Card className={"card-form-portlet"}>
                                <Card.Header as="h5">Job Information</Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Job Title *</Form.Label>
                                            <Form.Control
                                                value={title}
                                                onChange={(e)=> this.handleOnTextChange(e, "title")}
                                                required
                                                type="text"/>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Job Type *</Form.Label>
                                            <Select
                                                value={type}
                                                onChange={this.handleTypeChange}
                                                options={availableJobsTypes}
                                                components={animatedComponents}
                                                ignoreCase={true}
                                                classNamePrefix={`form-dropdown  ${!type.value && validated ? "error-dropdown": ""}`}
                                                className={"form-dropdown"}
                                                placeholder={""}
                                                required
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                value={description.join("\r\n")}
                                                onChange={(e)=> this.handleTextAreaOnChange(e)}
                                                as="textarea"
                                                rows={5}
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <Button type="submit">{mode === "CREATE" ? "Add": "Update" } job</Button>
                                    <NavLink to={`/cms/${companyId}/${section}/list`}>
                                        <Button variant={"secondary"}>
                                            Cancel
                                        </Button>
                                    </NavLink>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default JobForm;
