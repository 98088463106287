
export class Config {
    static getFirebaseKeys = () => {
        let devKeys =  {
            apiKey: "AIzaSyAEl3q3cwDmhcivBLLfh3kSXTROsVFb_os",
            authDomain: "startzeit-stage-8396c.firebaseapp.com",
            databaseURL: "https://startzeit-stage-8396c.firebaseio.com",
            projectId: "startzeit-stage-8396c",
            storageBucket: "startzeit-stage-8396c.appspot.com",
            messagingSenderId: "219552185338",
            appId: "1:219552185338:web:184c17245a57064f448243",
            measurementId: "G-N78QB7D7V2"
        };
        let prodKeys =  {
            apiKey: "AIzaSyCe2A5Nm1zjW-rUvbq-dmTOWytn4araQG4",
            authDomain: "startzeitdigital.firebaseapp.com",
            databaseURL: "https://startzeitdigital.firebaseio.com",
            projectId: "startzeitdigital",
            storageBucket: "startzeitdigital.appspot.com",
            messagingSenderId: "67551484837",
            appId: "1:67551484837:web:4e98879ee6625f7b8a264c",
            measurementId: "G-JSQV8KHH62"
        };

       return process.env.REACT_APP_STAGE === "dev" || window.location.href.startsWith("https://startzeit-stage-8396c.web.app")? devKeys : prodKeys
    }
}
