import React, {Component} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import SearchBar from "../searchBar/SearchBar";
import "./productList.scss";
import ProductCard from "./ProductCard";
import firebase from 'firebase/app';

type Props = {
    companies: any,
    city: string | null,
    branches: any | null, //url branches
    onSearch: Function,
    updateWLCounter: Function,
    jobType: string;
    onProductHover: Function
};

type State = {
    AppBranches: any
};

class ProductList extends Component<Props, State> {
    state: State = {
        AppBranches: {}
    };

    componentDidMount(): void {
        this.loadBranches();
    }

    loadBranches = ()=> {
        firebase.database().ref(`app/branches`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                let branches = snapshot.val();
                this.setState(
                    {
                        AppBranches:  Object.keys(branches).map((key: string)=> {
                            return {...branches[key]}
                        }),
                    }
                );
            }
        });
    };

    render() {
        const { companies, onSearch, city, branches, updateWLCounter, jobType, onProductHover } = this.props;
        const { AppBranches} = this.state;

        return (
            <Container fluid className={"product-list-wrapper"}>
                <Row noGutters className={"product-list-search-bar-wrapper"}>
                    <Col>
                        <SearchBar onSearch={onSearch} city={city} branches={branches} jobType={jobType}/>
                    </Col>
                </Row>
                <div className={"product-list-results"}>
                    {companies.length === 0 &&
                        <span>Es wurden keine Ergebnisse gefunden für deine Suchkriterien</span>
                    }
                    {companies.map((company: any)=>
                        <ProductCard
                            key={company.id}
                            company={company}
                            id={company.id}
                            updateWLCounter={updateWLCounter}
                            onProductHover={onProductHover}
                            branches={AppBranches}/>
                    )}
                </div>
            </Container>
        );
    }
}

export default ProductList;
