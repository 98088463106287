import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {Form} from 'react-bootstrap';

type Props = {
    address: string,
    handleOnAddressChange: (value: string) => void,
    handleOnAddressSelect: Function
}

type State = {
   isScriptLoaded: boolean;
}

export default class AddressAutocomplete extends React.Component<Props, State> {

    state: State = {
        isScriptLoaded: false
    };

    componentDidMount(): void {
        const googleapis = document.createElement('script');
        googleapis.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBeXKaK0lJ7mlFyeC_nL0qbIUWPHNB0gAc&libraries=places'
        googleapis.onload = () => {
          this.setState({isScriptLoaded: true});
        };
        document.head.append(googleapis);
    }

    handleChange = (address: any) => {
        this.props.handleOnAddressChange(address);
    };

    handleSelect = (address: any) => {

        let data: any = {
            zipcode: "",
            city: "",
            lat: "",
            lng: "",
        };

        let promises: any = [];

        promises.push( geocodeByAddress(address).then((results: any) => {
            if(results && results[0] && results[0].address_components){
                const locations = results[0].address_components;
                data.zipcode = locations.find((l: any) => l.types.includes("postal_code"));
                data.city = locations.find((l:any) => l.types.includes("locality"));
            }
        }));
        promises.push(
            geocodeByAddress(address)
                .then((results: any) => getLatLng(results[0]))
                .then((latLng: any) =>  {
                    data.lat = latLng.lat;
                    data.lng = latLng.lng;
                })
                .catch((error: any) => console.error('Error', error))
        );

        Promise.all(promises).then(() => {
            this.props.handleOnAddressSelect(data);
        });

    };

    render() {
        const {state: {isScriptLoaded}} = this;
        if(!isScriptLoaded){
            return null;
        }
        return (
            <PlacesAutocomplete
                value={this.props.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <input
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'form-control',
                                    autoComplete: "off"
                                })}
                            />

                            {suggestions.length > 0 &&
                                <div
                                    style={{
                                        border: "1px solid #ced4da",
                                        borderTop: "0",
                                        marginTop: "5px",
                                        borderRadius: "5px",
                                        overflow: "hidden"
                                    }}
                                >
                                    {suggestions
                                        .map((suggestion: any, key: any) => {
                                        const className = suggestion.active
                                            ? '--active'
                                            : '';
                                        // inline style for demonstration purpose
                                        let style = suggestion.active
                                            ? { backgroundColor: '#fafafa'}
                                            : { backgroundColor: '#ffffff'};
                                        return (
                                            <div
                                                key={key}
                                                style={{
                                                    height: "36px",
                                                    borderTop: "1px solid #ced4da",
                                                    padding: "5px 15px",
                                                    cursor: 'pointer',
                                                    ...style,
                                                }}
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </Form.Group>
                )}
            </PlacesAutocomplete>
        );
    }
}



