import React, {Component} from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import {Button, Col, Container, Row} from "react-bootstrap";
import firebase from 'firebase/app';
import Util from "../../../util/Util";
import Loading from "../shared/loading/Loading";
import "./portletTable.scss";
import {NavLink} from "react-router-dom";
import HeaderDescription from "../dashboard/HeaderDescription";
import ModalDelete from "../modal/ModalDelete";
import moment from 'moment';


const ActionCell = (row: any)=> {
    return (
        <div className={"cell-action-wrapper"}>
            <NavLink to={`../advisers/edit/${row.original.id}`}>
                <Button>
                    Edit
                </Button>
            </NavLink>
            <Button
                variant={"danger"}
                onClick={()=> row.original.component.setState({
                    showModalDelete: true,
                    record: {
                        id: row.original.id,
                        name: row.original.name
                    }
                })}>
                Delete
            </Button>
        </div>
    );
};

const columns = [
    {
        Header: 'Name',
        accessor: 'name' // String-based value accessors!
    },
    {
        Header: 'E-mail',
        accessor: 'email' // String-based value accessors!
    },
    {
        Header: 'Phone',
        accessor: 'phone' // String-based value accessors!
    },
    {
        Header: 'Day Availability',
        accessor: 'dayAvailability' // String-based value accessors!
    },
    {
        Header: 'Actions',
        accessor: 'actions', // String-based value accessors!
        Cell: ActionCell,
        width: 150
    }
];

type Props = {
    companyId: string,
    section: string,
    dashboard: string
};

type State = {
    searchField: string,
    data: Array<any>,
    filteredCompanies: Array<any>,
    loading: boolean,
    selectAll: boolean,
    selection: Array<any>,
    loadingData: boolean,
    showModalDelete: boolean,
    feedback: Array<any>;
    record: {
        name: string,
        id: string,
    };
};


class AdviserList extends Component<Props, State> {
    // @ts-ignore
    checkboxTable: ReactTable;

    state: State = {
        searchField: "",
        data: [],
        filteredCompanies: [],
        loading: false,
        selectAll: false,
        selection: [],
        loadingData: true,
        showModalDelete: false,
        feedback: [],
        record: {
            name: "",
            id: ""
        }
    };

    componentDidMount(): void {
        this.loadData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.companyId !== this.props.companyId){
            this.loadData();
        }
    }

    loadData = () => {
        this.setState({loadingData: true});
        firebase.database().ref(`companies/${this.props.companyId}/advisers`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState({data: this.mapAdvisers(snapshot.val()), loadingData: false});
            }else{
                this.setState({data: [], loadingData: false});
            }
        }, function(error: any) {
            if (error) {
                console.log(error);
            } else {
                // Data saved successfully!
            }
        });

    };

    mapAdvisers = (advisers: any): Array<any> => {
        return Object.keys(advisers).map((adviser: any)=> {
            return {
                id: adviser,
                name: Util.decodeCharacters(advisers[adviser].name),
                email: Util.decodeCharacters(advisers[adviser].email),
                phone: Util.decodeCharacters(advisers[adviser].phone.join("/")),
                dayAvailability: advisers[adviser].dayAvailability?  Util.decodeCharacters(advisers[adviser].dayAvailability.join("/")): "-",
                component: this
            }
        });
    };

    deleteRecord = (id: string, name: string) => {
        this.setState({showModalDelete: false});
        firebase.database().ref(`companies/${this.props.companyId}/advisers`).update({[id]: null}).then(()=>{
            this.pushFeedback({
                type: "success",
                title: "Adviser deleted",
                text: `Adviser ${name} was deleted!`,
                id: moment.now(),
            })
        });
    };

    pushFeedback = ( feedbackData : any)=>{
        let feedBack = this.state.feedback;
        this.setState({feedback: [feedbackData , ...feedBack]})
    };

    render() {
        const {
            props: {companyId, dashboard, section},
            state: {data, loadingData, feedback, showModalDelete, record}
        }= this;

        return (
            <Container className={"portlet-wrapper"}>
                <HeaderDescription
                    companyId={companyId}
                    dashboard={dashboard}
                    section={section}
                    icon={"fas fa-id-card-alt"}
                    name={"Advisers"}
                    feedback={feedback}
                />
                <Row>
                    <Col>
                        <div className="toolbar-dashboard">
                            <NavLink to={`/cms/${companyId}/${section}/add`} className={"btn btn-success"}>
                                <i className="fas fa-plus"/> Add adviser
                            </NavLink>
                            <NavLink target={"_black"} to={`/company/${companyId}/details#advisers`} className={"btn btn-light float-right"}>
                                <i className="fas fa-globe-europe"/> See website
                            </NavLink>
                        </div>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        {loadingData?
                            <Loading/>:
                            <ReactTable
                                data={data}
                                columns={columns}
                            />
                        }
                    </Col>
                </Row>
                <ModalDelete
                    showModal={showModalDelete}
                    onHide={()=> this.setState({showModalDelete: false})}
                    onDelete={()=> this.deleteRecord(record.id, record.name )}
                    title={"Delete Adviser?"}
                    message={`You are about to delete adviser ${record.name}`}/>
            </Container>
        );
    }
}

export default AdviserList;
